var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, } from "@mui/x-data-grid";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import { Button } from "@mui/material";
import trackerApi from "../../api/tracker";
import useExposant from "../../hooks/useExposant";
var moment = require("moment");
var AllDejeuners = function (props) {
    var _a = useState([]), rows = _a[0], setRows = _a[1];
    var _b = useExposant(), createExposant = _b[0], getExposant = _b[1], updateExposant = _b[2], deleteExposant = _b[3];
    var _c = useState(), selectedMeetings = _c[0], setSelectedMeetings = _c[1];
    var _d = useState(), selection = _d[0], setSelection = _d[1];
    var _e = React.useState({}), rowModesModel = _e[0], setRowModesModel = _e[1];
    var handleAccountClick = function (id) { return function () {
        window.location.href = "/admin/entreprise/".concat(id, "/compte");
    }; };
    var handleEditClick = function (id) { return function () {
        window.location.href = "/admin/entreprise/".concat(id, "/details");
    }; };
    var handleDeleteClick = function (id) { return function () {
        var isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette entreprise ? Cette action est irréversible.");
        var onSuccess = function () {
            setRows(rows.filter(function (row) { return row.id !== id; }));
            toastr.success("Entreprise suprimée", "L'entreprise ".concat(id, " a bien \u00E9t\u00E9 supprim\u00E9e"), { position: "top-right" });
        };
        var onFail = function () {
            toastr.error("Échec", "Une erreur est survenue", {
                position: "top-right",
            });
        };
        if (isConfirmed) {
            deleteExposant(id, onSuccess, onFail);
        }
    }; };
    var columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            maxWidth: 80,
        },
        {
            field: "etudiant",
            headerName: "Etudiant",
            flex: 1,
            minWidth: 150,
            valueGetter: function (params) {
                return "".concat(params.row.etudiant.nom, " ").concat(params.row.etudiant.prenom, " (").concat(params.row.etudiant.id, ")");
            },
            renderCell: function (params) {
                return (React.createElement("a", { href: params.row.etudiant.lien_cv, target: "_blank", rel: "noopener noreferrer" }, params.value));
            },
        },
        {
            field: "etudiantRank",
            headerName: "Choix #",
            flex: 1,
            maxWidth: 80,
            valueGetter: function (params) {
                var _a;
                var id = params.row.exposant.id;
                var preferences = JSON.parse(params.row.etudiant.dejeuner_preferences);
                var rank = preferences.indexOf(id) + 1;
                if (!rank)
                    return (_a = params.row.etudiant) === null || _a === void 0 ? void 0 : _a.dejeuner_preferences;
                return rank;
            },
            renderCell: function (params) {
                return (React.createElement("p", { style: { width: "100%", textAlign: "center" } }, params.value === 1 ? "🏆" : params.value));
            },
        },
        {
            field: "exposant",
            headerName: "Exposant",
            flex: 1,
            minWidth: 150,
            valueGetter: function (params) {
                return "".concat(params.value.titre, " (").concat(params.value.id, ")");
            },
            renderCell: function (params) {
                var companyURL = "/admin/entreprise/" + params.value.id + "/details";
                return (React.createElement("a", { href: companyURL, target: "_blank", rel: "noopener noreferrer" }, params.value));
            },
        },
        {
            field: "exposantRank",
            headerName: "Choix #",
            flex: 1,
            maxWidth: 80,
            valueGetter: function (params) {
                var _a;
                var id = params.row.etudiant.id;
                var preferences = JSON.parse(params.row.exposant.dejeuner_preferences);
                var rank = preferences.indexOf(id) + 1;
                if (!rank)
                    return (_a = params.row.exposant) === null || _a === void 0 ? void 0 : _a.dejeuner_preferences;
                return rank;
            },
            renderCell: function (params) {
                return (React.createElement("p", { style: { width: "100%", textAlign: "center" } }, params.value === 1 ? "🏆" : params.value));
            },
        },
        {
            field: "%",
            headerName: "Complet",
            type: "number",
            flex: 1,
            maxWidth: 100,
            valueGetter: function (params) {
                var numberStudents = rows.filter(function (e) { return e.exposant.titre === params.row.exposant.titre && e.tier === 1; }).length;
                var numberSlots = params.row.exposant.dejeuner_count;
                return numberStudents / numberSlots;
            },
            renderCell: function (params) {
                // @ts-ignore
                return "".concat(Number.parseInt(params.value * 100), "%");
                return (React.createElement("p", { style: { width: "100%", textAlign: "center" } }, params.value));
            },
        },
        {
            field: "tier",
            headerName: "Tranche",
            type: "number",
            flex: 1,
            maxWidth: 100,
            renderCell: function (params) {
                return (React.createElement("p", { style: { width: "100%", textAlign: "center" } }, params.value));
            },
        },
        //  {
        //    field: "confirmation_etudiant",
        //    headerName: "OK étudiant",
        //    type: "boolean",
        //    flex: 1,
        //    maxWidth: 130,
        //  },
        //  {
        //    field: "confirmation_exposant",
        //    headerName: "OK exposant",
        //    type: "boolean",
        //    flex: 1,
        //    maxWidth: 130,
        //  },
        {
            field: "createdAt",
            headerName: "Créée le",
            flex: 1,
            maxWidth: 150,
            renderCell: function (params) {
                var formattedDateFr = new Date(params.value).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                return React.createElement("span", null, formattedDateFr);
            },
        },
        //  {
        //    field: "actions",
        //    type: "actions",
        //    headerName: "",
        //    flex: 1,
        //    cellClassName: "actions",
        //    getActions: ({ id }) => {
        //      //   const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        //      //   if (isInEditMode) {
        //      //     return [
        //      //       <GridActionsCellItem
        //      //         icon={<SaveIcon />}
        //      //         label="Save"
        //      //         sx={{
        //      //           color: "primary.main",
        //      //         }}
        //      //         onClick={handleSaveClick(id)}
        //      //       />,
        //      //       <GridActionsCellItem
        //      //         icon={<CancelIcon />}
        //      //         label="Cancel"
        //      //         className="textPrimary"
        //      //         onClick={handleCancelClick(id)}
        //      //         color="inherit"
        //      //       />,
        //      //     ];
        //      //   }
        //      return [
        //                  <GridActionsCellItem
        //          icon={<DeleteIcon />}
        //          label="Delete"
        //          onClick={handleDeleteClick(id)}
        //          color="inherit"
        //        />,
        //      ];
        //    },
        //  },
    ];
    var getDejeuners = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, trackerApi.get("/api/dejeuners")];
                case 1:
                    response = _a.sent();
                    setRows(response.data);
                    console.log(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log("welcome to catch block!");
                    console.log(e_1);
                    toastr.error("Échec", "Un problème est survenu", {
                        position: "top-right",
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleChangeSelection = function (newSelectionModel) {
        setSelection(newSelectionModel);
    };
    function formatMeetings(selection) {
        var meetings = rows.filter(function (e) { return selection.includes(e.id); });
        //  const companyGroups = {};
        //  meetings.forEach((item) => {
        //    const exposantId = item.exposant.id;
        //    const exposantName = item.exposant.titre;
        //    if (!(exposantId in companyGroups)) {
        //      companyGroups[exposantId] = {
        //        type: "dejeuner",
        //        exposantId,
        //        exposantName,
        //        etudiants: [],
        //        meetingTime: "2023/11/23 12:00",
        //        meetingDuration: "02:00",
        //      };
        //    }
        //    companyGroups[exposantId].etudiants.push({
        //      firstName: item.etudiant.prenom,
        //      name: `${item.etudiant.prenom} ${item.etudiant.nom}`,
        //      email: item.etudiant.email,
        //    });
        //  });
        //  const emails = Object.values(companyGroups);
        var emails = meetings.map(function (item) {
            return item.etudiant.email;
        });
        return emails;
    }
    function CustomToolbar() {
        return (React.createElement(GridToolbarContainer, null,
            React.createElement(GridToolbarColumnsButton, null),
            React.createElement(GridToolbarFilterButton, null),
            React.createElement(GridToolbarDensitySelector, null),
            React.createElement(GridToolbarExport, null),
            React.createElement(Button, { onClick: function () {
                    var meetings = formatMeetings(selection);
                    console.log(meetings);
                    return;
                } },
                React.createElement(EmailIcon, { style: { fontSize: 16, marginRight: 8 } }),
                React.createElement("span", { style: { fontSize: "0.8125rem" } }, "COPY JSON"))));
    }
    useEffect(function () {
        getDejeuners();
    }, []);
    if (!rows) {
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col" },
                React.createElement("div", { style: { height: 40 } }),
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("h3", null, "Dejeuners"),
                        React.createElement("p", null, "En cours de chargement..."))))));
    }
    return (React.createElement(DataGrid, { disableRowSelectionOnClick: true, slots: {
            toolbar: CustomToolbar,
        }, rows: rows, columns: columns, style: { flex: 1, minHeight: 400 }, initialState: {
            pagination: {
                paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
                sortModel: [{ field: "tier", sort: "asc" }],
            },
        }, rowSelectionModel: selection, onRowSelectionModelChange: handleChangeSelection, density: "compact", pageSizeOptions: [50, 100], checkboxSelection: true }));
};
export default AllDejeuners;
