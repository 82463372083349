var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import { toastr } from "react-redux-toastr";
import YouTube from "react-youtube";
var equal = require("deep-equal");
var ReactQuill = require("react-quill");
require("quill/dist/quill.snow.css");
require("brace");
import AceEditor from "react-ace";
import "brace/mode/java";
import "brace/theme/github";
import { Network } from "../../redux/actions";
import { withRouter } from "react-router";
import { withApi } from "../../decorators/api";
import { getImageUrl } from "../../utils";
var Editor = /** @class */ (function (_super) {
    __extends(Editor, _super);
    function Editor(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            titre: "",
            type: "text",
            contenu: "",
            youtubeId: "",
            image: "",
            cta: "",
        };
        return _this;
    }
    Editor.prototype.save = function () {
        var self = this;
        var newsId = this.props.actualite.id;
        self.props.dispatch(Network.request("/api/news/update/" + newsId, this.state, {
            action: function (json) {
                //self.props.history.push('/admin/news');
                self.props.loadNews();
                toastr.success("Détails mis à jour", "L'actualité a bien été mise à jour", { position: "top-right" });
            },
        }, "POST"));
    };
    Editor.prototype.delete = function () {
        var self = this;
        var newsId = this.props.actualite.id;
        self.props.dispatch(Network.request("/api/news/delete", { newsId: newsId }, {
            action: function (json) {
                self.props.loadNews(true);
                toastr.success("Actualité supprimée", "L'actualité a bien été supprimé", { position: "top-right" });
            },
        }, "POST"));
    };
    Editor.prototype.componentWillMount = function () {
        if (this.props.actualite != null) {
            this.setState({
                titre: this.props.actualite.titre,
                contenu: this.props.actualite.contenu,
                image: this.props.actualite.image,
                youtubeId: this.props.actualite.youtubeId,
                type: this.props.actualite.type,
            });
        }
    };
    Editor.prototype.componentWillReceiveProps = function (nextProps) {
        if (!equal(this.props.actualite, nextProps.actualite)) {
            this.setState({
                titre: nextProps.actualite.titre,
                contenu: nextProps.actualite.contenu,
                image: nextProps.actualite.image,
                youtubeId: nextProps.actualite.youtubeId,
                type: nextProps.actualite.type,
            });
        }
    };
    Editor.prototype.render = function () {
        var self = this;
        console.log(this.state);
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "form-group col-12" },
                React.createElement("label", { htmlFor: "titre" }, "Titre de l'actualit\u00E9"),
                React.createElement("input", { type: "text", className: "form-control", id: "titre", placeholder: "Titre de l'actualit\u00E9", value: this.state.titre, onChange: function (e) {
                        self.setState({ titre: e.target.value });
                    } })),
            React.createElement("div", { className: "form-group col-12" },
                React.createElement("label", { htmlFor: "image" }, "Image de miniature"),
                React.createElement("input", { type: "text", className: "form-control", id: "image", placeholder: "URL de l'image (taille mini : 100x100px)", value: this.state.image, onChange: function (e) {
                        self.setState({ image: e.target.value });
                    } }),
                this.state.image != null && this.state.image.trim() != "" ? (React.createElement("div", { className: "col-12 py-3 text-center" },
                    React.createElement("img", { style: { width: "100px", height: "100px" }, src: getImageUrl(this.state.image) }))) : null),
            React.createElement("div", { className: "form-group col-12" },
                React.createElement("label", { htmlFor: "cta" }, "Lien de redirection"),
                React.createElement("input", { type: "text", className: "form-control", id: "cta", placeholder: "URL du lien", value: this.state.cta, onChange: function (e) {
                        self.setState({ cta: e.target.value });
                    } })),
            React.createElement("div", { className: "form-group col-12" },
                React.createElement("label", { htmlFor: "titre" }, "Type de contenu"),
                React.createElement("select", { value: this.state.type, onChange: function (e) {
                        self.setState({ type: e.target.value });
                    }, className: "form-control" },
                    React.createElement("option", { value: "text" }, "Texte enrichi"),
                    React.createElement("option", { value: "youtube" }, "Youtube"),
                    React.createElement("option", { value: "html" }, "HTML"))),
            this.state.type == "text"
                ? this.renderTextEditor()
                : this.state.type == "youtube"
                    ? this.renderYoutubeEditor()
                    : this.renderHtmlEditor(),
            React.createElement("div", { className: "col-12 mt-5 text-center" },
                React.createElement("button", { className: "btn btn-primary m-4", onClick: function () {
                        self.save();
                    } }, "Sauvegarder"),
                React.createElement("button", { className: "ml-3 btn btn-danger m-4", onClick: function () {
                        self.delete();
                    } }, "Supprimer"))));
    };
    Editor.prototype.renderTextEditor = function () {
        var self = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "col-12" },
                React.createElement("label", { htmlFor: "contenu" }, "Contenu de l'actualit\u00E9"),
                React.createElement(ReactQuill, { value: this.state.contenu, onChange: function (newValue) {
                        self.setState({ contenu: newValue });
                    } }))));
    };
    Editor.prototype.renderYoutubeEditor = function () {
        var self = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "col-12" },
                React.createElement("label", { htmlFor: "contenu" }, "Description de la vid\u00E9o"),
                React.createElement(ReactQuill, { value: this.state.contenu, onChange: function (newValue) {
                        self.setState({ contenu: newValue });
                    } })),
            React.createElement("div", { className: "form-group col-12 mt-4" },
                React.createElement("label", { htmlFor: "video" }, "ID de la vid\u00E9o Youtube"),
                React.createElement("input", { type: "text", className: "form-control", id: "image", placeholder: "ID youtube", value: this.state.youtubeId, onChange: function (e) {
                        self.setState({ youtubeId: e.target.value });
                    } })),
            React.createElement("div", { className: "col-12" }, this.state.contenu && this.state.youtubeId.trim() != "" ? (React.createElement(YouTube, { videoId: this.state.youtubeId })) : null)));
    };
    Editor.prototype.renderHtmlEditor = function () {
        var self = this;
        return (React.createElement("div", { className: "form-group col-12 mt-4" },
            React.createElement("label", { htmlFor: "html" }, "Code HTMl"),
            React.createElement(AceEditor, { mode: "html", theme: "github", value: this.state.contenu, onChange: function (newValue) {
                    self.setState({ contenu: newValue });
                }, name: "editor" })));
    };
    Editor = __decorate([
        withApi(),
        withRouter,
        __metadata("design:paramtypes", [Object])
    ], Editor);
    return Editor;
}(React.Component));
export { Editor };
