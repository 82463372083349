var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useSelector } from "react-redux";
import { makeOptions } from "../../utils";
import useEtudiant from "../../hooks/useEtudiant";
import Select from "react-select";
var EtudiantsCompte = function (props) {
    var _a;
    var fileInputElement = document.querySelector("#fileInputElement");
    var _b = useState(), etudiantCreated = _b[0], setEtudiantCreated = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(), etudiant = _d[0], setEtudiant = _d[1];
    var etudiantId = useSelector(function (state) { return state.session.etudiant; });
    var _e = useEtudiant(), createEtudiant = _e[0], getEtudiant = _e[1], updateEtudiant = _e[2], deleteEtudiant = _e[3];
    useEffect(function () {
        getEtudiant(etudiantId, setEtudiant);
    }, []);
    var onSuccess = useCallback(function (etudiant) {
        console.log(etudiant);
        setEtudiant(etudiant);
        toastr.success("Modification réussie", "Vos modifications ont bien été prises en compte", {
            position: "top-right",
        });
        setLoading(false);
    }, []);
    var onFail = useCallback(function () {
        toastr.error("Échec", "La modification a échoué", {
            position: "top-right",
        });
        setLoading(false);
    }, []);
    var onSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedEtudiant;
        return __generator(this, function (_a) {
            e.preventDefault();
            setLoading(true);
            formattedEtudiant = etudiant;
            if (fileInputElement.files[0]) {
                formattedEtudiant = __assign(__assign({}, formattedEtudiant), { file: fileInputElement.files[0] });
            }
            console.log(formattedEtudiant);
            updateEtudiant(formattedEtudiant, onSuccess, onFail);
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("section", { className: "exposants mt-5" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row justify-content-center mb-5" },
                React.createElement("div", { className: "col-lg-6 text-center" },
                    React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Compte"),
                    React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Retrouvez ici toutes les informations que vous partagez avec les entreprises"),
                    React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
            React.createElement("form", { id: "inscription-job-dating mt-2", onSubmit: onSubmit },
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "form-group col-12 col-md-6", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "prenom", className: "required" }, "Pr\u00E9nom"),
                                React.createElement("input", { required: true, type: "text", className: "form-control", id: "prenom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.prenom, placeholder: "Barack", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { prenom: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12 col-md-6" },
                                React.createElement("label", { htmlFor: "nom", className: "required" }, "Nom"),
                                React.createElement("input", { required: true, type: "text", className: "form-control", id: "prenom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.nom, placeholder: "Obama", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { nom: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "email", className: "required" }, "Adresse email"),
                                React.createElement("input", { required: true, type: "email", className: "form-control", id: "nom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.email, placeholder: "barack.obama@gmail.com", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { email: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "email", className: "required" }, "Num\u00E9ro de t\u00E9l\u00E9phone"),
                                React.createElement("input", { required: true, type: "phone", className: "form-control", id: "nom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.phoneNumber, placeholder: "06 XX XX XX XX", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { phoneNumber: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "formation", className: "required" }, "Formation"),
                                React.createElement(Select, { required: true, placeholder: "PGE 2A", value: (etudiant === null || etudiant === void 0 ? void 0 : etudiant.formation)
                                        ? {
                                            value: etudiant.formation,
                                            label: etudiant.formation,
                                        }
                                        : null, onChange: function (selectedOption) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { formation: selectedOption ? selectedOption.value : "" }));
                                    }, options: makeOptions([
                                        "PGE 2A",
                                        "PGE 3A",
                                        "Master Recherche",
                                        "Master Spécialisé",
                                        "Jeune diplômé",
                                        "Alternant 1A",
                                        "Alternant 2A",
                                        "Alternant 3A",
                                    ]) })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "motivation", className: "required" }, "Motivation (500 caract\u00E8res maximum)"),
                                React.createElement("textarea", { style: { height: 150, width: "100%" }, cols: 100, rows: 5, maxLength: 500, required: true, className: "form-control", id: "motivation", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.motivation, placeholder: "Je souhaite \u00E9changer avec vous car...", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { motivation: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "cv", className: "required " }, (etudiant === null || etudiant === void 0 ? void 0 : etudiant.lien_cv)
                                    ? "Déposer un nouveau CV"
                                    : "Déposer un CV"),
                                React.createElement("div", { className: "row d-flex flex-row justify-content-center  align-items-center", style: { marginTop: 12 } },
                                    React.createElement("input", { name: "file", id: "fileInputElement", type: "file", className: "row" }),
                                    ((_a = fileInputElement === null || fileInputElement === void 0 ? void 0 : fileInputElement.files) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement("i", { className: "fa fa-3x fa-check text-green mr-2", "aria-hidden": "true" })) : null),
                                (etudiant === null || etudiant === void 0 ? void 0 : etudiant.lien_cv) ? (React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        marginTop: 18,
                                    } },
                                    React.createElement("a", { className: "btn btn-small btn-blue", href: etudiant.lien_cv, target: "_blank" }, "Lien vers votre CV"))) : null),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }, className: "form-group col-12 justify-content-center mt-3" },
                                React.createElement("button", { disabled: loading, type: "submit", className: loading ? "btn btn-primary disabled" : "btn btn-primary" }, loading ? "Chargement..." : "Enregistrer mes informations")))))))));
};
export default EtudiantsCompte;
