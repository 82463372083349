var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useCallback, useState } from "react";
import { makeOptions } from "../../utils";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import useEtudiant from "../../hooks/useEtudiant";
import Select from "react-select";
function makeOptionsExposant(l) {
    try {
        return l.map(function (v) { return ({ value: v, label: v.titre }); });
    }
    catch (e) {
        return [];
    }
}
var SignupEtudiant = function (props) {
    var _a;
    var fileInputElement = document.querySelector("#fileInputElement");
    var _b = useState(), etudiantCreated = _b[0], setEtudiantCreated = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(), etudiant = _d[0], setEtudiant = _d[1];
    var _e = useEtudiant(), createEtudiant = _e[0], getEtudiant = _e[1], updateEtudiant = _e[2], deleteEtudiant = _e[3];
    var onSuccess = useCallback(function () {
        window.location.href = "/espace-etudiant/compte";
        setLoading(false);
    }, []);
    var onFail = useCallback(function () {
        toastr.error("Échec", "L'inscription a échoué", {
            position: "top-right",
        });
        setLoading(false);
    }, []);
    var onSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedEtudiant;
        return __generator(this, function (_a) {
            e.preventDefault();
            setLoading(true);
            if (etudiant.password !== etudiant.passwordConfirmation) {
                toastr.error("Erreur", "Veuillez saisir deux mots de passe identiques", {
                    position: "top-right",
                });
                setLoading(false);
                return [2 /*return*/];
            }
            if (!etudiant.prenom ||
                !etudiant.nom ||
                !etudiant.email ||
                !etudiant.formation ||
                !etudiant.motivation ||
                !etudiant.password ||
                !etudiant.passwordConfirmation ||
                fileInputElement.files.length === 0) {
                toastr.error("Erreur", "Veuillez remplir tous les champs", {
                    position: "top-right",
                });
                setLoading(false);
                return [2 /*return*/];
            }
            formattedEtudiant = __assign(__assign({}, etudiant), { formation: etudiant.formation.value, file: fileInputElement.files[0] });
            console.log(formattedEtudiant);
            createEtudiant(formattedEtudiant, onSuccess, onFail);
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("section", { className: "exposants mt-5" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row justify-content-center mb-5" },
                React.createElement("div", { className: "col-lg-6 text-center" },
                    React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Inscription"),
                    React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
            React.createElement("div", { className: "d-flex flex-row justify-content-center align-items-center mb-2" },
                React.createElement("p", { className: "cmb-0 mr-2" }, "Les inscriptions pour l'ann\u00E9e 2023 ont \u00E9t\u00E9 clotur\u00E9es")),
            React.createElement("div", { className: "d-flex flex-row justify-content-center align-items-center mb-2" },
                React.createElement("p", { className: "mb-0 mr-2" }, "Vous avez d\u00E9j\u00E0 cr\u00E9\u00E9 votre compte ?"),
                React.createElement(Link, { to: "/login-etudiant", className: "text-blue text-underline pe-auto" }, "Connectez-vous")))));
    if (etudiantCreated === null || etudiantCreated === void 0 ? void 0 : etudiantCreated.error) {
        return (React.createElement("section", { className: "exposants mt-5 d-flex justify-content-center align-items-center", style: { minHeight: "90vh" } },
            React.createElement("div", { className: "container " },
                React.createElement("div", { className: "row d-flex flex-row justify-content-center  align-items-center" },
                    React.createElement("i", { className: "fa fa-3x fa-times text-red mr-2", "aria-hidden": "true" }),
                    React.createElement("h1", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small text-center" }, "Une erreur est survenue.")),
                React.createElement("div", { className: "row d-flex flex-row justify-content-center  align-items-center mt-2 mb-2" },
                    React.createElement("h2", { className: "font-alt font-w-600 letter-spacing-1 m-0 text-gray-800 text-uppercase text-small text-center" }, "Essayez avec un autre fichier.")))));
    }
    if ((etudiantCreated === null || etudiantCreated === void 0 ? void 0 : etudiantCreated.length) === 2) {
        return (React.createElement("section", { className: "exposants mt-5 d-flex justify-content-center align-items-center", style: { minHeight: "90vh" } },
            React.createElement("div", { className: "container " },
                React.createElement("div", { className: "row d-flex flex-row justify-content-center  align-items-center" },
                    React.createElement("i", { className: "fa fa-3x fa-check text-green mr-2", "aria-hidden": "true" }),
                    React.createElement("h1", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small text-center" }, "Merci pour votre inscription !")),
                React.createElement("div", { className: "row d-flex flex-row justify-content-center  align-items-center mt-2 mb-2" },
                    React.createElement("h2", { className: "font-alt font-w-600 letter-spacing-1 m-0 text-gray-800 text-uppercase text-small text-center" }, "Retrouvez l'avanc\u00E9e de vos candidatures sur l'onglet Dashboard")),
                React.createElement("div", { className: "row d-flex justify-content-center  align-items-center mt-2" },
                    React.createElement("button", { onClick: function () { return props.history.push("/job-dating/candidatures"); }, type: "submit", className: "btn btn-primary", style: { width: 300, padding: 10, marginTop: 12 } }, "Acc\u00E9der au dashboard")))));
    }
    return (React.createElement("section", { className: "exposants mt-5" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row justify-content-center mb-5" },
                React.createElement("div", { className: "col-lg-6 text-center" },
                    React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Inscription"),
                    React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Cr\u00E9ez votre compte \u00E9tudiant pour obtenir un cr\u00E9neau privil\u00E9gi\u00E9 lors du forum"),
                    React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
            React.createElement("div", { className: "d-flex flex-row justify-content-center align-items-center mb-2" },
                React.createElement("p", { className: "mb-0 mr-2" }, "Vous avez d\u00E9j\u00E0 cr\u00E9\u00E9 votre compte ?"),
                React.createElement(Link, { to: "/login-etudiant", className: "text-blue text-underline pe-auto" }, "Connectez-vous")),
            React.createElement("form", { id: "inscription-job-dating mt-2", onSubmit: onSubmit },
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "form-group col-12 col-md-6", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "prenom", className: "required" }, "Pr\u00E9nom"),
                                React.createElement("input", { required: true, type: "text", className: "form-control", id: "prenom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.prenom, placeholder: "Barack", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { prenom: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12 col-md-6" },
                                React.createElement("label", { htmlFor: "nom", className: "required" }, "Nom"),
                                React.createElement("input", { required: true, type: "text", className: "form-control", id: "prenom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.nom, placeholder: "Obama", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { nom: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "email", className: "required" }, "Adresse email"),
                                React.createElement("input", { required: true, type: "email", className: "form-control", id: "nom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.email, placeholder: "barack.obama@gmail.com", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { email: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "email", className: "required" }, "Num\u00E9ro de t\u00E9l\u00E9phone"),
                                React.createElement("input", { required: true, type: "phone", className: "form-control", id: "nom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.phoneNumber, placeholder: "06 XX XX XX XX", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { phoneNumber: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "email", className: "required" }, "Mot de passe (8 caract\u00E8res minimum)"),
                                React.createElement("input", { required: true, type: "password", className: "form-control", id: "nom", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.password, minLength: 8, placeholder: "*********", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { password: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "password", className: "required" }, "Veuillez ressaisir votre mot de passe"),
                                React.createElement("input", { required: true, type: "password", className: "form-control", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.passwordConfirmation, minLength: 8, placeholder: "*********", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { passwordConfirmation: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "formation", className: "required" }, "Formation"),
                                React.createElement(Select, { required: true, placeholder: "PGE 2A", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.formation, onChange: function (e) { return setEtudiant(__assign(__assign({}, etudiant), { formation: e })); }, options: makeOptions([
                                        "PGE 2A",
                                        "PGE 3A",
                                        "Master Recherche",
                                        "Master Spécialisé",
                                        "Jeune diplômé",
                                        "Alternant 1A",
                                        "Alternant 2A",
                                        "Alternant 3A",
                                    ]) })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "motivation", className: "required" }, "Motivation (500 caract\u00E8res maximum)"),
                                React.createElement("textarea", { style: { height: 150, width: "100%" }, cols: 100, rows: 5, maxLength: 500, required: true, className: "form-control", id: "motivation", value: etudiant === null || etudiant === void 0 ? void 0 : etudiant.motivation, placeholder: "Je souhaite \u00E9changer avec vous car...", onChange: function (e) {
                                        return setEtudiant(__assign(__assign({}, etudiant), { motivation: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "cv", className: "required " }, "CV"),
                                React.createElement("div", { className: "row d-flex flex-row justify-content-center  align-items-center", style: { marginLeft: 12 } },
                                    React.createElement("input", { name: "file", id: "fileInputElement", type: "file", className: "row", required: true }),
                                    ((_a = fileInputElement === null || fileInputElement === void 0 ? void 0 : fileInputElement.files) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement("i", { className: "fa fa-3x fa-check text-green mr-2", "aria-hidden": "true" })) : null)),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }, className: "form-group col-12 justify-content-center mt-3" },
                                React.createElement("button", { disabled: loading, type: "submit", className: loading ? "btn btn-primary disabled" : "btn btn-primary" }, loading ? "Chargement..." : "Continuer")))))))));
};
export default SignupEtudiant;
