var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Link } from "react-router-dom";
import { Header } from "./HeaderBack";
import { Route, Switch } from "react-router";
import { News } from "../screens/back-admin/News";
import { EditPresentation } from "../components/back/EditPresentation";
import { EditPreparation } from "../components/back/EditPreparation";
import AllEntreprises from "../screens/back-admin/AllEntreprises";
import { connect } from "../redux/store";
import { New } from "../screens/back-admin/New";
import AllEntretiens from "../screens/back-admin/AllEntretiens";
import AdminEtudiants from "../screens/back-admin/AllEtudiants";
import EtudiantsCompte from "../screens/back-etudiants/EtudiantsCompte";
import EtudiantsEntretiens from "../screens/back-etudiants/EtudiantsEntretiens";
import EtudiantsDejeuners from "../screens/back-etudiants/EtudiantsDejeuners";
import AllDejeuners from "../screens/back-admin/AllDejeuners";
import { EditCompte } from "../components/back/EditCompte";
import { EntrepriseXAccount } from "../screens/back-admin/EntrepriseXAccount";
import { EntrepriseXDetails } from "../screens/back-admin/EntrepriseXDetails";
import EditEntretiens from "../components/back/EditEntretiens";
import EditDejeuners from "../components/back/EditDejeuners";
import { EntrepriseXEntretiens } from "../screens/back-admin/EntrepriseXEntretiens";
import { EntrepriseXDejeuners } from "../screens/back-admin/EntrepriseXDejeuners";
import EditExposant from "../components/back/EditExposant";
var Back = /** @class */ (function (_super) {
    __extends(Back, _super);
    function Back() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Back.prototype.render = function () {
        if (!this.props.userId) {
            return (React.createElement("div", null,
                "Vous devez ",
                React.createElement(Link, { to: "/login" }, "\u00EAtre connect\u00E9"),
                " pour acc\u00E9der \u00E0 cette page."));
        }
        return (React.createElement("div", { className: "main-wrapper back", style: {
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
            } },
            React.createElement(Header, null),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/espace-etudiant/compte", component: EtudiantsCompte }),
                React.createElement(Route, { path: "/espace-etudiant/job-dating", component: EtudiantsEntretiens }),
                React.createElement(Route, { path: "/espace-etudiant/business-lunch", component: EtudiantsDejeuners }),
                React.createElement(Route, { path: "/espace-entreprise/details", component: EditExposant }),
                React.createElement(Route, { path: "/espace-entreprise/compte", component: EditCompte }),
                React.createElement(Route, { path: "/espace-entreprise/job-dating", component: EditEntretiens }),
                React.createElement(Route, { path: "/espace-entreprise/business-lunch", component: EditDejeuners }),
                React.createElement(Route, { path: "/admin/entreprise/:id/details", component: EntrepriseXDetails }),
                React.createElement(Route, { path: "/admin/entreprise/:id/compte", component: EntrepriseXAccount }),
                React.createElement(Route, { path: "/admin/entreprise/:id/job-dating", component: EntrepriseXEntretiens }),
                React.createElement(Route, { path: "/admin/entreprise/:id/business-lunch", component: EntrepriseXDejeuners }),
                React.createElement(Route, { path: "/admin/contenu/presentation", component: EditPresentation }),
                React.createElement(Route, { path: "/admin/contenu/bien-se-preparer", component: EditPreparation }),
                React.createElement(Route, { path: "/admin/actualites", component: News }),
                React.createElement(Route, { path: "/admin/entreprises/new", component: New }),
                React.createElement(Route, { path: "/admin/entreprises", component: AllEntreprises }),
                React.createElement(Route, { path: "/admin/entretiens", component: AllEntretiens }),
                React.createElement(Route, { path: "/admin/dejeuners", component: AllDejeuners }),
                React.createElement(Route, { path: "/admin/etudiants", component: AdminEtudiants }))));
    };
    Back = __decorate([
        connect(function (state) { return ({
            userId: state.session.userId,
        }); })
    ], Back);
    return Back;
}(React.Component));
export { Back };
