export var InitialStates;
(function (InitialStates) {
    InitialStates.session = {
        userId: null,
    };
    InitialStates.network = {
        exposants: {},
        news: {},
        users: {},
        usersByExposants: {},
    };
})(InitialStates || (InitialStates = {}));
