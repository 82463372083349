var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { withApi } from "../../decorators/api";
import { getProp } from "../../redux/store";
import EntrepriseXHeader from "../../components/back/EntrepriseXHeader";
import EditExposant from "../../components/back/EditExposant";
var EntrepriseXDetails = /** @class */ (function (_super) {
    __extends(EntrepriseXDetails, _super);
    function EntrepriseXDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EntrepriseXDetails.prototype.componentWillMount = function () {
        this.props.loadAllEntreprises();
    };
    EntrepriseXDetails.prototype.render = function () {
        var entreprise = getProp(this.props, "exposants." + this.props.match.params.id);
        return (React.createElement("section", { className: "clearfix bg-dark listingSection" },
            React.createElement(EntrepriseXHeader, { entreprise: entreprise }),
            React.createElement(EditExposant, { adminExposantId: this.props.match.params.id })));
    };
    EntrepriseXDetails = __decorate([
        withApi(function (state) { return ({
            exposants: state.network.exposants,
            isAdmin: state.session.admin,
            exposantId: state.session.exposant,
        }); })
    ], EntrepriseXDetails);
    return EntrepriseXDetails;
}(React.Component));
export { EntrepriseXDetails };
