var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import config from "../../config";
import config2 from "../../config2";
var Hero = /** @class */ (function (_super) {
    __extends(Hero, _super);
    function Hero() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Hero.prototype.render = function () {
        return (React.createElement("section", { id: "hero-bg-static", className: "bg-img-cover bg-overlay-black-7 h-100 p-0" },
            React.createElement("div", { className: "container h-100" },
                React.createElement("div", { className: "row align-items-center h-100 justify-content-center" },
                    React.createElement("div", { className: "col-lg-10 col-xl-7 text-center" },
                        React.createElement("h2", { className: "font-alt font-w-700 letter-spacing-1 mt-3 text-gray-100 text-uppercase title-large title-sm-extra-large title-md-extra-large-3 title-lg-extra-large-5" }, "Forum Arts et M\u00E9tiers"),
                        React.createElement("h3", { className: "font-alt font-w-600 letter-spacing-1 mt-4 text-gray-200 text-medium text-uppercase" }, "Explorez, rencontrez, b\u00E2tissez votre avenir !"),
                        React.createElement("a", { target: "_blank", href: config2.GuideprepaUrl, className: "page-scroll btn btn-blue btn-small btn-lg-large mt-5 mx-0" },
                            React.createElement("i", { className: "fa fa-download fa-fw" }),
                            "\u00C9tudiants - Guide de pr\u00E9paration"),
                        React.createElement("a", { target: "_blank", href: config.plaquetteUrl, className: "page-scroll btn btn-blue btn-small btn-lg-large mt-5 mx-0" },
                            React.createElement("i", { className: "fa fa-download fa-fw" }),
                            "Entreprises - Plaquette commerciale"))))));
    };
    return Hero;
}(React.Component));
export { Hero };
