import * as React from "react";
import { Link } from "react-router-dom";
var EntrepriseXHeader = function (_a) {
    var entreprise = _a.entreprise;
    return (React.createElement("div", { className: "container" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-12 pb-3" },
                React.createElement(Link, { to: "/admin/entreprises" },
                    React.createElement("i", { className: "fa fa-arrow-left fa-fw" }),
                    " Retour aux entreprises")),
            entreprise == null ? null : (React.createElement("div", { className: "col-12 pb-3" },
                React.createElement("h1", null,
                    "Entreprise : ",
                    entreprise.titre),
                React.createElement("div", { style: { position: "absolute", top: 0, right: 0 } },
                    React.createElement(Link, { className: "btn btn-primary btn-small", to: "/admin/entreprise/" + entreprise.id + "/job-dating" }, "JD"),
                    React.createElement(Link, { className: "btn btn-primary btn-small", to: "/admin/entreprise/" + entreprise.id + "/business-lunch" }, "BL"),
                    React.createElement(Link, { className: "btn btn-primary btn-small", to: "/admin/entreprise/" + entreprise.id + "/details" }, "D\u00E9tails"),
                    "\u00A0 \u00A0",
                    React.createElement(Link, { className: "btn btn-primary btn-small", to: "/admin/entreprise/" + entreprise.id + "/compte" }, "Compte")))))));
};
export default EntrepriseXHeader;
