var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { Route, Switch } from "react-router";
import { Exposants } from "../screens/exposants/Exposants";
import { Footer } from "./Footer";
import { Home } from "../screens/home/Home";
import { Header } from "./HeaderFront";
import { Preparation } from "../screens/static/Preparation";
import { Contact } from "../screens/others/Contact";
import { Horaires } from "../screens/others/Horaires";
import { Planning } from "../screens/others/Planning";
import { Presentation } from "../screens/static/Presentation";
import { Restauration } from "../screens/static/Restauration";
import Legal from "../screens/others/Legal";
import { LoginEtudiant } from "../screens/auth/LoginEtudiant";
import { LoginExposant } from "../screens/auth/LoginExposant";
import SignupEtudiant from "../screens/auth/SignupEtudiant";
import Confidentialite from "../screens/others/Confidentialite";
var Front = /** @class */ (function (_super) {
    __extends(Front, _super);
    function Front() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Front.prototype.render = function () {
        return (React.createElement("div", { style: { display: "block", width: "100%", height: "100%" } },
            React.createElement(React.Fragment, null,
                React.createElement(Header, null),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/presentation", component: Presentation }),
                    React.createElement(Route, { path: "/bien-se-preparer", component: Preparation }),
                    React.createElement(Route, { path: "/horaires", component: Horaires }),
                    React.createElement(Route, { path: "/planning", component: Planning }),
                    React.createElement(Route, { path: "/restauration", component: Restauration }),
                    React.createElement(Route, { path: "/inscription-etudiant", component: SignupEtudiant }),
                    React.createElement(Route, { path: "/login-etudiant", component: LoginEtudiant }),
                    React.createElement(Route, { path: "/login-entreprise", component: LoginExposant }),
                    React.createElement(Route, { path: "/exposants/:id", component: Exposants }),
                    React.createElement(Route, { path: "/exposants", component: Exposants }),
                    React.createElement(Route, { path: "/contact", component: Contact }),
                    React.createElement(Route, { path: "/legal", component: Legal }),
                    React.createElement(Route, { path: "/confidentialite", component: Confidentialite }),
                    React.createElement(Route, { path: "/", component: Home })),
                React.createElement(Footer, null))));
    };
    return Front;
}(React.Component));
export { Front };
