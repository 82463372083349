var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import * as classNames from "classnames";
import { Network, Session as SessionActions } from "../redux/actions";
import { connect } from "../redux/store";
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header(props) {
        var _this = _super.call(this, props) || this;
        _this.switchTab = function (newTab) {
            _this.setState({ currentPath: newTab, showMobile: false });
        };
        _this.state = {
            showMobile: false,
            showDropdown: false,
            currentPath: props.history.location.pathname, // initialize with current pathname
        };
        return _this;
    }
    Header.prototype.logout = function () {
        var self = this;
        this.props.dispatch(Network.request("/api/logout", {}, {
            action: function (json) {
                self.props.dispatch(SessionActions.logout());
                self.props.history.push("/");
                localStorage.removeItem("token");
            },
        }, "POST"));
    };
    Header.prototype.componentDidUpdate = function (prevProps) {
        console.log("component did update");
        console.log(prevProps);
        if (prevProps.history.location.pathname !==
            this.props.history.location.pathname) {
            this.setState({ currentPath: this.props.history.location.pathname });
        }
    };
    Header.prototype.render = function () {
        var _this = this;
        var self = this;
        return (React.createElement("nav", { className: "navbar navbar-expand-lg navbar-dark bg-dark", id: "back-navbar" },
            React.createElement(Link, { className: "navbar-brand", to: "/" }, "Forum Arts & M\u00E9tiers"),
            React.createElement("button", { className: "navbar-toggler", onClick: function () {
                    self.setState(function (state) { return ({ showMobile: !state.showMobile }); });
                }, type: "button", "data-toggle": "collapse", "data-target": "#navbarSupportedContent", "aria-controls": "navbarSupportedContent", "aria-expanded": "false", "aria-label": "Toggle navigation" },
                React.createElement("span", { className: "navbar-toggler-icon" })),
            React.createElement("div", { className: classNames("collapse navbar-collapse", {
                    show: this.state.showMobile,
                }), id: "navbar-header" },
                React.createElement("ul", { className: "navbar-nav mr-auto" }, this.props.isAdmin ? (React.createElement(React.Fragment, null,
                    React.createElement("li", { className: classNames("nav-item dropdown", {
                            show: this.state.showDropdown,
                            active: this.matchContenu(this.state.currentPath),
                        }) },
                        React.createElement("a", { className: "nav-link dropdown-toggle", id: "navbarDropdown", role: "button", style: { cursor: "pointer" }, "data-toggle": "dropdown", "aria-haspopup": "true", "aria-expanded": "false", onClick: function () {
                                self.setState(function (state) { return ({
                                    showDropdown: !state.showDropdown,
                                }); });
                            } },
                            React.createElement("i", { className: "fa fa-fw fa-bars" }),
                            "Contenus"),
                        React.createElement("div", { className: classNames("dropdown-menu", {
                                show: this.state.showDropdown,
                            }), "aria-labelledby": "navbarDropdown" },
                            React.createElement(Link, { className: "dropdown-item", onClick: function () {
                                    self.setState({ showDropdown: false });
                                }, to: "/admin/contenu/presentation" }, "Pr\u00E9sentation"),
                            React.createElement(Link, { className: "dropdown-item", to: "/admin/contenu/bien-se-preparer", onClick: function () {
                                    self.setState({ showDropdown: false });
                                } }, "Bien se pr\u00E9parer"),
                            React.createElement(Link, { to: "/admin/actualites", className: "dropdown-item", onClick: function () {
                                    self.setState({ showDropdown: false });
                                } }, "Actualit\u00E9s"))),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/admin/entreprises"),
                        }) },
                        React.createElement(Link, { onClick: function () { return _this.switchTab("/admin/entreprises"); }, to: "/admin/entreprises", className: "nav-link" },
                            React.createElement("i", { className: "fa fa-building fa-fw", "aria-hidden": "true" }),
                            "Entreprises")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/admin/entreprises/new"),
                        }) },
                        React.createElement(Link, { onClick: function () { return _this.switchTab("/admin/entreprises/new"); }, to: "/admin/entreprises/new", className: "nav-link" },
                            React.createElement("i", { className: "fa fa-building fa-fw", "aria-hidden": "true" }),
                            "+")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchEtudiants(this.state.currentPath),
                        }) },
                        React.createElement(Link, { onClick: function () { return _this.switchTab("/admin/etudiants"); }, to: "/admin/etudiants", className: "nav-link" },
                            React.createElement("i", { className: "fa fa-users fa-fw", "aria-hidden": "true" }),
                            "\u00C9tudiants")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/admin/entretiens"),
                        }) },
                        React.createElement(Link, { onClick: function () { return _this.switchTab("/admin/entretiens"); }, to: "/admin/entretiens", className: "nav-link" },
                            React.createElement("i", { className: "fa fa-briefcase fa-fw", "aria-hidden": "true" }),
                            "Job dating")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/admin/dejeuners"),
                        }) },
                        React.createElement(Link, { onClick: function () { return _this.switchTab("/admin/dejeuners"); }, to: "/admin/dejeuners", className: "nav-link" },
                            React.createElement("i", { className: "fa fa-briefcase fa-fw", "aria-hidden": "true" }),
                            "Business lunch")))) : this.props.isExposant ? (React.createElement(React.Fragment, null,
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchEditEntrepriseDetails(this.state.currentPath),
                        }) },
                        React.createElement(Link, { onClick: function () { return _this.switchTab("/espace-entreprise/details"); }, className: "nav-link", to: "/espace-entreprise/details" },
                            React.createElement("i", { className: "fa fa-fw fa-user", "aria-hidden": "true" }),
                            "D\u00E9tails de l'entreprise")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchEditEntrepriseCompte(this.state.currentPath),
                        }) },
                        React.createElement(Link, { onClick: function () { return _this.switchTab("/espace-entreprise/compte"); }, className: "nav-link", to: "/espace-entreprise/compte" },
                            React.createElement("i", { className: "fa fa-fw fa-cogs", "aria-hidden": "true" }),
                            "Compte")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/espace-entreprise/job-dating"),
                        }) },
                        React.createElement(Link, { onClick: function () {
                                return _this.switchTab("/espace-entreprise/job-dating");
                            }, className: "nav-link", to: "/espace-entreprise/job-dating" },
                            React.createElement("i", { className: "fa fa-fw fa-briefcase", "aria-hidden": "true" }),
                            "Job dating")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/espace-entreprise/business-lunch"),
                        }) },
                        React.createElement(Link, { onClick: function () {
                                return _this.switchTab("/espace-entreprise/business-lunch");
                            }, className: "nav-link", to: "/espace-entreprise/business-lunch" },
                            React.createElement("i", { className: "fa fa-fw fa-cutlery", "aria-hidden": "true" }),
                            "Business lunch")))) : (React.createElement(React.Fragment, null,
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/espace-etudiant/compte"),
                        }) },
                        React.createElement(Link, { onClick: function () { return _this.switchTab("/espace-etudiant/compte"); }, className: "nav-link", to: "/espace-etudiant/compte" },
                            React.createElement("i", { className: "fa fa-fw fa-user", "aria-hidden": "true" }),
                            "Compte")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/espace-etudiant/job-dating"),
                        }) },
                        React.createElement(Link, { onClick: function () {
                                return _this.switchTab("/espace-etudiant/job-dating");
                            }, className: "nav-link", to: "/espace-etudiant/job-dating" },
                            React.createElement("i", { className: "fa fa-fw fa-briefcase", "aria-hidden": "true" }),
                            "Job dating")),
                    React.createElement("li", { className: classNames("nav-item", {
                            active: this.matchRoute(this.state.currentPath, "/espace-etudiant/business-lunch"),
                        }) },
                        React.createElement(Link, { onClick: function () {
                                return _this.switchTab("/espace-etudiant/business-lunch");
                            }, className: "nav-link", to: "/espace-etudiant/business-lunch" },
                            React.createElement("i", { className: "fa fa-fw fa-cutlery", "aria-hidden": "true" }),
                            "Business lunch"))))),
                React.createElement("button", { className: "btn btn-extra-small btn-red my-2 my-sm-0", onClick: function () {
                        self.logout();
                    } }, "D\u00E9connexion"),
                React.createElement("a", { href: "/", className: "btn btn-extra-small btn-orange my-2 my-sm-0" }, "Voir le site"))));
    };
    Header.prototype.matchRoute = function (pathName, value) {
        console.log(pathName, value);
        return pathName === value;
    };
    Header.prototype.matchEntretien = function (pathName) {
        return pathName.startsWith("/espace-etudiant/job-dating");
    };
    Header.prototype.matchDejeuners = function (pathName) {
        return pathName.startsWith("/espace-etudiant/business-lunch");
    };
    Header.prototype.matchEditEntrepriseDetails = function (pathName) {
        return pathName.startsWith("/espace-entreprise/details");
    };
    Header.prototype.matchEditEntrepriseCompte = function (pathName) {
        return pathName.startsWith("/espace-entreprise/compte");
    };
    Header.prototype.matchActualites = function (pathName) {
        return pathName.startsWith("/admin/actualites");
    };
    Header.prototype.matchAllEntreprises = function (pathName) {
        console.log(pathName);
        return pathName.startsWith("/admin/entreprises");
    };
    Header.prototype.matchEtudiants = function (pathName) {
        return pathName.startsWith("/admin/etudiants");
    };
    Header.prototype.matchEntretiens = function (pathName) {
        return pathName.startsWith("/admin/entretiens");
    };
    Header.prototype.matchContenu = function (pathName) {
        return (pathName.startsWith("/admin/contenu/presentation") ||
            pathName.startsWith("/admin/contenu/jour-j"));
    };
    Header = __decorate([
        connect(function (rootState) { return ({
            isAdmin: rootState.session.admin,
            isExposant: rootState.session.exposant,
        }); }),
        withRouter,
        __metadata("design:paramtypes", [Object])
    ], Header);
    return Header;
}(React.Component));
export { Header };
