import { compose, createStore, applyMiddleware } from 'redux';
import { connect as reduxConnect } from 'react-redux';
import thunk from 'redux-thunk';
var rl = require('redux-localstorage');
import persistState, { mergePersistedState } from 'redux-localstorage';
var adapter = require('redux-localstorage/lib/adapters/localStorage');
import filter from 'redux-localstorage-filter';
var objectPath = require('object-path');
import { createReducer } from './reducers';
/**
 * The redux store singleton.
 */
var store = null;
/**
 * Create the redux store, apply the following middlewares :
 *    - thunk for async actions
 */
export function configureStore() {
    if (store == null) {
        var storage = compose(filter('session'))(adapter(localStorage));
        var rootReducer = createReducer();
        var reducer = compose(mergePersistedState())(rootReducer);
        var enhancer = compose(applyMiddleware(thunk), persistState(storage, 'forum_am'));
        store = createStore(reducer, {}, enhancer);
    }
    return store;
}
export function dispatch(action) {
    if (store != null) {
        store.dispatch(action);
    }
}
/**
 * Simplify the react-redux connect decorator : only require a state to props map.
 * We always set
 */
export function connect(stateToProps) {
    if (stateToProps === void 0) { stateToProps = null; }
    if (stateToProps == null) {
        stateToProps = function () { return ({}); };
    }
    return reduxConnect(stateToProps, function (dispatch) { return ({
        dispatch: dispatch,
        store: store,
    }); });
}
/**
 * Proxy function to object-path.get(), to make it more intuitive in a react component.
 */
export function getProp(props, path, def) {
    if (def === void 0) { def = null; }
    return objectPath.get(props, path, def);
}
