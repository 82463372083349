var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useState, useEffect } from "react";
import MultiSelect from "react-select";
import { extractValues, makeOptionsObjects, } from "../../utils";
import Reorder, { reorder } from "react-reorder";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import useEtudiant from "../../hooks/useEtudiant";
import useDejeuner from "../../hooks/useDejeuner";
var EtudiantsDejeuners = function (props) {
    var _a = useState([]), exposantsDisponibles = _a[0], setExposantsDisponibles = _a[1];
    var _b = useState([]), exposantsChoisis = _b[0], setExposantsChoisis = _b[1];
    var _c = useState([]), preferences = _c[0], setPreferences = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(), etudiant = _e[0], setEtudiant = _e[1];
    var etudiantId = useSelector(function (state) { return state.session.etudiant; });
    var _f = useEtudiant(), createEtudiant = _f[0], getEtudiant = _f[1], updateEtudiant = _f[2], deleteEtudiant = _f[3];
    var _g = useDejeuner(), getExposantsDisponibles = _g[0], applyDejeuners = _g[1], updateDejeuner = _g[2];
    useEffect(function () {
        getEtudiant(etudiantId, setEtudiant);
        getExposantsDisponibles(setExposantsDisponibles);
    }, []);
    useEffect(function () {
        if (!(etudiant === null || etudiant === void 0 ? void 0 : etudiant.dejeuner_preferences) || !exposantsDisponibles)
            return;
        var dejeunerPreferences = JSON.parse(etudiant.dejeuner_preferences);
        var filteredExposants = exposantsDisponibles
            .filter(function (item) { return dejeunerPreferences.includes(item.id); })
            .sort(function (a, b) {
            return dejeunerPreferences.indexOf(a.id) - dejeunerPreferences.indexOf(b.id);
        });
        setExposantsChoisis(filteredExposants);
    }, [etudiant, exposantsDisponibles]);
    var onSuccess = function (etudiant) {
        if (etudiant)
            setEtudiant(etudiant);
        toastr.success("Modification réussie", "Vos préférences ont bien été prises en compte", {
            position: "top-right",
        });
        setLoading(false);
    };
    var onFail = function () {
        toastr.error("Échec", "Un problème est survenu", {
            position: "top-right",
        });
        setLoading(false);
    };
    var onSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedPreferences;
        return __generator(this, function (_a) {
            e.preventDefault();
            setLoading(true);
            formattedPreferences = {
                dejeuner_preferences: JSON.stringify(preferences),
            };
            applyDejeuners(etudiantId, formattedPreferences, onSuccess, onFail);
            return [2 /*return*/];
        });
    }); };
    var onSubmitParticipation = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedEtudiant;
        return __generator(this, function (_a) {
            e.preventDefault();
            setLoading(true);
            formattedEtudiant = {
                id: etudiantId,
                dejeuner: true,
            };
            updateEtudiant(formattedEtudiant, onSuccess, onFail);
            return [2 /*return*/];
        });
    }); };
    var onCancelParticipation = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedEtudiant;
        return __generator(this, function (_a) {
            e.preventDefault();
            setLoading(true);
            formattedEtudiant = {
                id: etudiantId,
                dejeuner: false,
                dejeuner_preferences: null,
            };
            updateEtudiant(formattedEtudiant, onSuccess, onFail);
            return [2 /*return*/];
        });
    }); };
    var handleReorder = function (event, previousIndex, nextIndex, fromId, toId) {
        setExposantsChoisis(reorder(exposantsChoisis, previousIndex, nextIndex));
    };
    var onChangeExposants = function (e) {
        var newExposants = extractValues(e);
        if (exposantsChoisis.length > 4 &&
            newExposants.length > exposantsChoisis.length) {
            alert("Vous ne pouvez choisir que maximum 5 entreprises");
            return;
        }
        setExposantsChoisis(newExposants);
    };
    useEffect(function () {
        setPreferences(exposantsChoisis.map(function (e) { return e.id; }));
    }, [exposantsChoisis]);
    return (React.createElement("section", { className: "exposants mt-5" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row justify-content-center mb-5" },
                React.createElement("div", { className: "col-lg-6 text-center" },
                    React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Business lunch"),
                    React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Partagez un d\u00E9jeuner traiteur avec des recruteurs et d'autres \u00E9tudiants"),
                    React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
            (etudiant === null || etudiant === void 0 ? void 0 : etudiant.dejeuner) ? (React.createElement("form", { id: "inscription-job-dating mt-2", onSubmit: onSubmit },
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("div", { className: "row" },
                            React.createElement("label", { htmlFor: "entreprises" }, "Choisissez 5 entreprises qui vous int\u00E9ressent"),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement(MultiSelect, { placeholder: "S\u00E9lectionnez les entreprises qui vous int\u00E9ressent", required: true, value: makeOptionsObjects(exposantsChoisis), isMulti: true, onChange: onChangeExposants, options: makeOptionsObjects(exposantsDisponibles) })),
                            exposantsChoisis.length > 0 ? (React.createElement(React.Fragment, null,
                                React.createElement("label", { htmlFor: "entreprises" }, "Classez-les par odre de pr\u00E9f\u00E9rence"),
                                React.createElement(Reorder, { reorderId: "my-list" // Unique ID that is used internally to track this list (required)
                                    , reorderGroup: "reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
                                    , component: "ul" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                                    , placeholderClassName: "placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                                    , style: {
                                        width: "100%",
                                        paddingRight: "15px",
                                        paddingLeft: "15px",
                                        height: 70 * exposantsChoisis.length,
                                    }, draggedClassName: "dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                    , lock: "horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                                    , holdTime: 500, touchHoldTime: 200, mouseHoldTime: 0, onReorder: handleReorder, autoScroll: true, disabled: false, disableContextMenus: true, placeholder: React.createElement("div", { style: {
                                            height: 60,
                                            width: "100%",
                                            borderRadius: 4,
                                            border: "1px dashed #DCDCDC",
                                            marginBottom: 4,
                                        } }) }, exposantsChoisis.map(function (item, index) { return (React.createElement("li", { style: {
                                        listStyle: "none",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: 60,
                                        padding: "8px 12px",
                                        borderRadius: 4,
                                        marginBottom: 4,
                                        border: "1px solid #DCDCDC",
                                        cursor: "move",
                                        backgroundColor: "white",
                                    }, key: item.id },
                                    React.createElement("div", null,
                                        React.createElement("span", { style: { fontWeight: 600, display: "block" } }, item.titre),
                                        React.createElement("span", { style: {
                                                fontSize: 12,
                                                color: "#888888",
                                                marginTop: 1,
                                                display: "block",
                                            } },
                                            item.dejeuner_count,
                                            " places disponibles")),
                                    React.createElement("span", { className: "font-alt", style: {
                                            fontWeight: 700,
                                            fontSize: 22,
                                            paddingRight: 12,
                                        } }, index + 1))); })))) : null,
                            React.createElement("div", { style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }, className: "form-group col-12 justify-content-center mt-3" },
                                React.createElement("button", { disabled: loading, type: "submit", className: loading
                                        ? "btn btn-primary disabled"
                                        : "btn btn-primary ml-3" }, loading ? "Chargement..." : "Enregistrer mes choix")),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }, className: "form-group col-12 justify-content-center" },
                                React.createElement("p", { onClick: onCancelParticipation, style: {
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    } }, "Je ne souhaite plus participer"))))))) : etudiant ? (React.createElement("form", { id: "inscription-job-dating mt-2", onSubmit: onSubmitParticipation },
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("div", { className: "row" },
                            React.createElement("label", { htmlFor: "entreprises" }, "Souhaitez-vous candidater pour un business lunch ?"),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }, className: "form-group col-12 justify-content-center mt-3" },
                                React.createElement("button", { disabled: loading, type: "submit", className: loading ? "btn btn-primary disabled" : "btn btn-primary" }, loading ? "Chargement..." : "Oui"))))))) : (React.createElement("div", { id: "inscription-job-dating mt-2" },
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("div", { className: "row" },
                            React.createElement("label", null, "Chargement...")))))))));
};
export default EtudiantsDejeuners;
