var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
var ReactQuill = require("react-quill");
require("quill/dist/quill.snow.css");
var TextEditor = /** @class */ (function (_super) {
    __extends(TextEditor, _super);
    function TextEditor(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        _this.editor = React.createRef();
        return _this;
    }
    TextEditor.prototype.componentDidMount = function () {
        var self = this;
        var quill = this.editor.current.editor;
        quill.on("text-change", function (delta, old, source) {
            var limit = self.props.max;
            if (quill.getLength() > limit) {
                quill.deleteText(limit, quill.getLength());
            }
        });
    };
    TextEditor.prototype.render = function () {
        var self = this;
        var strippedHtml = this.props.value.replace(/<[^>]+>/g, "");
        var num = strippedHtml.length;
        var max = this.props.max;
        var overflow = num == max;
        return (React.createElement(React.Fragment, null,
            React.createElement(ReactQuill, { ref: this.editor, value: this.props.value, onChange: function (newValue) {
                    if (newValue.replace(/<[^>]+>/g, "").length > 0 &&
                        newValue.replace(/<[^>]+>/g, "").length <= max) {
                        self.props.onChange(newValue);
                    }
                } }),
            React.createElement("div", { className: "text-right col-12" },
                React.createElement("span", { style: { color: overflow ? "red" : undefined, fontSize: "9px" } },
                    num,
                    "/",
                    max,
                    " caract\u00E8res."))));
    };
    TextEditor.defaultProps = {
        max: 500,
    };
    return TextEditor;
}(React.Component));
export { TextEditor };
