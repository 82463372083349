var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { getImageUrl } from "../../utils";
export function parseArray(value) {
    if (typeof value === "string") {
        try {
            value = JSON.parse(value);
            if (Array.isArray(value)) {
                value = value.join(" ; ");
            }
            return value;
        }
        catch (e) {
            return value;
        }
    }
    else {
        return value;
    }
}
var Details = /** @class */ (function (_super) {
    __extends(Details, _super);
    function Details() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Details.prototype.render = function () {
        if (this.props.exposant == null) {
            return (React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Chargement en cours ..."));
        }
        var exposant = this.props.exposant;
        return (React.createElement(React.Fragment, null,
            React.createElement("h3", { className: "text-center" }, exposant.titre),
            exposant.resume != null && exposant.resume != "" ? (React.createElement("div", { className: "py-4 text-center col-12", dangerouslySetInnerHTML: { __html: exposant.resume } })) : null,
            React.createElement("table", { className: "table table-bordered mt-3" },
                React.createElement("tbody", null,
                    this.renderTableLine("Site web", React.createElement("a", { target: "_blank", href: getImageUrl(exposant.site) }, exposant.site)),
                    this.renderTableLine("Domaine d'activité", parseArray(exposant.secteur)),
                    this.renderTableLine("Pays", parseArray(exposant.pays)),
                    this.renderTableLine("Nombre d'employés", parseArray(exposant.taille)),
                    this.renderTableLine("Profils recherchés", parseArray(exposant.profils)),
                    this.renderTableLine("Types de contrat", parseArray(exposant.type_contrat)))),
            this.renderSection(exposant.description, "Description"),
            this.renderSection(exposant.chiffres_cles, "Chiffres clés"),
            this.renderSection(exposant.mode_recrutement, "Mode de recrutement"),
            this.renderSection(exposant.postes, "Postes et responsabilités"),
            this.renderSection(exposant.message, "Message aux candidats")));
    };
    Details.prototype.renderSection = function (value, title) {
        if (value == null || value == "") {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("h4", { className: "pt-3" }, title),
            React.createElement("div", { className: "col-12", dangerouslySetInnerHTML: { __html: value } })));
    };
    Details.prototype.renderTableLine = function (label, value) {
        if (value == null || value == "") {
            return null;
        }
        return (React.createElement("tr", null,
            React.createElement("th", { scope: "row", className: "" }, label),
            React.createElement("td", null, value)));
    };
    return Details;
}(React.Component));
export { Details };
