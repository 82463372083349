var _a, _b;
import { combineReducers } from "redux";
var update = require("immutability-helper");
import { handleActions as handleActionsFull } from "redux-actions";
import * as Action from "./actions";
import { InitialStates } from "./state";
import { reducer as toastrReducer } from "react-redux-toastr";
import { Network } from "./actions";
/**************************************************************************************
 *
 * Mutability of reducers.
 *
 * Reducers MUST NOT modify the state. They MUST return a copy of it.
 * It is not pure immutability, because they can return the unchanged
 * data without cloning it.
 *
 * For this purpose, we heavily use https://github.com/kolodny/immutability-helper
 *
 *************************************************************************************/
/**
 * Create a root reducer that includes the reducers in this file, and async reducers (loaded with
 * code splitting).
 * The created reducer create the initial state.
 *
 * @param forms The initial forms models (for react-redux-forms)
 */
export function createReducer(forms) {
    if (forms === void 0) { forms = {}; }
    return combineReducers({
        network: networkReducer,
        session: sessionReducer,
        toastr: toastrReducer,
    });
}
/***************************
 *
 *        Utils functions
 *
 **************************/
/**
 * Wrap the original handleActions() of redux-action, but directly call reducers with actions' payload.
 *
 * @param reducers
 * @param initialState
 */
function handleActions(reducers, initialState) {
    var fullReducers = {};
    var _loop_1 = function (actions) {
        var reducer = reducers[actions];
        fullReducers[actions] = function (state, fullAction) {
            return reducer(state, fullAction.payload);
        };
    };
    for (var actions in reducers) {
        _loop_1(actions);
    }
    return handleActionsFull(fullReducers, initialState);
}
/***************************
 *
 *        Reducers
 *
 **************************/
var sessionReducer = handleActions((_a = {},
    _a[Action.Session.SET_USER] = function (state, action) {
        return update(state, {
            userId: { $set: action.userId },
            admin: { $set: action.admin },
            email: { $set: action.email },
            etudiant: { $set: action.etudiant },
            exposant: { $set: action.exposant },
            userType: { $set: action.userType },
        });
    },
    _a[Action.Session.LOGOUT] = function (state, action) {
        return update(state, {
            userId: { $set: null },
        });
    },
    _a), InitialStates.session);
var networkReducer = handleActions((_b = {},
    _b[Network.RECEIVE_DATA] = function (state, action) {
        return update(state, action.updater);
    },
    _b), InitialStates.network);
