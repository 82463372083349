import { createAction } from "redux-actions";
import { BASE_URL } from "../utils";
/**
 * Application state related to the user session.
 * This state is synced with the browser local storage.
 */
export var Session;
(function (Session) {
    Session.SET_USER = "app_set_user";
    Session.setUser = createAction(Session.SET_USER, function (userId, email, admin, exposant, etudiant, userType) { return ({
        userId: userId,
        email: email,
        admin: admin,
        exposant: exposant,
        etudiant: etudiant,
        userType: userType,
    }); });
    Session.LOGOUT = "logout";
    Session.logout = function () {
        return function (dispatch) {
            dispatch(createAction(Session.LOGOUT)());
        };
    };
    Session.TOGGLE_SIDEBAR = "toggle_sidebar";
    Session.toggleSidebar = createAction(Session.TOGGLE_SIDEBAR, function (open) { return ({ open: open }); });
})(Session || (Session = {}));
export var Network;
(function (Network) {
    Network.RECEIVE_DATA = "receiveData"; // Receive data from the server
    Network.COGNITO_TOKEN = "cognitoToken"; // Set the cognito tokens
    Network.REQUEST_STATUS = "requestStatus";
    Network.RECEIVE_STATUS = "receiveStatus";
    /**
     * Low level requests
     */
    Network.receiveData = createAction(Network.RECEIVE_DATA, function (updater) { return ({ updater: updater }); });
    Network.jsonRequest = function (url, method, body, noJson) {
        if (body === void 0) { body = {}; }
        if (noJson === void 0) { noJson = false; }
        var token = localStorage.getItem("token");
        var parameters = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-auth-token": token !== null && token !== void 0 ? token : "",
            },
            mode: "cors",
            credentials: "include",
            body: method == "GET" ? null : JSON.stringify(body),
        };
        var modifiedUrl = BASE_URL + url;
        var promise = fetch(modifiedUrl, parameters);
        if (noJson) {
            return promise;
        }
        else {
            return promise.then(function (response) {
                if (response.status == 200) {
                    return response.json();
                }
                else {
                    console.log(response.body);
                    return Promise.reject(response.body);
                }
            });
        }
    };
    Network.request = function (url, body, actions, method) {
        if (method === void 0) { method = "GET"; }
        return function (dispatch) {
            Network.jsonRequest(url, method, body, true).then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        if (actions.action != null) {
                            var action = actions.action(json);
                            if (action != null) {
                                dispatch(action);
                            }
                        }
                        else if (actions && actions.updater != null) {
                            dispatch(Network.receiveData(actions.updater(json)));
                        }
                    });
                }
            });
        };
    };
    /**
     * High level requests
     *  - Index : Update list(s) of entities (ie collections).
     *  - Entity : Update a single entity in a list. Usually, the Get Action
     *    for a single entity might receive more data than the Index Action.
     *
     * Categories are objects, where keys are the entities Id and values the
     * entities themselves.
     */
    function crudGetIndex(url, collections, resetCollections) {
        if (resetCollections === void 0) { resetCollections = false; }
        var updaterFactory = function (json) {
            var updater = {};
            for (var collection in collections) {
                var idField = collections[collection];
                if (resetCollections) {
                    var items = {};
                    for (var _i = 0, _a = json[collection]; _i < _a.length; _i++) {
                        var item = _a[_i];
                        items[item[idField]] = item;
                    }
                    updater[collection] = { $set: items };
                }
                else {
                    var itemsUpdater = { $merge: {} };
                    for (var _b = 0, _c = json[collection]; _b < _c.length; _b++) {
                        var item = _c[_b];
                        itemsUpdater["$merge"][item[idField]] = item;
                    }
                    updater[collection] = itemsUpdater;
                }
            }
            return updater;
        };
        return Network.request(url, {}, { updater: updaterFactory });
    }
    Network.crudGetIndex = crudGetIndex;
    function crudGetEntity(url, collection, idField) {
        if (idField === void 0) { idField = "id"; }
        // @param json is a single entity
        var updaterFactory = function (json) {
            var _a, _b;
            var updater = (_a = {},
                _a[collection] = (_b = {},
                    _b[json[idField]] = { $set: json },
                    _b),
                _a);
            return updater;
        };
        return Network.request(url, {}, { updater: updaterFactory });
    }
    Network.crudGetEntity = crudGetEntity;
    Network.requestStatus = createAction(Network.REQUEST_STATUS);
    Network.receiveStatus = createAction(Network.RECEIVE_STATUS);
})(Network || (Network = {}));
