var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
var Horaires = /** @class */ (function (_super) {
    __extends(Horaires, _super);
    function Horaires(props) {
        return _super.call(this, props) || this;
    }
    Horaires.prototype.render = function () {
        var self = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("section", { id: "schedule", className: "bg-white mt-5" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row justify-content-center mb-5" },
                        React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                            React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Horaires des transports pour les \u00E9tudiants"),
                            React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Cette ann\u00E9e et, comme les pr\u00E9c\u00E9dentes \u00E9ditions, tous les transports sont financ\u00E9s par la Fondation Arts et M\u00E9tiers"),
                            React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                    React.createElement("div", { className: "row justify-content-left" },
                        React.createElement("div", { className: "col-lg-8" },
                            React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Pour chaque campus :"),
                            React.createElement("p", { className: "subtitlepourhoraires" }, "Les horaires d'arriv\u00E9es et de m\u00E9tro sont \u00E0 titre indicatif. Les RE r\u00E9f\u00E9rent de chaque campus vous guideront durant tout votre trajet."))),
                    React.createElement("div", { className: "divhoraire" },
                        React.createElement("div", { className: "horaires_bordeau" },
                            React.createElement("p", { className: "title_horaire" }, "Aix-en-Provence"),
                            React.createElement("ol", { className: "progress", "data-steps": "7" },
                                React.createElement("li", { className: "" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "1"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "2"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "3"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "4"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore yellowcolorafter" },
                                        React.createElement("span", null, "6"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step yellowcolorbefore graycolorafter" },
                                        React.createElement("span", null, "7"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "8")))),
                            React.createElement("ol", { className: "name_station", "data-steps": "7" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "A&M Aix")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Gare Marseille St Charles")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Gare Marseille St Charles")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Paris Gare de Lyon")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Paris Gare de Lyon")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Chateau de Vincennes")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Parc Floral"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "6" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "TGV")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "M\u00E9tro 1")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "q"))),
                            React.createElement("ol", { className: "horaires", "data-steps": "7" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "4:40")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "5:30")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "6:02")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "9:12")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "9:35")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "9:40")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:00"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "6" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "TGV")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "M\u00E9tro 1")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "q"))),
                            React.createElement("ol", { className: "horairesretours", "data-steps": "7" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "22:10")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "21:25")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "21:25")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "18:10")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:55")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:50")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:30")))),
                        React.createElement("div", { className: "legende" },
                            React.createElement("p", { className: "marginsept" }, "Trajet"),
                            React.createElement("p", { className: "marginneuf" }, "Aller"),
                            React.createElement("p", { className: "margintroi" }, "Retour"))),
                    React.createElement("hr", { className: "hrhr" }),
                    React.createElement("div", { className: "divhoraire" },
                        React.createElement("div", { className: "horaires_bordeau" },
                            React.createElement("p", { className: "title_horaire" }, "Angers"),
                            React.createElement("ol", { className: "progress", "data-steps": "2" },
                                React.createElement("li", { className: "" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "1"))),
                                React.createElement("li", { className: "" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "1")))),
                            React.createElement("ol", { className: "name_station", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "A&M Angers")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Parc Floral"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "3" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus"))),
                            React.createElement("ol", { className: "horaires", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "5:50")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:00"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "3" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus"))),
                            React.createElement("ol", { className: "horairesretours", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "21:30")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:20")))),
                        React.createElement("div", { className: "legende" },
                            React.createElement("p", { className: "marginsept" }, "Trajet"),
                            React.createElement("p", { className: "marginneuf" }, "Aller"),
                            React.createElement("p", { className: "margintroi" }, "Retour"))),
                    React.createElement("hr", { className: "hrhr" }),
                    React.createElement("div", { className: "divhoraire" },
                        React.createElement("div", { className: "horaires_bordeau" },
                            React.createElement("p", { className: "title_horaire" }, "Bordeaux"),
                            React.createElement("ol", { className: "progress", "data-steps": "8" },
                                React.createElement("li", { className: "" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "1"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "2"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "3"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "4"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore greencolorafter" },
                                        React.createElement("span", null, "5"))),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "step greencolorbefore yellowcolorafter" },
                                        React.createElement("span", null, "6"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step yellowcolorbefore graycolorafter" },
                                        React.createElement("span", null, "7"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "8")))),
                            React.createElement("ol", { className: "name_station", "data-steps": "8" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "A&M Talence")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Gare Bordeaux")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Gare Bordeaux")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Gare Paris Montparnasse")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Gare Paris Montparnasse")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Nation (m\u00E9tro)")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Chateau de Vincennes")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Parc Floral"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "7" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "TGV")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "M\u00E9tro 6")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "M\u00E9tro 1")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "q"))),
                            React.createElement("ol", { className: "horaires", "data-steps": "8" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "6:35")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "7:30")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "7:45")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "9:45")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "9:55")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:05")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:10")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:30"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "7" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "TGV")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "M\u00E9tro 6")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "M\u00E9tro 1")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "q"))),
                            React.createElement("ol", { className: "horairesretours", "data-steps": "8" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "23:00")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "22:39")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "22:39")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "20:39")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "18:05")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:55")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:50")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:30")))),
                        React.createElement("div", { className: "legende" },
                            React.createElement("p", { className: "marginsept" }, "Trajet"),
                            React.createElement("p", { className: "marginneuf" }, "Aller"),
                            React.createElement("p", { className: "margintroi" }, "Retour"))),
                    React.createElement("hr", { className: "hrhr" }),
                    React.createElement("div", { className: "divhoraire" },
                        React.createElement("div", { className: "horaires_bordeau" },
                            React.createElement("p", { className: "title_horaire" }, "Chalon"),
                            React.createElement("ol", { className: "progress", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "1"))),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "step graycolorbefore  graycolorafter" },
                                        React.createElement("span", null, "6")))),
                            React.createElement("ol", { className: "name_station", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "A&M Chalon")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Parc Floral"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "3" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus"))),
                            React.createElement("ol", { className: "horaires", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "6:20")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:00"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "3" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus"))),
                            React.createElement("ol", { className: "horairesretours", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "21:00")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:20")))),
                        React.createElement("div", { className: "legende" },
                            React.createElement("p", { className: "marginsept" }, "Trajet"),
                            React.createElement("p", { className: "marginneuf" }, "Aller"),
                            React.createElement("p", { className: "margintroi" }, "Retour"))),
                    React.createElement("hr", { className: "hrhr" }),
                    React.createElement("div", { className: "divhoraire" },
                        React.createElement("div", { className: "horaires_bordeau" },
                            React.createElement("p", { className: "title_horaire" }, "Cluny"),
                            React.createElement("ol", { className: "progress", "data-steps": "7" },
                                React.createElement("li", { className: "" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "1"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "2"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "3"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "4"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore yellowcolorafter" },
                                        React.createElement("span", null, "6"))),
                                React.createElement("li", { className: "doneafter" },
                                    React.createElement("span", { className: "step yellowcolorbefore graycolorafter" },
                                        React.createElement("span", null, "7"))),
                                React.createElement("li", { className: "donebefore" },
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "8")))),
                            React.createElement("ol", { className: "name_station", "data-steps": "7" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "A&M Cluny")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Gare Macon TGV")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Gare Macon TGV")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Paris Gare de Lyon")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Paris Gare de Lyon")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Chateau de Vincennes")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Parc Floral"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "6" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "TGV")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "M\u00E9tro 1")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "q"))),
                            React.createElement("ol", { className: "horaires", "data-steps": "7" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "6:50")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "7:00")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "7:43")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "9:25")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "9:45")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "9:50")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:10"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "6" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "TGV")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "M\u00E9tro 1")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "\u00C0 Pied")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "q"))),
                            React.createElement("ol", { className: "horairesretours", "data-steps": "7" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "18h10")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:30")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:30")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "15:56")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "15:20")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "15:15")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "14:55")))),
                        React.createElement("div", { className: "legende" },
                            React.createElement("p", { className: "marginsept" }, "Trajet"),
                            React.createElement("p", { className: "marginneuf" }, "Aller"),
                            React.createElement("p", { className: "margintroi" }, "Retour"))),
                    React.createElement("hr", { className: "hrhr" }),
                    React.createElement("div", { className: "divhoraire" },
                        React.createElement("div", { className: "horaires_bordeau" },
                            React.createElement("p", { className: "title_horaire" }, "Lille"),
                            React.createElement("ol", { className: "progress", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "1"))),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "step graycolorbefore  graycolorafter" },
                                        React.createElement("span", null, "6")))),
                            React.createElement("ol", { className: "name_station", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "A&M Lille")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Parc Floral"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "3" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus"))),
                            React.createElement("ol", { className: "horaires", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "6:20")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:00"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "3" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus"))),
                            React.createElement("ol", { className: "horairesretours", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "21:00")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:20")))),
                        React.createElement("div", { className: "legende" },
                            React.createElement("p", { className: "marginsept" }, "Trajet"),
                            React.createElement("p", { className: "marginneuf" }, "Aller"),
                            React.createElement("p", { className: "margintroi" }, "Retour"))),
                    React.createElement("hr", { className: "hrhr" }),
                    React.createElement("div", { className: "divhoraire" },
                        React.createElement("div", { className: "horaires_bordeau" },
                            React.createElement("p", { className: "title_horaire" }, "Metz"),
                            React.createElement("ol", { className: "progress", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "step graycolorbefore graycolorafter" },
                                        React.createElement("span", null, "1"))),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "step graycolorbefore  graycolorafter" },
                                        React.createElement("span", null, "6")))),
                            React.createElement("ol", { className: "name_station", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "A&M Metz")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "name" }, "Parc Floral"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "3" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus"))),
                            React.createElement("ol", { className: "horaires", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "4:50")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereel" }, "10:00"))),
                            React.createElement("ol", { className: "iconetransition", "data-steps": "3" },
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus")),
                                React.createElement("li", null,
                                    React.createElement("span", null, "Bus"))),
                            React.createElement("ol", { className: "horairesretours", "data-steps": "2" },
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "22:30")),
                                React.createElement("li", null,
                                    React.createElement("span", { className: "horairereelretour" }, "17:10")))),
                        React.createElement("div", { className: "legende" },
                            React.createElement("p", { className: "marginsept" }, "Trajet"),
                            React.createElement("p", { className: "marginneuf" }, "Aller"),
                            React.createElement("p", { className: "margintroi" }, "Retour")))))));
    };
    return Horaires;
}(React.Component));
export { Horaires };
