import trackerApi from "../api/tracker";

export default () => {

   const updateAccount = async (userId, account, callback, fallback) => {
      try {
         await trackerApi.post(`/api/users/update/${userId}`, account)

         callback();
      } catch (e) {
         fallback();

         console.log(e)
      }
   };

   const getUsersByExposant = async (exposantId, setExposants) => {
      try {
         console.log(exposantId)

         const response = await trackerApi.get(`/api/users/byExposant/${exposantId}`);
         console.log(response.data)
         setExposants(response.data);

      } catch (e) {
         console.log(e)
      }
   };

   return [updateAccount, getUsersByExposant];
};