var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { DataGrid, GridActionsCellItem, GridToolbar, } from "@mui/x-data-grid";
import AccountIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import trackerApi from "../../api/tracker";
import useExposant from "../../hooks/useExposant";
var AllEntreprises = function (props) {
    var _a = useState([]), rows = _a[0], setRows = _a[1];
    var _b = useExposant(), createExposant = _b[0], getExposant = _b[1], updateExposant = _b[2], deleteExposant = _b[3];
    var handleAccountClick = function (id) { return function () {
        window.location.href = "/admin/entreprise/".concat(id, "/compte");
    }; };
    var handleEditClick = function (id) { return function () {
        window.location.href = "/admin/entreprise/".concat(id, "/details");
    }; };
    var handleDeleteClick = function (id) { return function () {
        var isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette entreprise ? Cette action est irréversible.");
        var onSuccess = function () {
            setRows(rows.filter(function (row) { return row.id !== id; }));
            toastr.success("Entreprise suprimée", "L'entreprise ".concat(id, " a bien \u00E9t\u00E9 supprim\u00E9e"), { position: "top-right" });
        };
        var onFail = function () {
            toastr.error("Échec", "Une erreur est survenue", {
                position: "top-right",
            });
        };
        if (isConfirmed) {
            deleteExposant(id, onSuccess, onFail);
        }
    }; };
    var onSuccess = useCallback(function (exposant) {
        toastr.success("Détails mis à jour", "Les détails de l'entreprise ont bien été mis à jour", { position: "top-right" });
    }, []);
    var onFail = useCallback(function () {
        toastr.error("Échec", "La modification a échoué", {
            position: "top-right",
        });
    }, []);
    var columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            maxWidth: 60,
        },
        {
            field: "logo",
            headerName: "Logo",
            flex: 1,
            maxWidth: 100,
            renderCell: function (params) {
                return (React.createElement("a", { href: "/admin/entreprise/" + params.row.id + "/details", target: "_blank", rel: "noopener noreferrer" },
                    React.createElement("img", { style: { width: 80, height: 50, objectFit: "contain" }, src: params.row.logo, alt: "Logo" })));
            },
        },
        {
            field: "titre",
            headerName: "Nom de l'entreprise",
            flex: 1,
            minWidth: 150,
            renderCell: function (params) {
                var companyURL = "/admin/entreprise/" + params.row.id + "/details";
                return (React.createElement("a", { href: companyURL, target: "_blank", rel: "noopener noreferrer" }, params.value));
            },
        },
        {
            field: "stand",
            headerName: "Stand",
            flex: 1,
            maxWidth: 100,
            editable: true,
        },
        {
            field: "participation_forum_virtuel",
            headerName: "Virtuel",
            type: "boolean",
            flex: 1,
            minWidth: 100,
            valueGetter: function (params) {
                return params.value === "true" ? true : false;
            },
        },
        {
            field: "entretien",
            headerName: "JD 💼",
            type: "boolean",
            flex: 1,
            maxWidth: 80,
        },
        {
            field: "entretien_count",
            headerName: "# 💼",
            flex: 1,
            maxWidth: 80,
        },
        {
            field: "entretien_preferences",
            headerName: "JD ids",
            type: "string",
            flex: 1,
            minWidth: 200,
            editable: true,
        },
        {
            field: "entretien_preferences_ok",
            headerName: "JD ✅",
            flex: 1,
            maxWidth: 80,
            type: "boolean",
            valueGetter: function (params) {
                var _a;
                return ((_a = params.row.entretien_preferences) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                    params.row.entretien_preferences !== "null"
                    ? true
                    : false;
            },
        },
        {
            field: "dejeuner",
            headerName: "BL 🍴",
            type: "boolean",
            flex: 1,
            maxWidth: 80,
        },
        {
            field: "dejeuner_count",
            headerName: "# 🍴",
            type: "string",
            flex: 1,
            maxWidth: 80,
        },
        {
            field: "dejeuner_preferences",
            headerName: "BL ids",
            type: "string",
            flex: 1,
            minWidth: 600,
            editable: true,
        },
        {
            field: "dejeuner_preferences_ok",
            headerName: "BL ✅",
            type: "boolean",
            flex: 1,
            maxWidth: 100,
            valueGetter: function (params) {
                var _a;
                return ((_a = params.row.dejeuner_preferences) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                    params.row.dejeuner_preferences !== "null"
                    ? true
                    : false;
            },
        },
        {
            field: "message",
            headerName: "Message aux candidats",
            type: "string",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "createdAt",
            headerName: "Créée le",
            flex: 1,
            maxWidth: 100,
            renderCell: function (params) {
                var formattedDateFr = new Date(params.value).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                return React.createElement("span", null, formattedDateFr);
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            flex: 1,
            cellClassName: "actions",
            getActions: function (_a) {
                var id = _a.id;
                return [
                    React.createElement(GridActionsCellItem, { icon: React.createElement(AccountIcon, null), label: "Compte", className: "textPrimary", onClick: handleAccountClick(id), color: "inherit" }),
                    React.createElement(GridActionsCellItem, { icon: React.createElement(EditIcon, null), label: "Edit", className: "textPrimary", onClick: handleEditClick(id), color: "inherit" }),
                    React.createElement(GridActionsCellItem, { icon: React.createElement(DeleteIcon, null), label: "Delete", onClick: handleDeleteClick(id), color: "inherit" }),
                ];
            },
        },
    ];
    var getExposants = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, trackerApi.get("/api/allExposants")];
                case 1:
                    response = _a.sent();
                    setRows(response.data.exposants);
                    console.log(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log("welcome to catch block!");
                    console.log(e_1);
                    toastr.error("Échec", "Un problème est survenu", {
                        position: "top-right",
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getExposants();
    }, []);
    if (!rows) {
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col" },
                React.createElement("div", { style: { height: 40 } }),
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("h3", null, "Entreprises"),
                        React.createElement("p", null, "En cours de chargement..."))))));
    }
    return (React.createElement(DataGrid, { disableRowSelectionOnClick: true, slots: {
            toolbar: GridToolbar,
        }, rows: rows, columns: columns, style: { flex: 1, minHeight: 400 }, initialState: {
            pagination: {
                paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
                sortModel: [{ field: "titre", sort: "asc" }],
            },
            columns: {
                columnVisibilityModel: {
                    entretien_preferences: false,
                    dejeuner_preferences: false,
                    dejeuner_count: false,
                    entretien_count: false,
                    message: false,
                },
            },
        }, density: "compact", processRowUpdate: function (newRow) {
            var _a, _b;
            console.log(newRow);
            var formattedExposant = {
                id: newRow.id,
                stand: Number.parseInt(newRow.stand),
                entretien_preferences: ((_a = newRow.entretien_preferences) === null || _a === void 0 ? void 0 : _a.length) > 0
                    ? newRow.entretien_preferences
                    : null,
                dejeuner_preferences: ((_b = newRow.dejeuner_preferences) === null || _b === void 0 ? void 0 : _b.length) > 0
                    ? newRow.dejeuner_preferences
                    : null,
            };
            updateExposant(formattedExposant, onSuccess, onFail);
        }, pageSizeOptions: [50, 100], checkboxSelection: true }));
};
export default AllEntreprises;
