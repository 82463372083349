var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import * as classNames from "classnames";
import Select from "react-select";
import { intersection } from "lodash";
var values = require("object.values");
import { createFilter } from "react-search-input";
import { Exposant } from "./Exposant";
import { withApi } from "../../decorators/api";
import { getProp } from "../../redux/store";
import { Details } from "./Details";
import { pays, profils, secteurs, tailles, typeContrats, } from "../../constants";
export function makeOptions(l) {
    try {
        return l.map(function (v) { return ({ value: v, label: v }); });
    }
    catch (e) {
        return [];
    }
}
export function extractValues(l) {
    return l.map(function (v) { return v.value; });
}
var Exposants = /** @class */ (function (_super) {
    __extends(Exposants, _super);
    function Exposants(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            searchTerm: "",
            showSearch: false,
            domaineActivite: [],
            participationVirtuel: { value: "Oui", label: "Oui" },
            profils: [],
            nombreEmployes: [],
            pays: [],
            typeContrat: [],
        };
        return _this;
    }
    Exposants.prototype.componentWillMount = function () {
        this.props.loadEntreprises();
    };
    Exposants.prototype.render = function () {
        var self = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("section", { className: "exposants mt-5" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row justify-content-center mb-5" },
                        React.createElement("div", { className: "col-lg-6 text-center" },
                            React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Exposants"),
                            React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "D\u00E9couvrez les entreprises pr\u00E9sentes le jour J"),
                            React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                    React.createElement("div", { className: "row justify-content-center mb-3" },
                        React.createElement("div", { className: "col-lg-9 col-md-7 col-sm-12 col-xs-12" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("input", { type: "text", value: this.state.searchTerm, className: "font-alt form-control required", placeholder: "Rechercher par le nom", onChange: function (e) {
                                        var value = e.target.value;
                                        self.setState({
                                            searchTerm: value,
                                        });
                                    } }))),
                        React.createElement("div", { className: "col-lg-3 col-md-5 col-sm-12 col-xs-12 text-center" },
                            React.createElement("a", { className: "btn btn-small btn-blue", style: { color: "white" }, onClick: function () {
                                    self.setState(function (state) { return ({
                                        showSearch: !state.showSearch,
                                    }); });
                                } },
                                React.createElement("i", { className: classNames("fa fa-fw", {
                                        "fa-chevron-down": !this.state.showSearch,
                                        "fa-chevron-up": this.state.showSearch,
                                    }) }),
                                "\u00A0 Recherche avanc\u00E9e"))),
                    this.state.showSearch ? (React.createElement("div", { className: "row justify-content-center mb-5" },
                        React.createElement("div", { className: "col-sm-12 col-md-6" },
                            React.createElement("div", { className: "form-group", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "participation" }, "Participation au forum virtuel"),
                                React.createElement(Select, { value: this.state.participationVirtuel, onChange: function (e) {
                                        return self.setState({ participationVirtuel: e });
                                    }, options: makeOptions(["Oui", "Non"]) }))),
                        React.createElement("div", { className: "col-sm-12 col-md-6" },
                            React.createElement("div", { className: "form-group", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "secteur" }, "Domaine d'activit\u00E9"),
                                React.createElement(Select, { value: makeOptions(this.state.domaineActivite), isMulti: true, onChange: function (e) {
                                        return self.setState({ domaineActivite: extractValues(e) });
                                    }, options: makeOptions(secteurs) }))),
                        React.createElement("div", { className: "col-sm-12 col-md-6" },
                            React.createElement("div", { className: "form-group", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "taille" }, "Nombre d'employ\u00E9s"),
                                React.createElement(Select, { value: makeOptions(this.state.nombreEmployes), isMulti: true, onChange: function (e) {
                                        return self.setState({ nombreEmployes: extractValues(e) });
                                    }, options: makeOptions(tailles) }))),
                        React.createElement("div", { className: "col-sm-12 col-md-6" },
                            React.createElement("div", { className: "form-group", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "pays" }, "Pays"),
                                React.createElement(Select, { value: makeOptions(this.state.pays), isMulti: true, onChange: function (e) {
                                        return self.setState({ pays: extractValues(e) });
                                    }, options: makeOptions(pays) }))),
                        React.createElement("div", { className: "col-sm-12 col-md-6" },
                            React.createElement("div", { className: "form-group", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "conrats" }, "Type de contrat"),
                                React.createElement(Select, { value: makeOptions(this.state.typeContrat), isMulti: true, onChange: function (e) {
                                        return self.setState({ typeContrat: extractValues(e) });
                                    }, options: makeOptions(typeContrats) }))),
                        React.createElement("div", { className: "col-sm-12 col-md-6" },
                            React.createElement("div", { className: "form-group", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "profils" }, "Profils recherch\u00E9s"),
                                React.createElement(Select, { value: makeOptions(this.state.profils), isMulti: true, onChange: function (e) {
                                        return self.setState({ profils: extractValues(e) });
                                    }, options: makeOptions(profils) }))))) : null,
                    React.createElement("div", { className: "row" }, this.renderExposants())))));
    };
    Exposants.prototype.renderExposants = function () {
        var _this = this;
        function parseArray(value) {
            if (typeof value === "string") {
                try {
                    value = JSON.parse(value);
                    if (Array.isArray(value)) {
                        return value;
                    }
                }
                catch (e) { }
            }
            return [];
        }
        var self = this;
        var participationForumVirtuel = this.state.participationVirtuel &&
            this.state.participationVirtuel.value == "Oui";
        var allExposants = values(this.props.exposants || {})
            .map(function (exposant) { return (__assign(__assign({}, exposant), { secteur: parseArray(exposant.secteur), taille: parseArray(exposant.taille), profils: parseArray(exposant.profils), type_contrat: parseArray(exposant.type_contrat) })); })
            .filter(function (exposant) {
            if (!self.state.showSearch) {
                return true;
            }
            if (participationForumVirtuel &&
                exposant.participation_forum_virtuel != "true") {
                return false;
            }
            if (self.state.domaineActivite.length &&
                intersection(exposant.secteur, self.state.domaineActivite) == 0) {
                return false;
            }
            if (self.state.nombreEmployes.length &&
                intersection(exposant.taille, self.state.nombreEmployes) == 0) {
                return false;
            }
            if (self.state.pays.length &&
                intersection(exposant.pays, self.state.pays) == 0) {
                return false;
            }
            if (self.state.typeContrat.length &&
                intersection(exposant.type_contrat, self.state.typeContrat) == 0) {
                return false;
            }
            if (self.state.profils.length &&
                intersection(exposant.profils, self.state.profils) == 0) {
                return false;
            }
            return true;
        });
        var filteredExposants = allExposants
            .filter(createFilter(this.state.searchTerm, ["titre"]))
            .sort(function (a, b) { return a.titre.localeCompare(b.titre); });
        if (filteredExposants.length == 0) {
            return (React.createElement("div", { className: "col-12 text-center" },
                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Aucun exposant ne correspond \u00E0 votre recherche ...")));
        }
        else {
            var components = [];
            var exposantId = this.props.match.params.id;
            var exposant = getProp(this.props, "exposants." + exposantId);
            for (var i = 0; i < filteredExposants.length; i++) {
                var listExposant = filteredExposants[i];
                var active = listExposant.id == exposantId;
                components.push(React.createElement(Exposant, { exposant: listExposant, key: i, active: active }));
                if (active) {
                    components.push(React.createElement("div", { className: "mb-4 py-3 bg-white border border-gray-300 box-shadow  col-12", key: filteredExposants.length + 1 },
                        React.createElement("span", { style: {
                                position: "absolute",
                                right: "20px",
                                top: "10px",
                                cursor: "pointer",
                            }, onClick: function () {
                                _this.props.history.push("/exposants");
                            } },
                            React.createElement("i", { className: "fa fa-times" })),
                        React.createElement(Details, { exposant: exposant })));
                }
            }
            return components;
        }
    };
    Exposants = __decorate([
        withApi(function (state) { return ({
            exposants: state.network.exposants,
        }); }),
        __metadata("design:paramtypes", [Object])
    ], Exposants);
    return Exposants;
}(React.Component));
export { Exposants };
