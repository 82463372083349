var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { toastr } from "react-redux-toastr";
import { Form, Input } from "informed";
import { Network } from "../../redux/actions";
import { connect } from "../../redux/store";
var New = /** @class */ (function (_super) {
    __extends(New, _super);
    function New() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    New.prototype.render = function () {
        var self = this;
        return (React.createElement("section", { className: "clearfix bg-dark listingSection" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement(Form, { className: "listing__form", onSubmit: function (data) {
                                var values = data.values;
                                if (values.email == null || values.password == null) {
                                    toastr.error("Erreur", "Vous devez indiquer les d\u00E9tails du compte associ\u00E9 (email et mot de passe).", { position: "top-right" });
                                }
                                else {
                                    self.props.dispatch(Network.request("/api/exposants/create", values, {
                                        action: function (json) {
                                            toastr.success("Entreprise créée", "L'entreprise ".concat(values.titre, " a bien \u00E9t\u00E9 cr\u00E9\u00E9e"), { position: "top-right" });
                                            self.props.history.push("/admin/entreprises");
                                        },
                                    }, "POST"));
                                }
                            } },
                            React.createElement("div", { className: "dashboardBoxBg mb30" },
                                React.createElement("div", { className: "about paraMargin" },
                                    React.createElement("h3", null, "Nouvelle entreprise"),
                                    React.createElement("div", { className: "row" },
                                        React.createElement("div", { className: "form-group col-12" },
                                            React.createElement("label", { htmlFor: "titre" }, "Titre de l'entreprise"),
                                            React.createElement(Input, { field: "titre", name: "titre", type: "text", className: "form-control", id: "titre", placeholder: "Titre de l'entreprise" })),
                                        React.createElement("div", { className: "form-group col-sm-6 col-xs-12" },
                                            React.createElement("label", { htmlFor: "email" }, "Email"),
                                            React.createElement(Input, { field: "email", name: "email", type: "text", className: "form-control", id: "email", placeholder: "Nom d'utilisateur" })),
                                        React.createElement("div", { className: "form-group col-sm-6 col-sm-pull-6 col-xs-12" },
                                            React.createElement("label", { htmlFor: "password" }, "Mot de passe"),
                                            React.createElement(Input, { type: "password", name: "password", field: "password", className: "form-control", id: "password", placeholder: "Mot de passe" }))))),
                            React.createElement("div", { className: "form-footer text-center" },
                                React.createElement("button", { type: "submit", className: "btn-submit", style: { cursor: "poiner" } }, "Cr\u00E9er"))))))));
    };
    New = __decorate([
        connect()
    ], New);
    return New;
}(React.Component));
export { New };
