var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { Link } from "react-router-dom";
import * as classNames from "classnames";
import { getImageUrl } from "../../utils";
var Exposant = /** @class */ (function (_super) {
    __extends(Exposant, _super);
    function Exposant() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Exposant.prototype.render = function () {
        var active = this.props.active;
        return (React.createElement("div", { className: "col-sm-6 col-md-4 col-lg-3 col-xs-12" },
            React.createElement(Link, { to: "/exposants/" + (active ? "" : this.props.exposant.id), className: classNames("exposant", { active: active }) },
                React.createElement("span", null,
                    this.props.exposant.logo != null &&
                        this.props.exposant.logo != "" ? (React.createElement("div", { style: { width: "100%" }, className: "text-center" },
                        React.createElement("img", { style: {
                                width: "100px",
                                maxHeight: "100px",
                                marginBottom: "20px",
                            }, src: getImageUrl(this.props.exposant.logo) }))) : null,
                    this.props.exposant.titre))));
    };
    Exposant.defaultProps = {
        exposant: {},
        active: false,
    };
    return Exposant;
}(React.Component));
export { Exposant };
