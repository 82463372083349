import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from "./App";
// Vendor libs
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../../node_modules/font-awesome/css/font-awesome.css');
// Website theme
require('../assets/css/theme.scss');
require('../assets/css/styles.css');
var app = document.getElementById('root');
ReactDOM.render(React.createElement(App, null), app);
