var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import useEtudiant from "../../hooks/useEtudiant";
import useDejeuner from "../../hooks/useDejeuner";
import useEntretien from "../../hooks/useEntretien";
import trackerApi from "../../api/tracker";
import { DataGrid, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, } from "@mui/x-data-grid";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Button } from "@mui/material";
var AllEtudiants = function (props) {
    var _a = useState([]), rows = _a[0], setRows = _a[1];
    var _b = useEtudiant(), createEtudiant = _b[0], getEtudiant = _b[1], updateEtudiant = _b[2], deleteEtudiant = _b[3];
    var _c = useState([]), exposantsBL = _c[0], setExposantsBL = _c[1];
    var _d = useState([]), exposantsJD = _d[0], setExposantsJD = _d[1];
    var _e = useState({}), columnVisibilityModel = _e[0], setColumnVisibilityModel = _e[1];
    var _f = useState(), selection = _f[0], setSelection = _f[1];
    var onSuccessEdit = function () {
        toastr.success("Étudiant modifié", "La fiche de l'\u00E9tudiant a bien \u00E9t\u00E9 modifi\u00E9e", {
            position: "top-right",
        });
    };
    var onSuccessDelete = function () {
        toastr.success("Étudiant suprimé", "L'\u00E9tudiant a bien \u00E9t\u00E9 supprim\u00E9", {
            position: "top-right",
        });
    };
    var onFail = function () {
        toastr.error("Échec", "Une erreur est survenue", {
            position: "top-right",
        });
    };
    var handleDeleteClick = function (id) { return function () {
        var isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette entreprise ? Cette action est irréversible.");
        if (isConfirmed) {
            deleteEtudiant(id, onSuccessDelete, onFail);
            setRows(rows.filter(function (row) { return row.id !== id; }));
        }
    }; };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    var columns = __spreadArray(__spreadArray(__spreadArray([
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            maxWidth: 50,
        },
        {
            field: "prenom",
            headerName: "Prénom",
            flex: 1,
            minWidth: 150,
            valueGetter: function (params) {
                return capitalizeFirstLetter(params.value);
            },
        },
        {
            field: "nom",
            headerName: "Nom",
            flex: 1,
            minWidth: 150,
            valueGetter: function (params) {
                return params.value.toUpperCase();
            },
        },
        {
            field: "lien_cv",
            headerName: "Nom et CV",
            flex: 1,
            minWidth: 150,
            renderCell: function (params) {
                var cvUrl = params.value;
                return (React.createElement("a", { href: cvUrl, target: "_blank", rel: "noopener noreferrer" },
                    params.row.prenom,
                    " ",
                    params.row.nom));
            },
        },
        {
            field: "entretien_preferences",
            headerName: "Pref 💼",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "dejeuner_preferences",
            headerName: "Pref 🍴",
            flex: 1,
            minWidth: 150,
        }
    ], exposantsBL.map(function (e) {
        return {
            field: "".concat(e.titre, " \uD83C\uDF74"),
            headerName: "".concat(e.titre, " \uD83C\uDF74"),
            flex: 1,
            type: "boolean",
            minWidth: 100,
            valueGetter: function (params) {
                if (!params.row.dejeuner_preferences)
                    return false;
                var preferences = JSON.parse(params.row.dejeuner_preferences);
                return preferences.includes(e.id);
            },
        };
    }), true), exposantsJD.map(function (e) {
        return {
            field: "".concat(e.titre, " \uD83D\uDCBC"),
            headerName: "".concat(e.titre, " \uD83D\uDCBC"),
            flex: 1,
            type: "boolean",
            minWidth: 100,
            valueGetter: function (params) {
                if (!params.row.entretien_preferences)
                    return false;
                var preferences = JSON.parse(params.row.entretien_preferences);
                return preferences.includes(e.id);
            },
        };
    }), true), [
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 150,
            editable: true,
        },
        {
            field: "phoneNumber",
            headerName: "Numéro",
            flex: 1,
            minWidth: 120,
        },
        { field: "formation", headerName: "Formation", flex: 1, minWidth: 150 },
        { field: "motivation", headerName: "Motivation", flex: 1, minWidth: 150 },
        {
            field: "entretien",
            headerName: "JD 💼",
            type: "boolean",
            flex: 1,
            maxWidth: 60,
        },
        {
            field: "dejeuner",
            headerName: "BL 🍴",
            type: "boolean",
            flex: 1,
            maxWidth: 60,
        },
        {
            field: "createdAt",
            headerName: "Créé le",
            flex: 1,
            maxWidth: 120,
            renderCell: function (params) {
                var formattedDateFr = new Date(params.value).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                return React.createElement("span", { title: params.value }, formattedDateFr);
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            flex: 1,
            maxWidth: 80,
            cellClassName: "actions",
            getActions: function (_a) {
                var id = _a.id;
                return [
                    React.createElement(GridActionsCellItem, { icon: React.createElement(DeleteIcon, null), label: "Delete", onClick: handleDeleteClick(id), color: "inherit" }),
                ];
            },
        },
    ], false);
    var getEtudiants = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, trackerApi.get("/api/allEtudiants")];
                case 1:
                    response = _a.sent();
                    setRows(response.data);
                    console.log(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log("welcome to catch block!");
                    console.log(e_1);
                    toastr.error("Échec", "Un problème est survenu", {
                        position: "top-right",
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _g = useDejeuner(), getExposantsBL = _g[0], applyDejeuners = _g[1], updateDejeuner = _g[2];
    var _h = useEntretien(), getExposantsJD = _h[0], applyEntretiens = _h[1], updateEntretien = _h[2];
    var model = {};
    var handleChangeSelection = function (newSelectionModel) {
        setSelection(newSelectionModel);
    };
    function formatStudents(selection) {
        var students = rows.filter(function (e) { return selection.includes(e.id); });
        var formattedEmails = students.map(function (item) {
            return {
                etudiantFirstName: item.prenom,
                etudiantEmail: item.email,
            };
        });
        return formattedEmails;
    }
    function CustomToolbar() {
        return (React.createElement(GridToolbarContainer, null,
            React.createElement(GridToolbarColumnsButton, null),
            React.createElement(GridToolbarFilterButton, null),
            React.createElement(GridToolbarDensitySelector, null),
            React.createElement(GridToolbarExport, null),
            React.createElement(Button, { onClick: function () { return console.log(formatStudents(selection)); } },
                React.createElement(EmailIcon, { style: { fontSize: 16, marginRight: 8 } }),
                React.createElement("span", { style: { fontSize: "0.8125rem" } }, "COPY JSON"))));
    }
    useEffect(function () {
        getEtudiants();
        getExposantsBL(setExposantsBL);
        getExposantsJD(setExposantsJD);
    }, []);
    useEffect(function () {
        if ((exposantsBL === null || exposantsBL === void 0 ? void 0 : exposantsBL.length) === 0 || (exposantsJD === null || exposantsJD === void 0 ? void 0 : exposantsJD.length) === 0)
            return;
        __spreadArray(__spreadArray([], exposantsBL, true), exposantsJD, true).forEach(function (exposant) {
            model["".concat(exposant.titre, " ").concat(exposant.type === "job_dating" ? "💼" : "🍴")] = false;
        });
        model["nom"] = false;
        model["prenom"] = false;
        model["entretien_preferences"] = false;
        model["dejeuner_preferences"] = false;
        setColumnVisibilityModel(model);
    }, [exposantsBL, exposantsJD]);
    if (!rows || Object.values(columnVisibilityModel).length === 0) {
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col" },
                React.createElement("div", { style: { height: 40 } }),
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("h3", null, "\u00C9tudiants"),
                        React.createElement("p", null, "En cours de chargement..."))))));
    }
    return (React.createElement(DataGrid, { disableRowSelectionOnClick: true, slots: {
            toolbar: CustomToolbar,
        }, columnVisibilityModel: columnVisibilityModel, onColumnVisibilityModelChange: function (newModel) {
            return setColumnVisibilityModel(newModel);
        }, rows: rows, columns: columns, initialState: {
            pagination: {
                paginationModel: { page: 0, pageSize: 50 },
            },
            sorting: {
                sortModel: [{ field: "createdAt", sort: "desc" }],
            },
        }, processRowUpdate: function (newRow) {
            if (newRow === null || newRow === void 0 ? void 0 : newRow.email) {
                var formattedEtudiant = {
                    id: newRow.id,
                    email: newRow.email,
                };
                updateEtudiant(formattedEtudiant, onSuccessEdit, onFail);
            }
        }, rowSelectionModel: selection, onRowSelectionModelChange: handleChangeSelection, density: "compact", pageSizeOptions: [50, 100], checkboxSelection: true }));
};
export default AllEtudiants;
