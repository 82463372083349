var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import ReduxToastr from "react-redux-toastr";
import { configureStore } from "./redux/store";
import { Back } from "./navigation/Back";
import { Front } from "./navigation/Front";
var store = configureStore();
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function () {
        return (React.createElement(Provider, { store: store },
            React.createElement(BrowserRouter, null,
                React.createElement(React.Fragment, null,
                    React.createElement(ReduxToastr, { timeOut: 4000, newestOnTop: false, preventDuplicates: true, position: "top-left", transitionIn: "fadeIn", transitionOut: "fadeOut", progressBar: true, closeOnToastrClick: true }),
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "/espace-entreprise", component: Back }),
                        React.createElement(Route, { path: "/espace-etudiant", component: Back }),
                        React.createElement(Route, { path: "/admin", component: Back }),
                        React.createElement(Route, { path: "/", component: Front }))))));
    };
    return App;
}(React.Component));
export { App };
