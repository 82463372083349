var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import * as classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "../redux/store";
import { withApi } from "../decorators/api";
var logo = require("../../assets/img/Logo_A_M.png");
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            showMobile: false,
            showPreparationMenu: false,
            showPresentationMenu: false,
            sectionsPresentation: [],
            sectionsPreparation: [],
        };
        return _this;
    }
    Header.prototype.componentWillMount = function () {
        var self = this;
        this.props.loadContenu().then(function (contenu) {
            try {
                self.setState({
                    sectionsPresentation: JSON.parse(contenu["presentation"].contenu),
                    sectionsPreparation: JSON.parse(contenu["preparation"].contenu),
                });
            }
            catch (e) { }
        });
    };
    Header.prototype.render = function () {
        var self = this;
        function closeMenu() {
            self.setState({
                showMobile: false,
                showPreparationMenu: false,
                showPresentationMenu: false,
            });
        }
        return (React.createElement("header", { className: "header-light shrink fixed-top" },
            React.createElement("div", { className: "container-xxl" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-xl-2 navbar-header" },
                        React.createElement(Link, { className: "navbar-brand page-scroll", to: "/" },
                            React.createElement("img", { src: logo, alt: "Arts&M\u00E9tiers" })),
                        React.createElement("button", { className: "navbar-toggler", onClick: function () {
                                self.setState(function (state) { return ({ showMobile: !state.showMobile }); });
                            }, type: "button", "data-toggle": "collapse", "data-target": "#navbar-header", "aria-controls": "navbar-header", "aria-expanded": "false", "aria-label": "Toggle navigation" },
                            React.createElement("span", { className: "navbar-toggler-icon" }))),
                    React.createElement("div", { className: "col-xl-7 navbar-wrapper justify-content-center text-center" },
                        React.createElement("div", { id: "navigation", className: "navbar navbar-expand-xl" },
                            React.createElement("div", { className: classNames("collapse navbar-collapse", {
                                    show: this.state.showMobile,
                                }), id: "navbar-header" },
                                React.createElement("ul", { className: "navbar-nav" },
                                    React.createElement("li", { className: "nav-item" },
                                        React.createElement(Link, { className: "nav-link page-scroll", to: "/", onClick: function () { return closeMenu(); } }, "Accueil")),
                                    React.createElement("li", { className: classNames("nav-item dropdown", {
                                            show: this.state.showPresentationMenu,
                                        }) },
                                        React.createElement(Link, { className: "nav-link page-scroll", to: "/presentation", onClick: function () { return closeMenu(); } }, "Pr\u00E9sentation")),
                                    React.createElement("li", { className: classNames("nav-item dropdown", {
                                            show: this.state.showPreparationMenu,
                                        }) },
                                        React.createElement(Link, { className: "nav-link page-scroll", to: "/bien-se-preparer", onClick: function () { return closeMenu(); } }, "Pr\u00E9paration")),
                                    React.createElement("li", { className: "nav-item" },
                                        React.createElement(Link, { className: "nav-link page-scroll", to: "/planning", onClick: function () { return closeMenu(); } }, "Planning")),
                                    React.createElement("li", { className: "nav-item" },
                                        React.createElement(Link, { className: "nav-link page-scroll", to: "/horaires", onClick: function () { return closeMenu(); } }, "Transports")),
                                    React.createElement("li", { className: "nav-item" },
                                        React.createElement(Link, { className: "nav-link page-scroll", to: "/restauration", onClick: function () { return closeMenu(); } }, "Restauration")),
                                    React.createElement("li", { className: "nav-item" },
                                        React.createElement(Link, { className: "nav-link page-scroll", to: "/exposants", onClick: function () { return closeMenu(); } }, "Exposants")),
                                    this.state.showMobile === true ? (React.createElement(React.Fragment, null, this.props.userId == null ? (React.createElement(React.Fragment, null,
                                        React.createElement("li", { className: classNames("collapse navbar-collapse", {
                                                show: this.state.showMobile,
                                            }), style: {
                                                paddingLeft: 15,
                                                marginBottom: 15,
                                                marginTop: 5,
                                            } },
                                            React.createElement(Link, { to: "/login-etudiant", className: "btn btn-extra-small btn-blue page-scroll mb-6", onClick: function () { return closeMenu(); } }, "\u00C9tudiants")),
                                        React.createElement("li", { className: classNames("collapse navbar-collapse", {
                                                show: this.state.showMobile,
                                            }), style: { paddingLeft: 15, marginBottom: 15 } },
                                            React.createElement(Link, { to: "/login-entreprise", className: "btn btn-extra-small btn-orange page-scroll", onClick: function () { return closeMenu(); } }, "Entreprises")))) : (React.createElement("li", { className: classNames("collapse navbar-collapse", {
                                            show: this.state.showMobile,
                                        }), style: { paddingLeft: 15, marginBottom: 15 } },
                                        React.createElement(Link, { to: this.props.userType === "ADMIN"
                                                ? "/admin/entreprises"
                                                : this.props.userType === "ETUDIANT"
                                                    ? "/espace-etudiant/compte"
                                                    : this.props.userType === "EXPOSANT"
                                                        ? "/espace-entreprise/details"
                                                        : "/", className: "btn btn-extra-small btn-blue page-scroll", onClick: function () { return closeMenu(); } }, this.props.userType === "ADMIN"
                                            ? "Espace administrateur"
                                            : this.props.userType === "ETUDIANT"
                                                ? "Espace étudiant"
                                                : this.props.userType === "EXPOSANT"
                                                    ? "Espace entreprise"
                                                    : "/"))))) : null)))),
                    React.createElement("div", { className: "col-xl-3 d-none d-xl-block pr-xl-0" }, this.props.userId == null ? (React.createElement("div", { className: "align-items-center d-flex h-100 justify-content-end" },
                        React.createElement(Link, { to: "/login-etudiant", className: "btn btn-extra-small btn-blue page-scroll" }, "\u00C9tudiants"),
                        React.createElement(Link, { to: "/login-entreprise", className: "btn btn-extra-small btn-orange page-scroll" }, "Entreprises"))) : (React.createElement("div", { className: "align-items-center d-flex h-100 justify-content-end" },
                        React.createElement(Link, { to: this.props.userType === "ADMIN"
                                ? "/admin/entreprises"
                                : this.props.userType === "ETUDIANT"
                                    ? "/espace-etudiant/compte"
                                    : this.props.userType === "EXPOSANT"
                                        ? "/espace-entreprise/details"
                                        : "/", className: "btn btn-extra-small btn-blue page-scroll" }, this.props.userType === "ADMIN"
                            ? "Espace administrateur"
                            : this.props.userType === "ETUDIANT"
                                ? "Espace étudiant"
                                : this.props.userType === "EXPOSANT"
                                    ? "Espace entreprise"
                                    : null))))))));
    };
    Header = __decorate([
        connect(function (state) { return ({
            userId: state.session.userId,
            userType: state.session.userType,
        }); }),
        withApi(),
        __metadata("design:paramtypes", [Object])
    ], Header);
    return Header;
}(React.Component));
export { Header };
