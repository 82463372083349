import trackerApi from "../api/tracker";
import { dispatch } from "../redux/store";
import { Network, Session as SessionActions } from "../redux/actions";
import { toastr } from "react-redux-toastr";

export default () => {

   const createEtudiant = async (formattedEtudiant, callback, fallback) => {
      try {
         const formData = new FormData();
         ["file", "prenom", "nom", "email", "password", "formation", "phoneNumber", "motivation"].map((value) => {
            if (formattedEtudiant[value]) formData.append(value, formattedEtudiant[value]);
         });

         const response = await trackerApi.post(`/api/etudiants/create`, formData)

         let { userId, email, admin, exposant, etudiant, userType, token } = response.data;

         localStorage.setItem("token", token);

         toastr.success("Inscription réussie", "Bienvenue " + email, {
            position: "top-right",
         });

         dispatch(
            SessionActions.setUser(
               userId,
               email,
               admin,
               exposant,
               etudiant,
               userType
            )
         );

         callback();
      } catch (e) {
         fallback();
         console.log(e)
      }
   }

   const getEtudiant = async (id, setEtudiant) => {
      try {
         const response = await trackerApi.get(`/api/etudiant/${id}`);
         setEtudiant(response.data)
      } catch (e) {
         toastr.error("Échec", "Un problème est survenu", {
            position: "top-right",
         });
         console.log(e)
      }
   }

   const updateEtudiant = async (etudiant, callback, fallback) => {
      try {
         const formData = new FormData();
         if (etudiant.file) {
            console.log("le cv a changé");
         }
         ["file", "prenom", "nom", "email", "formation", "phoneNumber", "motivation"].map((value) => {
            if (etudiant[value]) formData.append(value, etudiant[value]);
         });

         const response = await trackerApi.post(`/api/etudiants/${etudiant.file ? "update-cv" : "update"}/${etudiant.id}`, etudiant.file ? formData : etudiant)

         callback(response.data);
      } catch (e) {
         fallback();
         console.log(e)
      }
   }

   const deleteEtudiant = async (id, callback, fallback) => {
      try {
         await trackerApi.post(`/api/etudiants/delete/${id}`);
         callback();
      } catch (e) {
         fallback();
         console.log(e)
      }
   }

   return [createEtudiant, getEtudiant, updateEtudiant, deleteEtudiant];
};