import trackerApi from "../api/tracker";

export default () => {

   const getExposantsDisponibles = async (setExposantsDisponibles) => {
      try {
         const response = await trackerApi.get(`/api/exposants/job-dating`)
         console.log(response.data)
         setExposantsDisponibles(response.data.map(e => {
            return { ...e, type: "job_dating" }
         }));
      } catch (e) {
         console.log(e)
      }
   };

   const applyEntretiens = async (id, preferences, callback, fallback) => {
      try {
         const response = await trackerApi.post(`/api/etudiants/apply-entretiens/${id}`, preferences)

         callback(response.data);
      } catch (e) {
         fallback();
         console.log(e)
      }
   };

   const updateEntretien = async (entretien, callback) => {
      try {
         await trackerApi.post(`/api/entretiens/update/${id}`, entretien)

         callback();
      } catch (e) {
         console.log(e)
      }
   };

   const deleteEntretien = async (id, callback, fallback) => {
      try {
         await trackerApi.delete(`/api/entretiens/delete`, { id })

         callback();
      } catch (e) {
         console.log(e);
         fallback();
      }
   };

   return [getExposantsDisponibles, applyEntretiens, updateEntretien, deleteEntretien];
};