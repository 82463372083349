var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var contact1Image = require('../../../assets/images/placeholders/contact-1.jpg');
var contact2Image = require('../../../assets/images/placeholders/contact-2.jpg');
var contact3Image = require('../../../assets/images/placeholders/contact-3.jpg');
var Contact = /** @class */ (function (_super) {
    __extends(Contact, _super);
    function Contact() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Contact.prototype.render = function () {
        return (React.createElement("section", { id: "contact", className: "bg-white mt-5" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center mb-5" },
                    React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                        React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Nous contacter"),
                        React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-6 col-xl-5" },
                        React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Vous avez des questions concernant l'organisation du forum ?"),
                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Merci de remplir le formulaire ci-dessous"),
                        React.createElement("span", { className: "bg-blue d-block mt-4 sep-line-medium-thick-long" }),
                        React.createElement("form", { action: "/sendMail", method: "post", id: "form-contact", className: "mt-4" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("input", { type: "text", name: "name", className: "font-alt form-control required", id: "contact-name", placeholder: "Votre nom" })),
                            React.createElement("div", { className: "form-group" },
                                React.createElement("input", { type: "email", name: "email", className: "font-alt form-control required email", id: "contact-email", placeholder: "Votre adresse mail" })),
                            React.createElement("div", { className: "form-group" },
                                React.createElement("select", { name: "subject", className: "font-alt form-control required", id: "contact-subject" },
                                    React.createElement("option", { value: "" }, "- Choisissez un sujet -"),
                                    React.createElement("option", { value: "sujet1" }, "Sujet 1"),
                                    React.createElement("option", { value: "sujet2" }, "Sujet 2"),
                                    React.createElement("option", { value: "sujet3" }, "Sujet 3"),
                                    React.createElement("option", { value: "sujet4" }, "Sujet 4"))),
                            React.createElement("div", { className: "form-group" },
                                React.createElement("textarea", { name: "message", className: "font-alt form-control required", id: "contact-message", rows: 6, placeholder: "Votre message" })),
                            React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 text-extra-small text-uppercase" }, "* Champs obligatoires"),
                            React.createElement("button", { type: "submit", id: "btn-form-contact", className: "btn btn-small btn-lg-medium btn-blue mt-4" }, "Envoyer"))),
                    React.createElement("div", { className: "col-lg-6 offset-xl-1" },
                        React.createElement("div", { className: "align-items-center bg-white border border-gray-300 box-shadow d-flex justify-content-between mt-4 mt-lg-0 p-4 position-relative rounded" },
                            React.createElement("div", { className: "pr-md-4" },
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 text-small text-uppercase" }, "Contact 1"),
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-1 text-small text-uppercase" }, "Tel : +33 06 00 11 22 33"),
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-extra-small text-uppercase" }, "Lorem ipsum.")),
                            React.createElement("img", { src: contact1Image, alt: "", className: "img-fluid rounded d-none d-md-block" })),
                        React.createElement("div", { className: "align-items-center bg-white border border-gray-300 box-shadow d-flex justify-content-between mt-4 p-4 position-relative rounded" },
                            React.createElement("div", { className: "pr-md-4" },
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 text-small text-uppercase" }, "Contact 2"),
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-extra-small text-uppercase" }, "Lorem Ipsum.")),
                            React.createElement("img", { src: contact2Image, alt: "", className: "img-fluid rounded d-none d-md-block" })),
                        React.createElement("div", { className: "align-items-center bg-white border border-gray-300 box-shadow d-flex justify-content-between mt-4 p-4 position-relative rounded" },
                            React.createElement("div", { className: "pr-md-4" },
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 text-small text-uppercase" }, "Arts & M\u00E9tiers"),
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-extra-small text-uppercase" }, "Lorem ipsum")),
                            React.createElement("img", { src: contact3Image, alt: "", className: "img-fluid rounded d-none d-md-block" })))))));
    };
    return Contact;
}(React.Component));
export { Contact };
