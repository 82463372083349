import trackerApi from "../api/tracker";

export default () => {

   const getExposantsDisponibles = async (setExposantsDisponibles) => {
      try {
         const response = await trackerApi.get(`/api/exposants/business-lunch`)
         console.log(response.data)
         setExposantsDisponibles(response.data.map(e => {
            return { ...e, type: "business_lunch" }
         }));
      } catch (e) {
         console.log(e)
      }
   };

   const applyDejeuners = async (id, preferences, callback, fallback) => {
      try {
         const response = await trackerApi.post(`/api/etudiants/apply-dejeuners/${id}`, preferences)

         callback(response.data);
      } catch (e) {
         fallback();
         console.log(e)
      }
   };

   const updateDejeuner = async (dejeuner, callback) => {
      try {
         await trackerApi.post(`/api/dejeuners/update/${id}`, dejeuner)

         callback();
      } catch (e) {
         console.log(e)
      }
   };

   return [getExposantsDisponibles, applyDejeuners, updateDejeuner];
};