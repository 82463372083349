export var pays = [
    "Amérique du Nord",
    "Amérique du sud",
    "Europe",
    "Afrique",
    "Asie",
    "Moyen-Orient",
    "États-Unis",
    "Chine",
    "Japon",
    "Allemagne",
    "Royaume-Uni",
    "Inde",
    "France",
    "Brésil",
    "Italie",
    "Canada",
    "Corée du Sud",
    "Russie",
    "Australie",
    "Espagne",
    "Mexique",
    "Turquie",
    "Pays-Bas",
    "Suisse",
    "Belgique",
];
export var typeContrats = [
    "Stage de 1 mois",
    "Stage de 2-3 mois",
    "Stage de 6 mois",
    "VIE",
    "CDD",
    "CDI",
    "CIFRE",
    "Apprentissage",
    "Graduate Program",
    "Césure",
    "MBA",
    "MSC",
];
export var profils = [
    "1A",
    "2A",
    "3A",
    "Jeunes diplômés",
    "Alternants",
    "Doctorants",
];
export var secteurs = [
    "Agroalimentaire, Grande distribution",
    "Aéronautique, Aérospatial,  Défense",
    "Automobile, Transport, Logistique",
    "BTP, Immobilier",
    "Conseil, Banques, Finances, Services",
    "Ecole",
    "Energie, Environnement, Chimie, Médical",
    "Industrie, Ingénierie, IT",
    "Startup/PME",
];
export var tailles = [
    "1-10",
    "11-100",
    "101-500",
    "501-1000",
    "1001-5000",
    "> 5000",
];
export var heuresCreneaux = [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
];
