var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import { withApi } from "../../decorators/api";
// TODO SORTABLE
var equal = require("deep-equal");
var values = require("object.values");
import { getProp } from "../../redux/store";
import { Network } from "../../redux/actions";
import { Editor } from "./NewsEditor";
var News = /** @class */ (function (_super) {
    __extends(News, _super);
    function News(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            sorting: [],
            selectedNews: null,
            items: [],
        };
        return _this;
    }
    News.prototype.updateLocalValue = function (news, order) {
        order = order || [];
        news = news || {};
        var sortedItems = [];
        for (var _i = 0, order_1 = order; _i < order_1.length; _i++) {
            var id = order_1[_i];
            if (news[id] != null) {
                sortedItems.push(news[id]);
            }
        }
        for (var _a = 0, _b = values(news); _a < _b.length; _a++) {
            var item = _b[_a];
            if (order.indexOf(item.id) === -1) {
                sortedItems.push(item);
            }
        }
        this.setState({ items: sortedItems });
    };
    News.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.state.items.length == 0 ||
            !equal(this.props.news, nextProps.news)) {
            this.updateLocalValue(nextProps.news, this.state.sorting);
        }
    };
    News.prototype.loadNews = function () {
        var self = this;
        this.props.loadNews();
        self.props.dispatch(Network.request("/api/newsOrder", {}, {
            action: function (json) {
                self.setState({ sorting: json });
            },
        }, "GET"));
    };
    News.prototype.componentWillMount = function () {
        console.log("ok");
        this.loadNews();
    };
    //   public onSortEnd({ oldIndex, newIndex }) {
    //     let newItems = arrayMove(this.state.items, oldIndex, newIndex);
    //     this.setState({ items: newItems });
    //     console.log(newItems);
    //   }
    News.prototype.createNews = function () {
        var self = this;
        self.props.dispatch(Network.request("/api/news/create", {}, {
            action: function (json) {
                self.props.loadNews();
            },
        }, "POST"));
    };
    News.prototype.render = function () {
        var selectedNews = getProp(this.props, "news." + this.state.selectedNews);
        var self = this;
        //  const DragHandle = SortableHandle(() => (
        //    <i className="fa fa-bars fa-fw handle" />
        //  ));
        //  const SortableItem = SortableElement(({ sortIndex, value }) => {
        //    return (
        //      <li
        //        className={classNames("stylizedItem", {
        //          active: self.state.selectedNews == value.id,
        //        })}
        //        onClick={() => {
        //          self.setState({ selectedNews: value.id });
        //        }}
        //      >
        //        <DragHandle />
        //        {sortIndex + 1}: {value.titre}
        //      </li>
        //    );
        //  });
        //  const SortableList = SortableContainer(({ items }) => {
        //    return (
        //      <ul className={"stylizedList"}>
        //        {items.map((item, index) => (
        //          <SortableItem
        //            key={`item-${index}`}
        //            index={index}
        //            sortIndex={index}
        //            value={item}
        //          />
        //        ))}
        //      </ul>
        //    );
        //  });
        return (React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row news-editor pt-4" },
                React.createElement("div", { className: "col-lg-4 col-sm-6 col-xs-12 text-center" },
                    React.createElement("button", { className: "btn btn-primary m-4", onClick: function () {
                            self.createNews();
                        } }, "Nouvelle actualit\u00E9")),
                selectedNews == null ? null : (React.createElement("div", { className: "col-lg-8 col-sm-6 col-xs-12 news-editor-container" },
                    React.createElement(Editor, { actualite: selectedNews }))))));
    };
    News = __decorate([
        withApi(function (state) { return ({
            news: state.network.news,
        }); }),
        __metadata("design:paramtypes", [Object])
    ], News);
    return News;
}(React.Component));
export { News };
