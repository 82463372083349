var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { BASE_URL } from "../../utils";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import trackerApi from "../../api/tracker";
import Reorder, { reorder } from "react-reorder";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import useExposant from "../../hooks/useExposant";
var steps = [
    "Informations",
    "Sélectionnez 10 étudiants",
    "Classez-les par ordre de préférence",
];
var EditEntretiens = function (props) {
    var _a = useState(), exposant = _a[0], setExposant = _a[1];
    var _b = useState(0), activeStep = _b[0], setActiveStep = _b[1];
    var exposantId = useSelector(function (state) { return state.session.exposant; });
    var id = exposantId ? exposantId : props.adminExposantId;
    var _c = useExposant(), createExposant = _c[0], getExposant = _c[1], updateExposant = _c[2], deleteExposant = _c[3];
    var onSuccess = function (exposant) {
        setExposant(exposant);
    };
    var handleNext = function () {
        setActiveStep(function (prevActiveStep) { return prevActiveStep + 1; });
    };
    var handleBack = function () {
        setActiveStep(function (prevActiveStep) { return prevActiveStep - 1; });
    };
    var handleReset = function () {
        setActiveStep(0);
        setSelectedStudents([]);
    };
    var handleSubmit = function () {
        var formattedExposant = {
            id: id,
            entretien_preferences: selectedStudents.map(function (e) { return e.id; }),
        };
        console.log(formattedExposant);
        updateExposant(formattedExposant, onSuccess);
    };
    var _d = useState([]), rows = _d[0], setRows = _d[1];
    var _e = useState([]), selectedStudents = _e[0], setSelectedStudents = _e[1];
    var handleSelectedStudentsChange = function (newSelectionModel) {
        if ((newSelectionModel === null || newSelectionModel === void 0 ? void 0 : newSelectionModel.length) > 10) {
            alert("Vous pouvez choisir maximum 10 étudiants");
            return;
        }
        var newStudents = rows.filter(function (e) { return newSelectionModel.includes(e.id); });
        setSelectedStudents(newStudents);
        console.log("Selected Rows:", newStudents);
    };
    var handleReorderSelectedStudents = function (event, previousIndex, nextIndex) {
        setSelectedStudents(reorder(selectedStudents, previousIndex, nextIndex));
    };
    var columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            maxWidth: 80,
        },
        {
            field: "etudiant",
            headerName: "Etudiant (lien CV)",
            flex: 1,
            minWidth: 150,
            renderCell: function (params) {
                return (React.createElement("a", { href: params.row.lien_cv, target: "_blank", rel: "noopener noreferrer" },
                    params.row.prenom,
                    " ",
                    params.row.nom));
            },
        },
        {
            field: "formation",
            headerName: "Formation",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "motivation",
            headerName: "Motivation",
            flex: 1,
            minWidth: 400,
            renderCell: function (params) {
                return (React.createElement("div", { title: params.value, onClick: function (e) {
                        e.preventDefault();
                        alert(params.value);
                    }, style: { cursor: "pointer" } }, params.value));
            },
        },
    ];
    var getEntretiens = function (exposantId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, filteredEtudiants, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, trackerApi.get("api/etudiants/job-dating/".concat(exposantId))];
                case 1:
                    response = _a.sent();
                    console.log(exposantId, typeof exposantId);
                    filteredEtudiants = response.data.filter(function (e) {
                        if (e.entretien_preferences && e.entretien_preferences !== "null") {
                            return JSON.parse(e.entretien_preferences).includes(Number.parseInt(exposantId));
                        }
                        return false;
                    });
                    setRows(filteredEtudiants);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log("welcome to catch block!");
                    console.log(e_1);
                    toastr.error("Échec", "Un problème est survenu", {
                        position: "top-right",
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if ((exposant === null || exposant === void 0 ? void 0 : exposant.entretien_preferences) &&
            (exposant === null || exposant === void 0 ? void 0 : exposant.entretien_preferences) !== "null" &&
            (rows === null || rows === void 0 ? void 0 : rows.length) > 0) {
            var preferences_1 = JSON.parse(exposant.entretien_preferences);
            setActiveStep(steps.length);
            setSelectedStudents(rows
                .filter(function (e) { return preferences_1.includes(e.id); })
                .sort(function (a, b) { return preferences_1.indexOf(a.id) - preferences_1.indexOf(b.id); }));
        }
    }, [exposant === null || exposant === void 0 ? void 0 : exposant.entretien_preferences, rows]);
    useEffect(function () {
        if (id)
            getEntretiens(id);
    }, [id]);
    useEffect(function () {
        fetch("".concat(BASE_URL, "/api/exposant/").concat(id))
            .then(function (response) { return response.json(); })
            .then(setExposant);
    }, []);
    if (!exposant) {
        return (React.createElement(Box, { sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 8,
                height: window.innerHeight - 40,
            } },
            React.createElement("p", null, "En cours de chargement...")));
    }
    if (!(exposant === null || exposant === void 0 ? void 0 : exposant.entretien)) {
        return (React.createElement(Box, { sx: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 8,
                height: window.innerHeight - 40,
            } },
            React.createElement("p", null, "Vous n'avez pas souscrit \u00E0 l'offre de Job dating."),
            React.createElement("p", null, "Si celle-ci vous int\u00E9resse, veuillez nous contacter.")));
    }
    return (React.createElement(Box, { sx: {
            width: "100%",
            padding: 8,
            height: window.innerHeight - 40,
        } },
        React.createElement(Stepper, { activeStep: activeStep }, steps.map(function (label, index) {
            var stepProps = {};
            var labelProps = {};
            return (React.createElement(Step, __assign({ key: label }, stepProps),
                React.createElement(StepLabel, __assign({}, labelProps), label)));
        })),
        React.createElement(Box, { sx: {
                flex: 1,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingTop: 4,
            } },
            activeStep === 0 ? (React.createElement(Box, { sx: {
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 4,
                } },
                React.createElement("h2", null, "Job dating"),
                React.createElement("p", { className: "mt-2" }, "Bienvenue sur le formulaire de choix des \u00E9tudiants pour vos cr\u00E9neaux de job dating."),
                React.createElement("p", { className: "mt-2" }, "Vous allez avoir acc\u00E8s \u00E0 tous les \u00E9tudiants ayant candidat\u00E9."),
                React.createElement("p", { className: "mt-2" }, "Veuillez en s\u00E9lectionner 10 puis les classer par ordre de pr\u00E9f\u00E9rence."),
                React.createElement("p", { className: "mt-4" }, "N'h\u00E9sitez pas \u00E0 nous contacter si vous avez des questions."))) : activeStep === 1 ? (React.createElement(DataGrid, { disableRowSelectionOnClick: true, rows: rows, columns: columns, style: { flex: 1 }, initialState: {
                    pagination: {
                        paginationModel: { page: 0, pageSize: 50 },
                    },
                }, pageSizeOptions: [25, 50], checkboxSelection: true, rowSelectionModel: selectedStudents.map(function (e) { return e.id; }), onRowSelectionModelChange: handleSelectedStudentsChange })) : (React.createElement("div", { style: {
                    height: "100%",
                    overflow: "auto",
                } },
                activeStep === steps.length ? (React.createElement(Typography, { sx: { mt: 2, mb: 2 } },
                    React.createElement("h2", null, "Merci !"),
                    React.createElement("p", { className: "mt-2" }, "Vos pr\u00E9f\u00E9rences ont bien \u00E9t\u00E9 enregistr\u00E9es."),
                    React.createElement("p", { className: "mt-2 mb-4" }, "Nous allons contacter les \u00E9tudiants de mani\u00E8re successive selon votre ordre de pr\u00E9f\u00E9rence et nous pr\u00E9viendrons lorsqu'un \u00E9tudiant aura confirm\u00E9 son entretien."))) : null,
                React.createElement(Reorder, { reorderId: "my-list", reorderGroup: "reorder-group", component: "ul", placeholderClassName: "placeholder", style: {
                        width: "100%",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                        height: 50 * selectedStudents.length,
                    }, draggedClassName: "dragged", lock: "horizontal", holdTime: 500, touchHoldTime: 200, mouseHoldTime: 0, onReorder: handleReorderSelectedStudents, autoScroll: true, disabled: activeStep === steps.length, disableContextMenus: true, placeholder: React.createElement("div", { style: {
                            height: 50,
                            width: "100%",
                            borderRadius: 4,
                            border: "1px dashed #DCDCDC",
                            marginBottom: 4,
                        } }) }, selectedStudents.map(function (etudiant, index) { return (React.createElement("li", { style: {
                        listStyle: "none",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 45,
                        padding: "8px 12px",
                        borderRadius: 4,
                        marginBottom: 4,
                        border: "1px solid #DCDCDC",
                        cursor: activeStep === steps.length ? "pointer" : "move",
                        backgroundColor: "white",
                    }, key: etudiant.id },
                    React.createElement("div", null,
                        React.createElement("a", { href: etudiant.lien_cv, target: "_blank", rel: "no_referrer", style: { fontWeight: 500, display: "block" } }, [etudiant.prenom, etudiant.nom].join(" ")),
                        React.createElement("span", { style: {
                                fontSize: 12,
                                color: "#888888",
                                marginTop: 1,
                                display: "block",
                            } }, etudiant.formation)),
                    React.createElement("span", { className: "font-alt", style: {
                            fontWeight: 400,
                            fontSize: 16,
                            paddingRight: 12,
                        } },
                        index + 1,
                        activeStep !== steps.length && (React.createElement(MenuIcon, { style: { color: "#888888", marginLeft: 8 } }))))); })))),
            React.createElement(Box, { sx: { display: "flex", flexDirection: "row", pt: 2 } },
                React.createElement(Button, { color: "inherit", disabled: activeStep === 0, onClick: handleBack, sx: { mr: 1 } }, "Retour"),
                React.createElement(Box, { sx: {
                        flex: "1 1 auto",
                    } }),
                React.createElement(Button, { disabled: activeStep === 1 && (selectedStudents === null || selectedStudents === void 0 ? void 0 : selectedStudents.length) < 2, onClick: activeStep + 1 === steps.length
                        ? handleSubmit
                        : activeStep === steps.length
                            ? handleReset
                            : handleNext }, activeStep === steps.length - 1
                    ? "Valider"
                    : activeStep === 0
                        ? "Commencer"
                        : activeStep === steps.length
                            ? "Recommencer"
                            : "Suivant")))));
};
export default EditEntretiens;
