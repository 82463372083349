var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Figures } from "./Figures";
import { Sponsors } from "./Sponsors";
import { FAQ } from "./FAQ";
import { About } from "./About";
import { Intro } from "./Intro";
import { Hero } from "./Hero";
import { News } from "./News";
import { PlanAcces } from "./PlanAcces";
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    function Home() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Home.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Hero, null),
            React.createElement(Intro, null),
            React.createElement(About, null),
            React.createElement(PlanAcces, null),
            React.createElement(Figures, null),
            React.createElement(News, null),
            React.createElement(Sponsors, null),
            React.createElement(FAQ, null)));
    };
    return Home;
}(React.Component));
export { Home };
