var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import { toastr } from "react-redux-toastr";
import AccountSettings from "../../components/back/AccountSettings";
import { withApi } from "../../decorators/api";
import { connect, getProp } from "../../redux/store";
import { Network } from "../../redux/actions";
import EntrepriseXHeader from "../../components/back/EntrepriseXHeader";
var EntrepriseXAccount = /** @class */ (function (_super) {
    __extends(EntrepriseXAccount, _super);
    function EntrepriseXAccount(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            email: "",
            password: "",
            selectedUser: null,
        };
        return _this;
    }
    EntrepriseXAccount.prototype.createAccount = function (email, password) {
        var exposantId = this.props.match.params.id;
        var self = this;
        this.props.dispatch(Network.request("/api/users/create", {
            email: email,
            password: password,
            exposantId: exposantId,
        }, {
            action: function (json) {
                toastr.success("Compte créé", "Le compte utilisateur a bien été créé.", { position: "top-right" });
                self.props.loadUsersByExposant(exposantId);
            },
        }, "POST"));
    };
    EntrepriseXAccount.prototype.componentWillReceiveProps = function (nextProps) {
        var exposantId = this.props.match.params.id;
        var nextExposantId = nextProps.match.params.id;
        if (nextExposantId && exposantId != nextExposantId) {
            this.props.loadUsersByExposant(nextExposantId);
        }
    };
    EntrepriseXAccount.prototype.componentWillMount = function () {
        this.props.loadAllEntreprises();
        var exposantId = this.props.match.params.id;
        if (exposantId) {
            this.props.loadUsersByExposant(exposantId);
        }
    };
    EntrepriseXAccount.prototype.render = function () {
        var entreprise = getProp(this.props, "exposants." + this.props.match.params.id);
        var self = this;
        var exposantId = this.props.match.params.id;
        if (this.props.usersByExposants[exposantId] == null) {
            return null;
        }
        var users = this.props.usersByExposants[exposantId].users;
        return (React.createElement("section", { className: "clearfix bg-dark listingSection" },
            React.createElement(EntrepriseXHeader, { entreprise: entreprise }),
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "col-12 row" },
                    React.createElement("div", { className: "col-4" },
                        React.createElement("select", { multiple: true, className: "form-control", style: { width: "100%'" } }, users.map(function (user, i) {
                            return (React.createElement("option", { key: i, selected: self.state.selectedUser == user.id, onClick: function () {
                                    self.setState({ selectedUser: user.id });
                                } }, user.email));
                        })),
                        React.createElement("div", { className: "mt-5 row card", style: { width: "100%" } },
                            React.createElement("div", { className: "card-body" },
                                React.createElement("h5", null, "Nouveau compte"),
                                React.createElement("div", { className: "form-group col-12" },
                                    React.createElement("label", { htmlFor: "email" }, "Email"),
                                    React.createElement("input", { className: "form-control", type: "text", value: this.state.email, onChange: function (e) {
                                            self.setState({ email: e.target.value });
                                        } })),
                                React.createElement("div", { className: "form-group col-12" },
                                    React.createElement("label", { htmlFor: "password" }, "Mot de passe"),
                                    React.createElement("input", { className: "form-control", type: "password", value: this.state.password, onChange: function (e) {
                                            self.setState({ password: e.target.value });
                                        } })),
                                React.createElement("button", { className: "btn btn-primary", onClick: function () {
                                        self.createAccount(self.state.email, self.state.password);
                                    } }, "Cr\u00E9er l'utilisateur")))),
                    this.state.selectedUser == null ? null : (React.createElement(AccountSettings, { userId: this.state.selectedUser, exposantId: exposantId, className: "col-8", onUpdate: function () {
                            self.props.loadUsersByExposant(exposantId);
                        } }))))));
    };
    EntrepriseXAccount = __decorate([
        connect(function (state) { return ({
            usersByExposants: state.network.usersByExposants,
        }); }),
        withApi(function (state) { return ({
            exposants: state.network.exposants,
            isAdmin: state.session.admin,
            exposantId: state.session.exposant,
        }); }),
        __metadata("design:paramtypes", [Object])
    ], EntrepriseXAccount);
    return EntrepriseXAccount;
}(React.Component));
export { EntrepriseXAccount };
