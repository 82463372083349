var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import config from "../../config";
var About = /** @class */ (function (_super) {
    __extends(About, _super);
    function About() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    About.prototype.render = function () {
        return (React.createElement("section", { id: "about", className: "bg-white" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center mb-5" },
                    React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                        React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "\u00C0 propos du Forum Arts et M\u00E9tiers"),
                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Infos pratiques"),
                        React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                React.createElement("div", { className: "row justify-content-center" },
                    React.createElement("div", { className: "col-lg-6 col-xl-5 text-center text-lg-left" },
                        React.createElement("p", { className: "font-alt font-w-600 letter-spacing-1 text-medium text-uppercase" }, "Explorez, rencontrez, b\u00E2tissez votre avenir !"),
                        React.createElement("p", { className: "text-medium" }, "Depuis plus de 40 ans, le Forum Arts et Me\u0301tiers accompagne annuellement plus de 140 entreprises qui rythment cet e\u0301ve\u0300nement riche en rencontres professionnelles. Cette journe\u0301e est l\u2019opportunite\u0301 de rencontrer des e\u0301le\u0300ves inge\u0301nieurs motive\u0301s et pre\u0301pare\u0301s a\u0300 vous rencontrer."),
                        React.createElement("a", { target: "_blank", href: config.plaquetteUrl, className: "page-scroll btn btn-outline-black btn-small border-thick mt-2 mx-0" },
                            React.createElement("i", { className: "fa fa-download fa-fw" }),
                            "Plaquette commerciale")),
                    React.createElement("div", { className: "col-lg-6 offset-xl-1" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-sm-6 mt-5 mt-lg-0 text-center text-lg-left" },
                                React.createElement("i", { className: "fa fa-map-marker fa-3x text-blue" }),
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-3 text-medium text-uppercase" }, "Lieu"),
                                React.createElement("p", { className: "mb-0 mt-2 text-medium" },
                                    "Parc Floral",
                                    React.createElement("br", null),
                                    "Route de la Pyramide Carrefour Charles Lindbergh",
                                    React.createElement("br", null),
                                    "75012 Paris")),
                            React.createElement("div", { className: "col-sm-6 mt-5 mt-lg-0 text-center text-lg-left" },
                                React.createElement("i", { className: "fa fa-calendar-check-o fa-3x text-blue" }),
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-3 text-medium text-uppercase" }, "Date"),
                                React.createElement("p", { className: "mb-0 mt-2 text-medium" }, "le mercredi 20 novembre 2024 de 10h \u00E0 17h")),
                            React.createElement("div", { className: "col-sm-6 mt-5 text-center text-lg-left" },
                                React.createElement("i", { className: "fa fa-bullhorn fa-3x text-blue" }),
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-3 text-medium text-uppercase" }, "Acc\u00E8s"),
                                React.createElement("p", { className: "mb-0 mt-2 text-medium" }, "M\u00E9tro ligne 1 (arr\u00EAt Ch\u00E2teau de Vincennes) ou RER A (arr\u00EAt Vincennes)")),
                            React.createElement("div", { className: "col-sm-6 mt-5 text-center text-lg-left" },
                                React.createElement("i", { className: "fa fa-eur fa-3x text-blue" }),
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-3 text-medium text-uppercase" }, "Co\u00FBt"),
                                React.createElement("p", { className: "mb-0 mt-2 text-medium" }, "Gratuit pour les \u00E9tudiants, transport pris en charge par la Fondation Arts et M\u00E9tiers"))))))));
    };
    return About;
}(React.Component));
export { About };
