var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { connect } from "../redux/store";
import { Network } from "../redux/actions";
export function withApi(mapStateToProps) {
    if (mapStateToProps === void 0) { mapStateToProps = null; }
    return function (WrappedComponent) {
        var ApiComponent = /** @class */ (function (_super) {
            __extends(ApiComponent, _super);
            function ApiComponent() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            ApiComponent.prototype.loadEntreprises = function (resetCollections) {
                if (resetCollections === void 0) { resetCollections = false; }
                this.props.dispatch(Network.crudGetIndex("/api/exposants", {
                    exposants: "id",
                }, resetCollections));
            };
            ApiComponent.prototype.loadAllEntreprises = function (resetCollections) {
                if (resetCollections === void 0) { resetCollections = false; }
                this.props.dispatch(Network.crudGetIndex("/api/allExposants", {
                    exposants: "id",
                }, resetCollections));
            };
            ApiComponent.prototype.loadNews = function (resetCollections) {
                if (resetCollections === void 0) { resetCollections = false; }
                this.props.dispatch(Network.crudGetIndex("/api/news", {
                    news: "id",
                }, resetCollections));
            };
            ApiComponent.prototype.loadUser = function (userId) {
                this.props.dispatch(Network.crudGetIndex("/api/users/" + userId, {
                    users: "id",
                }));
            };
            ApiComponent.prototype.loadUsersByExposant = function (exposantId) {
                this.props.dispatch(Network.crudGetEntity("/api/users/byExposant/" + exposantId, "usersByExposants", "exposantId"));
            };
            ApiComponent.prototype.loadContenu = function () {
                return Network.jsonRequest("/api/contenu", "GET").then(function (results) {
                    var res = {};
                    for (var _i = 0, results_1 = results; _i < results_1.length; _i++) {
                        var r = results_1[_i];
                        res[r.type] = r;
                    }
                    return res;
                });
            };
            ApiComponent.prototype.render = function () {
                var apiProps = {
                    loadEntreprises: this.loadEntreprises.bind(this),
                    loadAllEntreprises: this.loadAllEntreprises.bind(this),
                    loadNews: this.loadNews.bind(this),
                    loadUser: this.loadUser.bind(this),
                    loadUsersByExposant: this.loadUsersByExposant.bind(this),
                    loadContenu: this.loadContenu.bind(this),
                };
                return React.createElement(WrappedComponent, __assign({}, this.props, apiProps));
            };
            ApiComponent = __decorate([
                connect(mapStateToProps)
            ], ApiComponent);
            return ApiComponent;
        }(React.Component));
        return ApiComponent;
    };
}
