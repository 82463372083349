var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as values from "object.values";
import * as moment from "moment";
import { Link } from "react-router-dom";
import * as classNames from "classnames";
import { SocialButtons } from "../../components/front/SocialButtons";
import { withApi } from "../../decorators/api";
import { getImageUrl } from "../../utils";
var youtubeLogo = require("../../../assets/img/youtube.png");
var News = /** @class */ (function (_super) {
    __extends(News, _super);
    function News() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    News.prototype.componentWillMount = function () {
        this.props.loadNews();
    };
    News.prototype.get5LatestNews = function () {
        var allNews = values(this.props.news);
        return allNews
            .sort(function (left, right) {
            return moment.utc(right.createdAt).diff(moment.utc(left.createdAt));
        })
            .slice(0, 5);
    };
    News.prototype.render = function () {
        var news = this.get5LatestNews();
        return (React.createElement("section", { id: "news", className: "bg-white" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center mb-5" },
                    React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                        React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Actualit\u00E9s"),
                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Les derni\u00E8res actualit\u00E9s du forum"),
                        React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                React.createElement("div", { className: "row" },
                    news.map(function (item, i) {
                        return (React.createElement("div", { className: classNames("col-md-6 col-xl-4", {
                                "mt-4": i >= 3,
                            }), key: i },
                            React.createElement("div", { style: { height: "100%" }, className: "align-items-center bg-white border border-gray-300 box-shadow d-flex justify-content-between p-4 position-relative rounded" },
                                React.createElement("div", { className: "pr-3" },
                                    React.createElement(Link, { to: "/news/" + item.id, key: i },
                                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 text-small text-uppercase" }, item.titre)),
                                    React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-extra-small text-uppercase" }, moment(item.createdAt).lang("fr").calendar())),
                                item.type == "youtube" ? (React.createElement("a", { target: "_blank", href: "https://youtube.com/watch?v=" + item.youtubeId, key: i },
                                    React.createElement("img", { src: youtubeLogo, style: { width: "100px" }, alt: "Youtube", className: "img-fluid rounded" }))) : (React.createElement(Link, { to: "/news/" + item.id, key: i },
                                    React.createElement("img", { src: getImageUrl(item.image), style: { width: "100px", height: "100px" }, alt: "", className: "img-fluid rounded" }))))));
                    }),
                    React.createElement("div", { className: "col-md-6 col-xl-4" },
                        React.createElement("div", { className: "align-items-center  d-flex justify-content-between p-4 position-relative rounded" },
                            React.createElement("div", { className: "mt-5 pr-3" },
                                React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 text-small text-uppercase" },
                                    React.createElement(Link, { to: "/news" },
                                        "Voir toutes les actualit\u00E9s \u00A0 \u00A0",
                                        React.createElement("i", { className: "fa fa-arrow-right" }))))))),
                React.createElement("div", { className: "row justify-content-center mt-5" },
                    React.createElement("div", { className: "col-lg-10 text-center mt-3 mt-lg-5" },
                        React.createElement("span", { className: "font-alt font-w-700 letter-spacing-2 text-uppercase title-small" },
                            "Suivez nous \u00E9galement sur nos r\u00E9seaux sociaux :",
                            React.createElement("br", null),
                            React.createElement(SocialButtons, { className: "footer-icon-social mb-0 mt-4 p-0 text-center text-md-center title-xs-small title-medium" })))))));
    };
    News = __decorate([
        withApi(function (rootState) { return ({
            news: rootState.network.news,
        }); })
    ], News);
    return News;
}(React.Component));
export { News };
