var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var SocialButtons = /** @class */ (function (_super) {
    __extends(SocialButtons, _super);
    function SocialButtons() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SocialButtons.prototype.render = function () {
        return (React.createElement("ul", { className: this.props.className },
            React.createElement("li", { className: "list-inline-item" },
                React.createElement("a", { href: "https://www.facebook.com/forumartsetmetiers", className: "text-gray-600" },
                    React.createElement("i", { className: "fa fa-facebook" }))),
            React.createElement("li", { className: "list-inline-item pl-4" },
                React.createElement("a", { href: "https://www.linkedin.com/company/forum-arts-&-m%C3%A9tiers", className: "text-gray-600" },
                    React.createElement("i", { className: "fa fa-linkedin" }))),
            React.createElement("li", { className: "list-inline-item pl-4" },
                React.createElement("a", { href: "https://www.instagram.com/forum_am", className: "text-gray-600" },
                    React.createElement("i", { className: "fa fa-instagram" })))));
    };
    return SocialButtons;
}(React.Component));
export { SocialButtons };
