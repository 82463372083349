var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "../../redux/store";
var espaceEtudiants = require("../../../assets/images/Espace etudiant.jpg");
var exposants = require("../../../assets/images/Exposants.jpg");
var espaceEntreprises = require("../../../assets/images/Espace entreprise.jpg");
var Intro = /** @class */ (function (_super) {
    __extends(Intro, _super);
    function Intro() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Intro.prototype.render = function () {
        return (React.createElement("div", { id: "intro", className: "bg-white" },
            React.createElement("div", { className: "container-fluid p-xl-0" },
                React.createElement("div", { className: "row justify-content-center no-gutters" },
                    React.createElement("div", { className: "col-lg-10 col-xl-12 text-center py-5" },
                        React.createElement("span", { className: "d-block font-alt font-w-700 letter-spacing-2 mt-1 text-uppercase title-small" }, "D\u00E9couvrez le forum Arts et M\u00E9tiers"))),
                React.createElement("div", { className: "row justify-content-center no-gutters", style: { height: "350px" } },
                    React.createElement("div", { className: "col-lg-10 col-xl-4 bg-overlay-black-5 my-2 my-xl-0 position-relative" },
                        React.createElement("div", { style: {
                                background: "url(" + espaceEtudiants + ")",
                                backgroundSize: "cover",
                            }, className: "d-flex align-items-center h-100 justify-content-center left-0 position-absolute top-0 w-100" },
                            React.createElement(Link, { to: "/presentation" },
                                React.createElement("span", { className: "font-alt font-w-600 letter-spacing-1 text-gray-100 text-small text-uppercase" }, "Espace \u00C9tudiants")))),
                    React.createElement("div", { className: "col-lg-10 col-xl-4 bg-overlay-black-5 my-2 my-xl-0 position-relative" },
                        React.createElement("div", { style: {
                                background: "url(" + exposants + ")",
                                backgroundSize: "cover",
                            }, className: "d-flex align-items-center h-100 justify-content-center left-0 position-absolute top-0 w-100" },
                            React.createElement(Link, { to: "/exposants" },
                                React.createElement("span", { className: "font-alt font-w-600 letter-spacing-1 text-gray-100 text-small text-uppercase" }, "Liste des exposants")))),
                    React.createElement("div", { className: "col-lg-10 col-xl-4 bg-overlay-black-5 my-2 my-xl-0 position-relative" },
                        React.createElement("div", { style: {
                                background: "url(" + espaceEntreprises + ")",
                                backgroundSize: "cover",
                            }, className: "d-flex align-items-center h-100 justify-content-center left-0 position-absolute top-0 w-100" },
                            React.createElement(Link, { to: this.props.userId == null
                                    ? "/login-entreprise"
                                    : "/espace-entreprise/details" },
                                React.createElement("span", { className: "font-alt font-w-600 letter-spacing-1 text-gray-100 text-small text-uppercase" }, "Espace entreprises"))))))));
    };
    Intro = __decorate([
        connect(function (state) { return ({
            userId: state.session.userId,
        }); })
    ], Intro);
    return Intro;
}(React.Component));
export { Intro };
