var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import useAccount from "../../hooks/useAccount";
var AccountSettings = function (_a) {
    var userId = _a.userId, exposantId = _a.exposantId, className = _a.className, onUpdate = _a.onUpdate;
    var _b = useState(), account = _b[0], setAccount = _b[1];
    var _c = useState([]), usersByExposant = _c[0], setUsersByExposant = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useAccount(), updateAccount = _e[0], getUsersByExposant = _e[1];
    var onFail = useCallback(function () {
        setLoading(false);
    }, []);
    var onSuccess = useCallback(function () {
        setLoading(false);
        onUpdate ? onUpdate() : null;
        toastr.success("Détails mis à jour", "Le profil a bien été mis à jour.", {
            position: "top-right",
        });
    }, []);
    var onSubmit = function (e) {
        var _a;
        console.log("coucou");
        e.preventDefault();
        setLoading(true);
        if (userId == null) {
            return;
        }
        if (((_a = account.password) === null || _a === void 0 ? void 0 : _a.trim()) !== "" &&
            account.password !== account.password2) {
            return toastr.warning("Mots de passe différents", "Vous devez indiquer le même mot de passe.", { position: "top-right" });
        }
        updateAccount(userId, account, onSuccess, onFail);
    };
    useEffect(function () {
        getUsersByExposant(exposantId, setUsersByExposant);
    }, [exposantId]);
    return (React.createElement("div", { className: className },
        React.createElement("form", { className: "listing__form", onSubmit: onSubmit },
            React.createElement("div", { className: "dashboardBoxBg mb30" },
                React.createElement("div", { className: "about paraMargin" },
                    React.createElement("h3", null,
                        "Identifiants de connexion (utilisateur #",
                        userId,
                        ")"),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "form-group col-12" },
                            React.createElement("label", { htmlFor: "email" }, "Nouvel email de connexion"),
                            React.createElement("input", { type: "email", className: "form-control", id: "email", value: account === null || account === void 0 ? void 0 : account.email, placeholder: "barack.obama@gmail.com", onChange: function (e) {
                                    return setAccount(__assign(__assign({}, account), { email: e.target.value }));
                                } })),
                        React.createElement("div", { className: "form-group col-6" },
                            React.createElement("label", { htmlFor: "password" }, "Nouveau mot de passe (8 caract\u00E8res minimum)"),
                            React.createElement("input", { type: "password", className: "form-control", id: "password", value: account === null || account === void 0 ? void 0 : account.password, minLength: 8, placeholder: "*********", onChange: function (e) {
                                    return setAccount(__assign(__assign({}, account), { password: e.target.value }));
                                } }),
                            React.createElement("p", null, "(Laissez vide pour ne pas le modifier)")),
                        React.createElement("div", { className: "form-group col-6" },
                            React.createElement("label", { htmlFor: "password2" }, "Confirmation du nouveau mot de passe"),
                            React.createElement("input", { type: "password", className: "form-control", id: "password2", value: account === null || account === void 0 ? void 0 : account.password2, minLength: 8, placeholder: "*********", onChange: function (e) {
                                    return setAccount(__assign(__assign({}, account), { password2: e.target.value }));
                                } }))))),
            React.createElement("div", { className: "form-footer text-center" },
                React.createElement("button", { type: "submit", className: "btn-submit" }, "Mettre \u00E0 jour")))));
};
export default AccountSettings;
