// export const BASE_URL = "http://localhost:2998"; // TODO SWITCH
export var BASE_URL = "https://forum-am.fr"; // TODO SWITCH
export function getImageUrl(rawUrl) {
    if (!rawUrl) {
        return null;
    }
    if (rawUrl.startsWith("http")) {
        return rawUrl;
    }
    return "http://" + rawUrl;
}
export function makeOptions(l) {
    try {
        return l.map(function (v) { return ({ value: v, label: v }); });
    }
    catch (e) {
        return [];
    }
}
export function makeOptionsObjects(l) {
    try {
        return l.map(function (v) { return ({ value: v, label: v.titre }); });
    }
    catch (e) {
        return [];
    }
}
export function getListValue(rawValue) {
    if (rawValue == null || rawValue == "") {
        return [];
    }
    else {
        try {
            var v = JSON.parse(rawValue);
            if (!Array.isArray(v)) {
                return [];
            }
            else {
                return v;
            }
        }
        catch (e) {
            return [];
        }
    }
}
export function extractValues(l) {
    return l.map(function (v) { return v.value; });
}
export function getBooleanValue(rawValue) {
    if (rawValue == null || rawValue == "") {
        return { value: "Oui", label: "Oui" };
    }
    else {
        if (rawValue == "true") {
            return { value: "Oui", label: "Oui" };
        }
        else {
            return { value: "Non", label: "Non" };
        }
    }
}
export function dumpBooleanValue(selectValue) {
    if (selectValue && selectValue.value == "Oui") {
        return "true";
    }
    else {
        return "false";
    }
}
