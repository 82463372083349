var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Link } from "react-router-dom";
import { Form, Input } from "informed";
import { toastr } from "react-redux-toastr";
import { connect } from "../../redux/store";
import { Network, Session as SessionActions } from "../../redux/actions";
var LoginEtudiant = /** @class */ (function (_super) {
    __extends(LoginEtudiant, _super);
    function LoginEtudiant() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoginEtudiant.prototype.login = function (data) {
        var _this = this;
        var self = this;
        var values = data.values;
        Network.jsonRequest("/api/login", "POST", values)
            .then(function (json) {
            var userId = json.userId, email = json.email, admin = json.admin, exposant = json.exposant, etudiant = json.etudiant, userType = json.userType, token = json.token;
            if (!exposant && !etudiant && !userType) {
                toastr.error("Échec", "Ce compte n'est rattaché à aucune entreprise ou étudiant", {
                    position: "top-right",
                });
            }
            if (!userId) {
                return toastr.error("Échec", "Missing userId", {
                    position: "top-right",
                });
            }
            localStorage.setItem("token", token);
            toastr.success("Connexion réussie", "Bienvenue " + email, {
                position: "top-right",
            });
            _this.props.dispatch(SessionActions.setUser(userId, email, admin, exposant, etudiant, userType));
            if (admin) {
                _this.props.history.push("/admin/entreprises");
            }
            if (exposant) {
                _this.props.history.push("/espace-entreprise/details");
            }
            if (etudiant) {
                _this.props.history.push("/espace-etudiant/compte");
            }
        })
            .catch(function (err) {
            console.log(err);
            toastr.error("Échec", "Identifiants incorrects", {
                position: "top-right",
            });
        });
    };
    LoginEtudiant.prototype.render = function () {
        return (React.createElement("section", { className: "clearfix loginSection" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row mt-5" },
                    React.createElement("div", { className: "mx-auto col-md-5 col-sm-9 col-xs-12" },
                        React.createElement("div", { className: "panel panel-default loginPanel" },
                            React.createElement("div", { style: {
                                    backgroundColor: "#8e2862",
                                }, className: "panel-heading text-center" }, "Espace \u00E9tudiant"),
                            React.createElement("div", { className: "panel-body" },
                                React.createElement(Form, { className: "loginForm", onSubmit: this.login.bind(this) },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("label", { htmlFor: "userName" }, "Email *"),
                                        React.createElement(Input, { name: "email", field: "email", type: "text", className: "form-control", id: "userName" }),
                                        React.createElement("p", { className: "help-block" }, "Entrez votre adresse mail.")),
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("label", { htmlFor: "userPassword" }, "Mot de passe *"),
                                        React.createElement(Input, { name: "password", field: "password", type: "password", className: "form-control", id: "userPassword" }),
                                        React.createElement("p", { className: "help-block" }, "Entrez votre mot de passe.")),
                                    React.createElement("div", { className: "form-group", style: {
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        } },
                                        React.createElement("button", { type: "submit", className: "btn btn-primary" }, "Connexion")))),
                            React.createElement("div", { className: "panel-footer text-center" }, new Date() < new Date("2023-11-13T00:00:00") ? (React.createElement("p", null,
                                "Pas encore d'espace ? \u00A0",
                                React.createElement(Link, { to: "/inscription-etudiant", className: "link" }, "Inscription"))) : (React.createElement("div", { className: "panel-footer text-center" },
                                React.createElement("p", null, "Pas besoin d\u2019inscriptions pour participer au Forum Arts et M\u00E9tiers ! Seules les inscriptions pour participer \u00E0 un Business lunch ou Job dating sont closes pour 2023"))))))))));
    };
    LoginEtudiant = __decorate([
        connect()
    ], LoginEtudiant);
    return LoginEtudiant;
}(React.Component));
export { LoginEtudiant };
