var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import { toastr } from "react-redux-toastr";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, } from "react-accessible-accordion";
import { withApi } from "../../decorators/api";
import { getImageUrl } from "../../utils";
import { Network } from "../../redux/actions";
require("react-accessible-accordion/dist/fancy-example.css");
var update = require("immutability-helper");
var ReactQuill = require("react-quill");
require("quill/dist/quill.snow.css");
var Editor = /** @class */ (function (_super) {
    __extends(Editor, _super);
    function Editor(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            panes: [],
        };
        return _this;
    }
    Editor.prototype.loadContenu = function () {
        var self = this;
        this.props.loadContenu().then(function (contenu) {
            try {
                var newPanes = JSON.parse(contenu[self.props.type].contenu);
                self.setState({ panes: newPanes });
            }
            catch (e) { }
        });
    };
    Editor.prototype.componentWillMount = function () {
        this.loadContenu();
    };
    Editor.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.type != nextProps.type) {
            this.loadContenu();
        }
    };
    Editor.prototype.render = function () {
        var self = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "col-12" },
                React.createElement(Accordion, null, this.state.panes.map(function (pane, i) {
                    function updatePaneField(field, newValue) {
                        self.setState(function (state) {
                            var _a, _b;
                            return update(state, {
                                panes: (_a = {},
                                    _a[i] = (_b = {},
                                        _b[field] = { $set: newValue },
                                        _b),
                                    _a),
                            });
                        });
                    }
                    function deletePane() {
                        self.setState(function (state) {
                            return update(state, {
                                panes: {
                                    $splice: [[i, 1]],
                                },
                            });
                        });
                    }
                    return (React.createElement(AccordionItem, { key: i },
                        React.createElement(AccordionItemHeading, null,
                            React.createElement(AccordionItemButton, null,
                                React.createElement("h3", null, pane.title))),
                        React.createElement(AccordionItemPanel, null,
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "form-group col-12" },
                                    React.createElement("label", { htmlFor: "titre-" + i }, "Titre de la section"),
                                    React.createElement("input", { type: "text", className: "form-control", id: "titre-" + i, placeholder: "Titre de la section", value: pane.title, onChange: function (e) {
                                            updatePaneField("title", e.target.value);
                                        } }))),
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-6" },
                                    React.createElement("h4", null, "Contenu"),
                                    React.createElement(ReactQuill, { value: pane.content, onChange: function (newValue) {
                                            updatePaneField("content", newValue);
                                        } })),
                                React.createElement("div", { className: "col-6" },
                                    React.createElement("h4", null, "Image"),
                                    React.createElement("div", { className: "form-group col-12" },
                                        React.createElement("label", { htmlFor: "image-" + i }, "URL de l'image"),
                                        React.createElement("input", { type: "text", className: "form-control", id: "timage-" + i, placeholder: "URL de l'image", value: pane.image, onChange: function (e) {
                                                updatePaneField("image", e.target.value);
                                            } })),
                                    pane.image == null ||
                                        pane.image.trim() == "" ? null : (React.createElement("img", { src: getImageUrl(pane.image), alt: "Image de la section", className: "d-block col-12" }))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12 text-center p-4" },
                                        React.createElement("button", { className: "btn btn-danger", onClick: deletePane }, "Supprimer la section")))))));
                }))),
            React.createElement("div", { className: "col-12 text-center p-4" },
                React.createElement("button", { className: "btn btn-primary", onClick: function () {
                        self.setState(function (state) {
                            return update(state, {
                                panes: {
                                    $push: [
                                        {
                                            title: "Section",
                                            content: "",
                                            image: "",
                                        },
                                    ],
                                },
                            });
                        });
                    } }, "Ajouter une section"),
                React.createElement("button", { className: "ml-3 btn btn-primary", onClick: function () {
                        Network.jsonRequest("/api/saveContent", "POST", {
                            type: self.props.type,
                            contenu: JSON.stringify(self.state.panes),
                        }).then(function () {
                            toastr.success("Sauvebarde", "Le contenu a bien été sauvegardé.", { position: "top-right" });
                        });
                    } }, "Sauvegarder"))));
    };
    Editor = __decorate([
        withApi(),
        __metadata("design:paramtypes", [Object])
    ], Editor);
    return Editor;
}(React.Component));
export { Editor };
