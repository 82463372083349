import axios from 'axios';
import { BASE_URL } from '../utils';

const trackerApi = axios.create({
   baseURL: BASE_URL,
   withCredentials: true,
   headers: {
      'Accept': 'application/json',
      "Content-Type": "application/json",
      'Accept-Language': 'FR',
      "Content-Type": "application/json",
   }
})

trackerApi.interceptors.request.use(
   async (config) => {
      const multipartRoutes = [
         '/api/etudiants/create',
         /^\/api\/etudiants\/update-cv\/\d+$/, // \d+ matches all api/etudiants/update/:id
      ]
      const multipartMethods = [
         'put',
         'post'
      ]

      const token = localStorage.getItem('token');

      if (token) {
         config.headers['x-auth-token'] = token;
      }

      if (config.url) {
         console.log(config.url);
      }

      if (
         config.url &&
         config.method &&
         multipartMethods.includes(config.method) &&
         multipartRoutes.some((route) =>
            typeof route === 'string'
               ? route === config.url
               : route.test(config.url)
         )
      ) {
         config.headers['Content-Type'] = 'multipart/form-data';
      } else {
         config.headers['Content-Type'] = 'application/json';
      }

      return config;
   },
   (error) => {
      return Promise.reject(error)
   }
)

// Response interceptor for API calls
trackerApi.interceptors.response.use((response) => {

   // if (response.config.url) {
   //    console.log(styleConfigUrl(response.config.url.slice(1), "RESPONSE"))
   // }

   return response;
});

export default trackerApi;