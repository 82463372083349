var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
var classique_beure = require("../../../assets/images/restauration/classique-beure.jpg");
var classique_chevre = require("../../../assets/images/restauration/classique-chevre.jpg");
var classique_mixte = require("../../../assets/images/restauration/classique-mixte.jpg");
var classique_thon = require("../../../assets/images/restauration/classique-thon.jpg");
var classique_poulet = require("../../../assets/images/restauration/classique-poulet.jpg");
var club_saumon = require("../../../assets/images/restauration/club-saumon.jpg");
var club_poulet = require("../../../assets/images/restauration/club-poulet.jpg");
var club_moza = require("../../../assets/images/restauration/club-moza.jpg");
var dessert_patisserie = require("../../../assets/images/restauration/dessert-patisserie.jpg");
var dessert_bodega_fromage = require("../../../assets/images/restauration/dessert-bodega-fromage.jpg");
var dessert_bodega_mousse = require("../../../assets/images/restauration/dessert-bodega-mousse.jpg");
var dessert_bodega_tiramissou = require("../../../assets/images/restauration/dessert-bodega-tiramissou.jpg");
var dessert_bodega_panacota = require("../../../assets/images/restauration/dessert-bodega-panacota.jpg");
var dessert_salade = require("../../../assets/images/restauration/dessert-salade.jpg");
var dessert_tarte = require("../../../assets/images/restauration/dessert-tarte.jpg");
var Restauration = /** @class */ (function (_super) {
    __extends(Restauration, _super);
    function Restauration(props) {
        return _super.call(this, props) || this;
    }
    Restauration.prototype.render = function () {
        var self = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("section", { id: "schedule", className: "bg-white mt-5" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row justify-content-center mb-5" },
                        React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                            React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Restauration pour les \u00E9tudiants"),
                            React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Le Forum AM prend \u00E0 sa charge 65% du prix public, pour que vous b\u00E9n\u00E9ficiez de prix tr\u00E8s avantageux."),
                            React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                    React.createElement("div", { className: "granddiv" },
                        React.createElement("div", { className: "grandtitre" },
                            React.createElement("h2", null, " Les visuels sont des suggestions de pr\u00E9sentation et les prix sont \u00E0 titre indicatif."),
                            React.createElement("p", { className: "imgtitreindicatif" }, "Achat sur place.")),
                        React.createElement("div", { className: "sousdivformuleetplats" },
                            React.createElement("div", { className: "formules" },
                                React.createElement("h2", { className: "titreplat" }, "Formules"),
                                React.createElement("div", { className: "lesdiff" },
                                    React.createElement("div", { className: "uneformue" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Complet"),
                                                    React.createElement("div", { className: "prixdescription" }, "5\u20AC")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Sandwich + boisson + dessert")))),
                                    React.createElement("div", { className: "uneformue" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Sandwich seul"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Sans accompagnement")))))),
                            React.createElement("div", { className: "plats" },
                                React.createElement("h2", { className: "titreplat" }, "Sandwichs classiques"),
                                React.createElement("div", { className: "lesdiff" },
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: classique_beure, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Parisien"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Jambon beurre")))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: classique_chevre, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Ch\u00E8vre"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Ch\u00E8vre tapenade roquette tomates s\u00E9ch\u00E9es")))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: classique_mixte, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Mixte"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Jambon emmental")))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: classique_thon, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Thon"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Thon mayonnaise concombre tomates s\u00E9ch\u00E9es")))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: classique_poulet, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Poulet"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Poulet roquette mayonnaise tomates s\u00E9ch\u00E9es")))))),
                            React.createElement("div", { className: "plats" },
                                React.createElement("h2", { className: "titreplat" }, "Club sandwich"),
                                React.createElement("div", { className: "lesdiff" },
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: club_saumon, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Saumon"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Saumon cr\u00E8me \u00E0 l'aneth")))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: club_poulet, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Classique poulet"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" }, "Poulet salade tomate oeuf mayonnaise")))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: club_moza, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Mozzarella"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" },
                                                    "Mozzarella tomate pesto",
                                                    " ")))))),
                            React.createElement("div", { className: "plats" },
                                React.createElement("h2", { className: "titreplat" }, "Desserts"),
                                React.createElement("div", { className: "lesdiff" },
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: dessert_patisserie, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "P\u00E2tisserie"),
                                                    React.createElement("div", { className: "prixdescription" }, "Inclus dans le menu"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")),
                                                React.createElement("div", { className: "descriptiondescription" }, "P\u00E2tisserie am\u00E9ricaine")))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: dessert_bodega_fromage, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Bodega de fromage blanc"),
                                                    React.createElement("div", { className: "prixdescription" }, "Inclus dans le menu"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul"))))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: dessert_bodega_mousse, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Bodega de mousse au chocolat"),
                                                    React.createElement("div", { className: "prixdescription" }, "Inclus dans le menu"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul"))))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: dessert_bodega_tiramissou, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Bodega de Tiramisu"),
                                                    React.createElement("div", { className: "prixdescription" }, "Inclus dans le menu"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul"))))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: dessert_bodega_panacota, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Bodega de panacota"),
                                                    React.createElement("div", { className: "prixdescription" }, "Inclus dans le menu"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul"))))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: dessert_salade, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Bodega de salade de fruit"),
                                                    React.createElement("div", { className: "prixdescription" }, "Inclus dans le menu"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul"))))),
                                    React.createElement("div", { className: "unplat" },
                                        React.createElement("div", { className: "intermediaire" },
                                            React.createElement("div", { className: "platimg" },
                                                React.createElement("img", { src: dessert_tarte, className: "imgmenue" })),
                                            React.createElement("div", { className: "descritpion" },
                                                React.createElement("div", { className: "principal" },
                                                    React.createElement("div", { className: "titredescription" }, "Tarte du jour maison"),
                                                    React.createElement("div", { className: "prixdescription" }, "Inclus dans le menu"),
                                                    React.createElement("div", { className: "prixdescription" }, "2\u20AC seul")))))))))))));
    };
    return Restauration;
}(React.Component));
export { Restauration };
