var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { Link } from "react-router-dom";
import { SocialButtons } from "../components/front/SocialButtons";
var logoAM = require("../../assets/img/Logo_A_M_footer.png");
var logoParisTech = require("../../assets/img/fondationam.jpg");
var Footer = /** @class */ (function (_super) {
    __extends(Footer, _super);
    function Footer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Footer.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("footer", { className: "bg-white py-4" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row align-items-center pb-2" },
                        React.createElement("div", { className: "col-md-4 text-center text-md-left" },
                            React.createElement("a", { href: "http://www.ueensam.org/", target: "_blank" },
                                React.createElement("img", { src: logoAM, alt: "Union des \u00E9l\u00E8ves Arts et M\u00E9tiers", className: "footer-logo opacity-6" })),
                            "\u00A0 \u00A0 \u00A0 \u00A0",
                            React.createElement("a", { href: "https://fondationartsetmetiers.org", target: "_blank" },
                                React.createElement("img", { src: logoParisTech, alt: "Fondation Arts et M\u00E9tiers", className: "footer-logo opacity-6" }))),
                        React.createElement("div", { className: "col-md-8" },
                            React.createElement(SocialButtons, { className: "footer-icon-social mb-0 mt-4 p-0 text-center text-md-right title-xs-small title-medium" }))),
                    React.createElement("div", { className: "row align-items-center" },
                        React.createElement("div", { className: "col-12 text-center" },
                            React.createElement("div", { className: "border-gray-300 border-top pt-3" },
                                React.createElement("span", { className: "font-alt font-w-600 letter-spacing-1 text-extra-small text-gray-700 text-uppercase" },
                                    React.createElement(Link, { to: "/" }, "Accueil"),
                                    "\u00A0 - \u00A0",
                                    React.createElement(Link, { to: "/legal" }, "Mentions l\u00E9gales"))))))),
            React.createElement("a", { href: "#page-top", className: "page-scroll scroll-to-top" },
                React.createElement("i", { className: "fa fa-angle-up" }))));
    };
    return Footer;
}(React.Component));
export { Footer };
