var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import * as classNames from "classnames";
/***************************************************************************************************
 *
 * Contenu à modifier
 *
 **************************************************************************************************/
var events = {
    matinee: [
        {
            heure: "10h00",
            titre: "Accueil des étudiants",
            lieu: "Parc Floral",
        },
        {
            heure: "10h30-11h15",
            titre: "Martin SION, président exécutif d'ArianeGroup. Session de questions réponses avec vous !",
            lieu: "Conférence",
        },
        {
            heure: "11h30-12h15",
            titre: "Guillaume Gaudfroy, dirigeant de KPI consulting. Power BI et l’IA dans le monde de l’ingénierie mécanique et industrielle ",
            lieu: "Conférence",
        },
    ],
    midi: [
        {
            heure: "11h45-13h00",
            titre: "1er service - repas traiteur",
            lieu: "Espace restauration",
        },
        {
            heure: "12h30-14h00",
            titre: "Repas Business Lunch",
            lieu: "Espace restauration Business Lunch",
        },
        {
            heure: "13h15-14h30",
            titre: "2ème service - repas traiteur ",
            lieu: "Espace restauration",
        },
    ],
    "apres-midi": [
        {
            heure: "13h00",
            titre: "Début du Challenge AM",
            lieu: "Espace Challenge AM",
        },
        {
            heure: "13h15-13h45",
            titre: "Orano, Etienne GAGENPAIN, Ingénieur d'études et de développement. Présentation du groupe puis présentation de son métier et de ses activités.",
            lieu: "Conférence",
        },
        {
            heure: "13h45-14h30",
            titre: "Claire Pétréault, Les pépites vertes. Être ingénieur au service de la transition environnementale ? C’est possible ! ",
            lieu: "Conférence",
        },
        {
            heure: "14h30-15h15",
            titre: "Olivier Huet, Directeur Général Adjoint de TechnicAtome. Les besoins energétiques en 2050",
            lieu: "Conférence",
        },
        {
            heure: "15h15-16h00",
            titre: "Thomas Sarazanas, Chief Network Operations Officer at Electra. Véhicules électriques : quels enjeux pour une transition réussie ?",
            lieu: "Conférence",
        },
        {
            heure: "16h00",
            titre: "Finale du Challenge AM",
            lieu: "Espace Challenge AM",
        },
        {
            heure: "16h10-16h45",
            titre: "François Xavier Hermellin, Directeur Industriel de Néolithe. Revaloriser les déchets non recyclables en les transformant en pierre : nouveaux puits de carbone.",
            lieu: "Conférence",
        },
        {
            heure: "17h00",
            titre: "Fermture aux étudiants",
            lieu: "Parc Floral",
        },
        {
            heure: "17h30-19h00",
            titre: "Cocktail de remerciement",
            lieu: "Espace restauration ",
        },
    ],
};
var Planning = /** @class */ (function (_super) {
    __extends(Planning, _super);
    function Planning(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentTab: "matinee",
        };
        return _this;
    }
    Planning.prototype.render = function () {
        var self = this;
        var currentEvents = events[this.state.currentTab];
        return (React.createElement(React.Fragment, null,
            React.createElement("section", { id: "schedule", className: "bg-white mt-5" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row justify-content-center mb-5" },
                        React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                            React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Planning de la journ\u00E9e"),
                            React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Retrouvez ici le planning des conf\u00E9rences"),
                            React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                    React.createElement("div", { className: "row justify-content-center" },
                        React.createElement("div", { className: "col-lg-8" },
                            React.createElement("div", { className: "text-center mb-4" },
                                React.createElement("ul", { id: "tab-schedule", className: "nav nav-tabs tabs-blue font-alt font-w-600 letter-spacing-1 text-center text-small text-uppercase", role: "tablist" },
                                    React.createElement("li", { className: "nav-item d-table-cell" },
                                        React.createElement("a", { onClick: function () {
                                                self.setState({ currentTab: "matinee" });
                                            }, "data-toggle": "tab", role: "tab", className: classNames("nav-link", {
                                                active: this.state.currentTab == "matinee",
                                            }) }, "Matin\u00E9e")),
                                    React.createElement("li", { className: "nav-item d-table-cell" },
                                        React.createElement("a", { onClick: function () {
                                                self.setState({ currentTab: "midi" });
                                            }, "data-toggle": "tab", role: "tab", className: classNames("nav-link", {
                                                active: this.state.currentTab == "midi",
                                            }) }, "Midi")),
                                    React.createElement("li", { className: "nav-item d-table-cell" },
                                        React.createElement("a", { onClick: function () {
                                                self.setState({ currentTab: "apres-midi" });
                                            }, "data-toggle": "tab", role: "tab", className: classNames("nav-link", {
                                                active: this.state.currentTab == "apres-midi",
                                            }) }, "Apr\u00E8s-midi")))),
                            React.createElement("div", { id: "tab-content-schedule", className: "tab-content" },
                                React.createElement("div", { className: "tab-pane fade show active", id: "tab-day-1", role: "tabpanel" }, currentEvents.map(function (event, i) {
                                    return (React.createElement("div", { className: "align-items-top border-bottom d-md-flex py-3", key: i },
                                        React.createElement("table", { className: "d-block font-alt font-w-600 letter-spacing-1 text-gray-700 text-small text-uppercase", style: { width: "100%" } },
                                            React.createElement("tbody", { className: "d-block font-alt font-w-600 letter-spacing-1 text-gray-700 text-small text-uppercase" },
                                                React.createElement("tr", null,
                                                    React.createElement("td", { style: { width: "10%", marginRight: "5px" } }, event.heure),
                                                    React.createElement("td", { style: { width: "70%" } }, event.titre),
                                                    React.createElement("td", { style: {
                                                            width: "20%",
                                                            right: 0,
                                                            textAlign: "right",
                                                        } }, event.lieu))))));
                                })))))))));
    };
    return Planning;
}(React.Component));
export { Planning };
