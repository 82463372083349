var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
var planAccesImage = require("../../../assets/images/Plan acces.jpg");
var PlanAcces = /** @class */ (function (_super) {
    __extends(PlanAcces, _super);
    function PlanAcces(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            active: {},
        };
        return _this;
    }
    PlanAcces.prototype.render = function () {
        var self = this;
        var isMarkerShown = false;
        return (React.createElement("section", { id: "access", className: "bg-white" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center mb-5" },
                    React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                        React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Plan d'acc\u00E8s"),
                        React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-6 col-sm-12" },
                        React.createElement("img", { src: planAccesImage, alt: "Plan d'accès", style: { width: "100%" } })),
                    React.createElement("div", { className: "col-lg-6 col-sm-12" },
                        React.createElement("iframe", { src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.1439990156155!2d2.4432054360356528!3d48.83647930957961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e672b79d97cd0b%3A0xd87d5287ecb38b11!2sArea+Events+-+Parc+Floral+de+Paris!5e0!3m2!1sen!2sfr!4v1541974096332", width: "100%", height: "450", frameBorder: "0", style: { border: 0 }, allowFullScreen: true }))))));
    };
    return PlanAcces;
}(React.Component));
export { PlanAcces };
