var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useSelector } from "react-redux";
import { extractValues, makeOptions } from "../../utils";
import MultiSelect from "react-select";
import useExposant from "../../hooks/useExposant";
import { pays, profils, secteurs, tailles, typeContrats, } from "../../constants";
import { TextEditor } from "../utils/TextEditor";
var EditExposant = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var adminExposantId = _a.adminExposantId;
    var _h = useState(), exposantCreated = _h[0], setExposantCreated = _h[1];
    var _j = useState(false), loading = _j[0], setLoading = _j[1];
    var _k = useState(), exposant = _k[0], setExposant = _k[1];
    var exposantId = useSelector(function (state) { return state.session.exposant; });
    var admin = useSelector(function (state) { return state.session.admin; });
    var _l = useExposant(), createExposant = _l[0], getExposant = _l[1], updateExposant = _l[2], deleteExposant = _l[3];
    useEffect(function () {
        if (adminExposantId) {
            getExposant(adminExposantId, setExposant);
        }
        else {
            getExposant(exposantId, setExposant);
        }
    }, []);
    var onSuccess = useCallback(function (exposant) {
        setExposant(exposant);
        toastr.success("Détails mis à jour", "Les détails de l'entreprise ont bien été mis à jour", { position: "top-right" });
        setLoading(false);
    }, []);
    var onFail = useCallback(function () {
        toastr.error("Échec", "La modification a échoué", {
            position: "top-right",
        });
        setLoading(false);
    }, []);
    var onSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            e.preventDefault();
            setLoading(true);
            updateExposant(exposant, onSuccess, onFail);
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("section", { className: "exposants" },
        React.createElement("div", { className: "container" },
            React.createElement("form", { id: "inscription-job-dating mt-2", onSubmit: onSubmit },
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "about paraMargin" },
                        React.createElement("h3", null, "\u00C0 propos"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "titre" }, "Titre de l'entreprise"),
                                React.createElement("input", { type: "text", className: "form-control", id: "titre", value: exposant === null || exposant === void 0 ? void 0 : exposant.titre, placeholder: "Titre de l'entreprise", onChange: function (e) {
                                        setExposant(__assign(__assign({}, exposant), { titre: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "secteur" }, "Domaine d'activit\u00E9"),
                                React.createElement(MultiSelect, { value: makeOptions(exposant === null || exposant === void 0 ? void 0 : exposant.secteur), isMulti: true, onChange: function (e) {
                                        return setExposant(__assign(__assign({}, exposant), { secteur: extractValues(e) }));
                                    }, options: makeOptions(secteurs) })),
                            React.createElement("div", { className: "form-group col-sm-6 col-sm-pull-6 col-xs-12" },
                                React.createElement("label", { htmlFor: "logo" }, "URL du logo de l'entreprise"),
                                React.createElement("input", { type: "text", className: "form-control", id: "logo", value: exposant === null || exposant === void 0 ? void 0 : exposant.logo, placeholder: "https:// (image 100x100px)", onChange: function (e) {
                                        setExposant(__assign(__assign({}, exposant), { logo: e.target.value }));
                                    } })),
                            React.createElement("div", { className: "col-sm-6 col-xs-12 col-sm-push-6" },
                                React.createElement("p", null, "Pr\u00E9visualisation du logo : "),
                                React.createElement("img", { src: exposant === null || exposant === void 0 ? void 0 : exposant.logo, alt: "Logo", style: { maxWidth: 200 } })),
                            React.createElement("div", { className: "form-group col-12", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "taille" }, "Nombre d'employ\u00E9s"),
                                React.createElement(MultiSelect, { value: makeOptions(exposant === null || exposant === void 0 ? void 0 : exposant.taille), isMulti: true, onChange: function (e) {
                                        return setExposant(__assign(__assign({}, exposant), { taille: extractValues(e) }));
                                    }, options: makeOptions(tailles) })),
                            React.createElement("div", { className: "form-group col-sm-6 col-xs-12" },
                                React.createElement("label", { htmlFor: "visible" }, "Visible"),
                                React.createElement(MultiSelect, { value: exposant === null || exposant === void 0 ? void 0 : exposant.visible, onChange: function (e) { return setExposant(__assign(__assign({}, exposant), { visible: e })); }, options: makeOptions(["Oui", "Non"]) })),
                            React.createElement("div", { className: "form-group col-sm-6 col-xs-12 col-sm-push-6" },
                                React.createElement("label", { htmlFor: "participation" }, "Participation au forum virtuel"),
                                React.createElement(MultiSelect, { value: exposant === null || exposant === void 0 ? void 0 : exposant.participation_forum_virtuel, onChange: function (e) {
                                        return setExposant(__assign(__assign({}, exposant), { participation_forum_virtuel: e }));
                                    }, options: makeOptions(["Oui", "Non"]) })),
                            admin && (React.createElement("div", { className: "form-group col-sm-6 col-xs-12" },
                                React.createElement("label", { htmlFor: "visible" },
                                    "Job dating \u2013 nombre d'entretiens",
                                    React.createElement("span", { style: {
                                            fontSize: 10,
                                            marginLeft: 6,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            paddingLeft: 4,
                                            paddingRight: 4,
                                            borderRadius: 4,
                                            color: "white",
                                            backgroundColor: "#8e2862",
                                        } }, "ADMIN")),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: 10,
                                        paddingLeft: 12,
                                        paddingRight: 24,
                                    } },
                                    React.createElement("input", { style: { height: 36, width: 36 }, type: "checkbox", className: "form-control", id: "entretien", checked: exposant === null || exposant === void 0 ? void 0 : exposant.entretien, onChange: function (e) {
                                            if (e.target.checked === false) {
                                                setExposant(__assign(__assign({}, exposant), { entretien: false, entretien_count: 0 }));
                                            }
                                            else {
                                                setExposant(__assign(__assign({}, exposant), { entretien: true }));
                                            }
                                        } }),
                                    (exposant === null || exposant === void 0 ? void 0 : exposant.entretien) && (React.createElement("input", { style: { width: "100%" }, type: "number", min: 0, max: 20, id: "entretienCount", value: exposant.entretien_count, onChange: function (e) {
                                            setExposant(__assign(__assign({}, exposant), { entretien_count: e.target.value }));
                                        } }))))),
                            admin && (React.createElement("div", { className: "form-group col-sm-6 col-xs-12" },
                                React.createElement("label", { htmlFor: "visible" },
                                    "Business lunch (nombre de places)",
                                    React.createElement("span", { style: {
                                            fontSize: 10,
                                            marginLeft: 6,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            paddingLeft: 4,
                                            paddingRight: 4,
                                            borderRadius: 4,
                                            color: "white",
                                            backgroundColor: "#8e2862",
                                        } }, "ADMIN")),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: 10,
                                        paddingLeft: 12,
                                        paddingRight: 24,
                                    } },
                                    React.createElement("input", { style: { height: 36, width: 36 }, type: "checkbox", className: "form-control", id: "entretien", checked: exposant === null || exposant === void 0 ? void 0 : exposant.dejeuner, onChange: function (e) {
                                            if (e.target.checked === false) {
                                                setExposant(__assign(__assign({}, exposant), { dejeuner: false, dejeuner_count: 0 }));
                                            }
                                            else {
                                                setExposant(__assign(__assign({}, exposant), { dejeuner: true }));
                                            }
                                        } }),
                                    (exposant === null || exposant === void 0 ? void 0 : exposant.dejeuner) && (React.createElement("input", { style: { width: "100%" }, type: "number", min: 0, max: 20, id: "dejeunerCount", value: exposant.dejeuner_count, onChange: function (e) {
                                            setExposant(__assign(__assign({}, exposant), { dejeuner_count: e.target.value }));
                                        } }))))),
                            React.createElement("div", { className: "form-group col-12", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "pays" }, "Pays"),
                                React.createElement(MultiSelect, { value: makeOptions(exposant === null || exposant === void 0 ? void 0 : exposant.pays), isMulti: true, onChange: function (e) {
                                        return setExposant(__assign(__assign({}, exposant), { pays: extractValues(e) }));
                                    }, options: makeOptions(pays) }))))),
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "description paraMargin" },
                        React.createElement("h3", null, "Description"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("label", { htmlFor: "resume" }, "R\u00E9sum\u00E9"),
                                React.createElement(TextEditor, { value: (_b = exposant === null || exposant === void 0 ? void 0 : exposant.resume) !== null && _b !== void 0 ? _b : "", max: 100, onChange: function (newValue) {
                                        setExposant(__assign(__assign({}, exposant), { resume: newValue }));
                                    } })),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("label", { htmlFor: "resume" }, "Description"),
                                React.createElement(TextEditor, { value: (_c = exposant === null || exposant === void 0 ? void 0 : exposant.description) !== null && _c !== void 0 ? _c : "", max: 1400, onChange: function (newValue) {
                                        setExposant(__assign(__assign({}, exposant), { description: newValue }));
                                    } })),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("label", { htmlFor: "chiffres_cles" }, "Chiffres cl\u00E9s"),
                                React.createElement(TextEditor, { value: (_d = exposant === null || exposant === void 0 ? void 0 : exposant.chiffres_cles) !== null && _d !== void 0 ? _d : "", max: 300, onChange: function (newValue) {
                                        setExposant(__assign(__assign({}, exposant), { chiffres_cles: newValue }));
                                    } })),
                            React.createElement("div", { className: "form-group col-12" },
                                React.createElement("label", { htmlFor: "site" }, "Site web"),
                                React.createElement("input", { type: "text", className: "form-control", id: "site", value: exposant === null || exposant === void 0 ? void 0 : exposant.site, placeholder: "https://", onChange: function (e) {
                                        setExposant(__assign(__assign({}, exposant), { site: e.target.value }));
                                    } }))))),
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "recrutement paraMargin" },
                        React.createElement("h3", null, "Recrutement"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "form-group col-12", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "conrats" }, "Type de contrat"),
                                React.createElement(MultiSelect, { value: makeOptions(exposant === null || exposant === void 0 ? void 0 : exposant.type_contrat), isMulti: true, onChange: function (e) {
                                        return setExposant(__assign(__assign({}, exposant), { type_contrat: extractValues(e) }));
                                    }, options: makeOptions(typeContrats) })),
                            React.createElement("div", { className: "form-group col-12", style: { width: "100%" } },
                                React.createElement("label", { htmlFor: "profils" }, "Profils recherch\u00E9s"),
                                React.createElement(MultiSelect, { value: makeOptions(exposant === null || exposant === void 0 ? void 0 : exposant.profils), isMulti: true, onChange: function (e) {
                                        return setExposant(__assign(__assign({}, exposant), { profils: extractValues(e) }));
                                    }, options: makeOptions(profils) })),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("label", { htmlFor: "modeRecrutement" }, "Mode de recrutement"),
                                React.createElement(TextEditor, { value: (_e = exposant === null || exposant === void 0 ? void 0 : exposant.mode_recrutement) !== null && _e !== void 0 ? _e : "", max: 500, onChange: function (newValue) {
                                        setExposant(__assign(__assign({}, exposant), { mode_recrutement: newValue }));
                                    } })),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("label", { htmlFor: "postes" }, "Postes et responsabilit\u00E9s"),
                                React.createElement(TextEditor, { value: (_f = exposant === null || exposant === void 0 ? void 0 : exposant.postes) !== null && _f !== void 0 ? _f : "", max: 500, onChange: function (newValue) {
                                        setExposant(__assign(__assign({}, exposant), { postes: newValue }));
                                    } })),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("label", { htmlFor: "message" }, "IMPORTANT / A REMPLIR : Message aux candidats. Sera visible dans le catalogue des exposants sur l\u2019application mobile"),
                                React.createElement("div", null,
                                    React.createElement(TextEditor, { value: (_g = exposant === null || exposant === void 0 ? void 0 : exposant.message) !== null && _g !== void 0 ? _g : "", max: 500, onChange: function (newValue) {
                                            setExposant(__assign(__assign({}, exposant), { message: newValue }));
                                        } })))))),
                React.createElement("div", { style: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }, className: "form-group col-12 justify-content-center mt-3" },
                    React.createElement("button", { disabled: loading, type: "submit", className: loading ? "btn btn-primary disabled" : "btn btn-primary" }, loading ? "Chargement..." : "Enregistrer les informations"))))));
};
export default EditExposant;
