var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import trackerApi from "../../api/tracker";
import useEntretien from "../../hooks/useEntretien";
import { DataGrid, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, } from "@mui/x-data-grid";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Button } from "@mui/material";
var AllEntretiens = function (props) {
    var _a;
    var _b = useState([]), rows = _b[0], setRows = _b[1];
    var _c = useEntretien(), getExposantsDisponibles = _c[0], applyEntretiens = _c[1], updateEntretien = _c[2], deleteEntretien = _c[3];
    var _d = useState(), selection = _d[0], setSelection = _d[1];
    var handleDeleteClick = function (id) { return function () {
        var isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette entreprise ? Cette action est irréversible.");
        var onSuccess = function () {
            setRows(rows.filter(function (row) { return row.id !== id; }));
            toastr.success("Entretien supprimé", "L'entretien ".concat(id, " a bien \u00E9t\u00E9 supprim\u00E9"), { position: "top-right" });
        };
        var onFail = function () {
            toastr.error("Échec", "Une erreur est survenue", {
                position: "top-right",
            });
        };
        if (isConfirmed) {
            deleteEntretien(id, onSuccess, onFail);
        }
    }; };
    var columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            maxWidth: 80,
        },
        {
            field: "etudiant",
            headerName: "Etudiant",
            flex: 1,
            minWidth: 150,
            valueGetter: function (params) {
                return "".concat(params.row.etudiant.nom, " ").concat(params.row.etudiant.prenom, " (").concat(params.row.etudiant.id, ")");
            },
            renderCell: function (params) {
                return (React.createElement("a", { href: params.row.etudiant.lien_cv, target: "_blank", rel: "noopener noreferrer" }, params.value));
            },
        },
        {
            field: "etudiantRank",
            headerName: "Choix #",
            flex: 1,
            maxWidth: 80,
            valueGetter: function (params) {
                var _a;
                var id = params.row.exposant.id;
                var preferences = JSON.parse(params.row.etudiant.entretien_preferences);
                var rank = preferences.indexOf(id) + 1;
                if (!rank)
                    return (_a = params.row.etudiant) === null || _a === void 0 ? void 0 : _a.entretien_preferences;
                return rank;
            },
            renderCell: function (params) {
                return (React.createElement("p", { style: { width: "100%", textAlign: "center" } }, params.value === 1 ? "🏆" : params.value));
            },
        },
        {
            field: "exposant",
            headerName: "Exposant",
            flex: 1,
            minWidth: 150,
            valueGetter: function (params) {
                return "".concat(params.value.titre, " (").concat(params.value.id, ")");
            },
            renderCell: function (params) {
                var companyURL = "/admin/entreprise/" + params.value.id + "/details";
                return (React.createElement("a", { href: companyURL, target: "_blank", rel: "noopener noreferrer" }, params.value));
            },
        },
        {
            field: "exposantRank",
            headerName: "Choix #",
            flex: 1,
            maxWidth: 80,
            valueGetter: function (params) {
                var _a;
                var id = params.row.etudiant.id;
                var preferences = JSON.parse(params.row.exposant.entretien_preferences);
                var rank = preferences.indexOf(id) + 1;
                if (!rank)
                    return (_a = params.row.exposant) === null || _a === void 0 ? void 0 : _a.entretien_preferences;
                return rank;
            },
            renderCell: function (params) {
                return (React.createElement("p", { style: { width: "100%", textAlign: "center" } }, params.value === 1 ? "🏆" : params.value));
            },
        },
        {
            field: "%",
            headerName: "Complet",
            type: "number",
            flex: 1,
            maxWidth: 100,
            valueGetter: function (params) {
                var numberStudents = rows.filter(function (e) { return e.exposant.titre === params.row.exposant.titre && e.tier === 1; }).length;
                var numberSlots = params.row.exposant.entretien_count;
                if (numberStudents / numberSlots !== 1)
                    return "".concat(numberStudents, " / ").concat(numberSlots);
                // @ts-ignore
                return "".concat(Number.parseInt((numberStudents / numberSlots) * 100), "%");
            },
            renderCell: function (params) {
                return (React.createElement("p", { style: { width: "100%", textAlign: "center" } }, params.value));
            },
        },
        {
            field: "tier",
            headerName: "Tranche",
            type: "number",
            flex: 1,
            maxWidth: 100,
        },
        {
            field: "createdAt",
            headerName: "Créée le",
            flex: 1,
            maxWidth: 100,
            renderCell: function (params) {
                var formattedDateFr = new Date(params.value).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                return React.createElement("span", null, formattedDateFr);
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            flex: 1,
            cellClassName: "actions",
            getActions: function (_a) {
                var id = _a.id;
                return [
                    React.createElement(GridActionsCellItem, { icon: React.createElement(DeleteIcon, null), label: "Delete", onClick: handleDeleteClick(id), color: "inherit" }),
                ];
            },
        },
    ];
    var getEntretiens = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, trackerApi.get("/api/entretiens")];
                case 1:
                    response = _a.sent();
                    setRows(response.data);
                    console.log(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log("welcome to catch block!");
                    console.log(e_1);
                    toastr.error("Échec", "Un problème est survenu", {
                        position: "top-right",
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleChangeSelection = function (newSelectionModel) {
        setSelection(newSelectionModel);
    };
    var emails = (_a = {},
        _a["Eurofins"] = "melanie.bocquet@sc.eurofinseu.com",
        _a["IKOS Consulting"] = "jlevasseur@ikosconsulting.com",
        _a["Sopra Steria Next"] = "marie.quatrefages@soprasterianext.com",
        _a["Valéo"] = "esther.alves@valeo.com",
        _a["TECHNICATOME"] = "gtaformation@technicatome.com",
        _a["Orano"] = "ghislaine.dolloue@orano.group",
        _a["ArianeGroup"] = "caroline.laulan@ariane.group",
        _a["ORESYS"] = "Mehdi.EL-MOKHTARI@oresys.eu",
        _a["Armée de l'air et de l'espace"] = "clemence.bourin@intradef.gouv.fr",
        _a);
    function formatMeetings(selection) {
        var meetings = rows.filter(function (e) { return selection.includes(e.id); });
        var formattedEmails = meetings.map(function (e) {
            return {
                tier: e.tier,
                type: "entretien",
                exposantId: e.exposant.id,
                exposantName: e.exposant.titre,
                exposantEmail: emails[e.exposant.titre],
                etudiantId: e.etudiant.id,
                etudiantFirstName: e.etudiant.prenom,
                etudiantName: [e.etudiant.prenom, e.etudiant.nom].join(" "),
                etudiantEmail: e.etudiant.email,
                meetingTime: "2023/11/23 11:00",
                meetingDuration: "00:30",
            };
        });
        return formattedEmails;
    }
    function CustomToolbar() {
        return (React.createElement(GridToolbarContainer, null,
            React.createElement(GridToolbarColumnsButton, null),
            React.createElement(GridToolbarFilterButton, null),
            React.createElement(GridToolbarDensitySelector, null),
            React.createElement(GridToolbarExport, null),
            React.createElement(Button, { onClick: function () { return console.log(formatMeetings(selection)); } },
                React.createElement(EmailIcon, { style: { fontSize: 16, marginRight: 8 } }),
                React.createElement("span", { style: { fontSize: "0.8125rem" } }, "COPY JSON"))));
    }
    useEffect(function () {
        getEntretiens();
    }, []);
    if (!rows) {
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col" },
                React.createElement("div", { style: { height: 40 } }),
                React.createElement("div", { className: "dashboardBoxBg mb30" },
                    React.createElement("div", { className: "jobdating paraMargin" },
                        React.createElement("h3", null, "Entretiens"),
                        React.createElement("p", null, "En cours de chargement..."))))));
    }
    return (React.createElement(DataGrid, { slots: {
            toolbar: CustomToolbar,
        }, rows: rows, columns: columns, style: { flex: 1, minHeight: 400 }, initialState: {
            pagination: {
                paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
                sortModel: [{ field: "tier", sort: "asc" }],
            },
            columns: {
                columnVisibilityModel: {
                    nom: false,
                },
            },
        }, rowSelectionModel: selection, onRowSelectionModelChange: handleChangeSelection, density: "compact", pageSizeOptions: [50, 100], checkboxSelection: true }));
};
export default AllEntretiens;
