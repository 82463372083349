var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import { withApi } from "../../decorators/api";
import { getImageUrl } from "../../utils";
var Content = /** @class */ (function (_super) {
    __extends(Content, _super);
    function Content(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            sections: [],
        };
        return _this;
    }
    Content.prototype.componentWillMount = function () {
        var self = this;
        this.props.loadContenu().then(function (contenu) {
            try {
                if (contenu[self.props.type]) {
                    self.setState({
                        sections: JSON.parse(contenu[self.props.type].contenu),
                    });
                }
            }
            catch (e) { }
        });
    };
    Content.prototype.componentDidUpdate = function (prevProps, prevState) {
        // Check if the sections have changed, and trigger scrolling
        if (this.state.sections !== prevState.sections) {
            this.scrollToSection();
        }
    };
    Content.prototype.scrollToSection = function () {
        var fragmentIdentifier = window.location.hash.slice(1); // Remove the '#' symbol
        console.log(typeof fragmentIdentifier, fragmentIdentifier);
        var sectionElement = document.getElementById(fragmentIdentifier);
        console.log(sectionElement);
        setTimeout(function () { return console.log(sectionElement); }, 1000);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: "smooth" });
        }
    };
    Content.prototype.render = function () {
        return (React.createElement("div", { style: { minHeight: "300px" } }, this.state.sections.map(function (section, i) {
            function renderImage() {
                return (React.createElement("div", { className: "col-lg-6 py-4" },
                    React.createElement("img", { style: { width: "100%" }, src: getImageUrl(section.image) })));
            }
            function renderContent() {
                return (React.createElement("div", { className: "col-lg-6" },
                    React.createElement("h2", { className: "text-center" }, section.title),
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: section.content } })));
            }
            return (React.createElement(React.Fragment, { key: i },
                React.createElement("a", { id: i + "" }),
                React.createElement("div", { className: "row py-5" }, i % 2 == 0 ? (React.createElement(React.Fragment, null,
                    renderImage(),
                    renderContent())) : (React.createElement(React.Fragment, null,
                    renderContent(),
                    renderImage())))));
        })));
    };
    Content = __decorate([
        withApi(),
        __metadata("design:paramtypes", [Object])
    ], Content);
    return Content;
}(React.Component));
export { Content };
