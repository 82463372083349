import trackerApi from "../api/tracker";
import { toastr } from "react-redux-toastr";
import { dumpBooleanValue, getBooleanValue, getListValue } from "../utils";

export default () => {

   const createExposant = null;

   const getExposant = async (id, setExposant) => {
      try {
         const response = await trackerApi.get(`/api/exposant/${id}`);
         const exposant = response.data;
         const formattedExposant = {
            ...exposant,
            secteur: getListValue(exposant.secteur),
            visible: getBooleanValue(exposant.visible),
            participation_forum_virtuel: getBooleanValue(
               exposant.participation_forum_virtuel
            ),
            profils: getListValue(exposant.profils),
            taille: getListValue(exposant.taille),
            pays: getListValue(exposant.pays),
            type_contrat: getListValue(exposant.type_contrat),
         }

         console.log(formattedExposant)

         setExposant(formattedExposant);

      } catch (e) {
         toastr.error("Échec", "Un problème est survenu", {
            position: "top-right",
         });
         console.log(e)
      }
   };

   const updateExposant = async (exposant, callback, fallback) => {
      try {
         const convertedExposant = {};

         for (const key in exposant) {
            if (Array.isArray(exposant[key])) {
               convertedExposant[key] = JSON.stringify(exposant[key]);
            } else if (["participation_forum_virtuel", "visible"].includes(key)) {
               convertedExposant[key] = dumpBooleanValue(exposant[key]);
            } else {
               convertedExposant[key] = exposant[key];
            }
         }

         const response = await trackerApi.post(`/api/exposants/update/${exposant.id}`, convertedExposant);

         const newExposant = response.data;

         const formattedExposant = {
            ...newExposant,
            secteur: getListValue(newExposant.secteur),
            visible: getBooleanValue(newExposant.visible),
            participation_forum_virtuel: getBooleanValue(
               newExposant.participation_forum_virtuel
            ),
            profils: getListValue(newExposant.profils),
            taille: getListValue(newExposant.taille),
            pays: getListValue(newExposant.pays),
            type_contrat: getListValue(newExposant.type_contrat),
         }

         console.log(formattedExposant)

         callback(formattedExposant);
      } catch (e) {
         fallback();
         console.log(e)
      }
   }

   const deleteExposant = async (id, callback, fallback) => {
      try {
         await trackerApi.post(`/api/exposants/delete/${id}`);
         callback();
      } catch (e) {
         fallback();
         console.log(e)
      }
   }

   return [createExposant, getExposant, updateExposant, deleteExposant];
};