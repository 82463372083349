var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var sponsor_forum_1 = require('../../../assets/images/sponsors/Logo Accenture.png');
var sponsor_forum_2 = require('../../../assets/images/sponsors/Logo Orano.png');
var sponsor_forum_3 = require('../../../assets/images/sponsors/ariane.jpeg');
var sponsor_forum_4 = require('../../../assets/images/sponsors/ta.png');
var sponsor_forum_5 = require('../../../assets/images/sponsors/fondationam.jpg');
var Sponsors = /** @class */ (function (_super) {
    __extends(Sponsors, _super);
    function Sponsors() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Sponsors.prototype.render = function () {
        return (React.createElement("section", { id: "sponsors", className: "bg-white" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center mb-5" },
                    React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                        React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Partenaires Forum"),
                        React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                React.createElement("div", { className: "row justify-content-center" },
                    React.createElement("div", { className: "col-lg-10" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-sm-6 col-md-4 text-center" },
                                React.createElement("a", { href: "https://www.accenture.com/fr-fr/careers" },
                                    React.createElement("img", { width: "200", src: sponsor_forum_1, alt: "" }))),
                            React.createElement("div", { className: "col-sm-6 col-md-4 text-center mt-4 mt-sm-0" },
                                React.createElement("a", { href: "https://recrutement.orano.group/" },
                                    React.createElement("img", { width: "200", src: sponsor_forum_2, alt: "" }))),
                            React.createElement("div", { className: "col-sm-6 col-md-4 text-center mt-4 mt-md-0" },
                                React.createElement("a", { href: "https://www.ariane.group/fr/carrieres/" },
                                    React.createElement("img", { width: "200", src: sponsor_forum_3, alt: "" }))),
                            React.createElement("div", { className: "col-sm-6 col-md-4 text-center mt-4" },
                                React.createElement("a", { href: "https://www.technicatome.com/carrieres/" },
                                    React.createElement("img", { width: "200", src: sponsor_forum_4, alt: "" }))),
                            React.createElement("div", { className: "col-sm-6 col-md-4 text-center mt-4" },
                                React.createElement("a", { href: "https://fondationartsetmetiers.org" },
                                    React.createElement("img", { width: "200", src: sponsor_forum_5, alt: "" })))))))));
    };
    return Sponsors;
}(React.Component));
export { Sponsors };
